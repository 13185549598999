@use "../settings/settings";

.gallery {
   background-color: white;

   &__background {
      max-width: settings.$widthFullPage;
      margin: 0 auto;
   }

   &__wrapper {
      &-text {
         padding: 3rem 1.25rem 2rem;
         font-size: 2rem;
         @include MontserratMedium;
         line-height: 1.5em;

         strong {
            @include MontserratBold;
            position: relative;
            z-index: 1;
            display: inline-block;
         }

         strong::before {
            content: "";
            z-index: -1;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 35%;
            background-color: settings.$greenColor;
         }
      }

      &-img {
         padding: 0 1.25rem 3rem;
         overflow: scroll;
         display: flex;
      }
   }

   &__img {
      min-width: 17.5rem;
      max-width: 17.5rem;
      max-height: 17.5rem;
      object-fit: cover;
      margin-right: 1.25rem;
   }
}

@media (min-width: 765px) {
   .gallery__img{
      max-width: 21rem;
      margin: 0;
   }
 .gallery__wrapper-img{
    gap: 1.25rem;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: repeat(3,1fr);
    overflow: hidden;
 }
}
@media (min-width: settings.$widthFullPage) {
   .gallery__wrapper-img{
      grid-template-columns: repeat(4,1fr);

   }
   .gallery__img{
      max-width: 100%;
      max-height: 19rem;
   }
}