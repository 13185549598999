@use "../settings/settings";
// Honey
.url-field-wrapper {
   position: absolute;
   left: -9999px;
}

.partner-form,
.volunteering-form,
.support-form,
.product-form {
   padding: 4rem 1.25rem 4rem;
}
.formulars__success{
   @include MontserratBold;
   color: darkgreen;
   margin-top: 1rem;
   margin-bottom: 1rem;
   line-height: 1.5em;
}
.formulars__field_error,
.formulars__error {
   @include MontserratBold;
   color: red;
   margin-top: 1rem;
   margin-bottom: 1rem;
   line-height: 1.5em;
}
.formulars__field_error{
   margin-top: 0;
   margin-bottom: 2rem;
}

.page__contact {
   padding: 8rem 1.25rem 4rem;

   &-title {
      position: relative;
      z-index: 1;
      font-size: 2rem;
      @include MontserratMedium;
      line-height: 1.25em;
      margin-bottom: 1.5rem;

      strong {
         display: inline-block;
         position: relative;
         @include MontserratBold;
      }

      strong::before {
         content: "";
         z-index: -1;
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 35%;
         background-color: settings.$purpleColor;
      }
   }

   &-text {
      max-width: 25rem;
      color: $colorAccessibleGrey;
      font-size: .875rem;
      @include MontserratRegular;
      line-height: 1.55em;
      margin-bottom: 1rem;
   }
}

.contact__information {
   padding: 2rem 0 4rem;

   &-title {
      position: relative;
      z-index: 1;
      font-size: 1.5rem;
      @include MontserratMedium;
      line-height: 1.25em;
      margin-bottom: 1.5rem;

      strong {
         display: inline-block;
         position: relative;
         @include MontserratBold;
      }

      strong::before {
         content: "";
         z-index: -1;
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 28%;
         background-color: settings.$purpleColor;
      }
   }

   &-list {
      &-text {
         display: flex;
         flex-direction: column;
         font-size: 1rem;
         @include MontserratMedium;
         line-height: 1.25em;

         &:last-of-type a {
            padding-bottom: 0
         }
      }

      &-link {
         padding-top: .25rem;
         font-size: .875rem;
         @include MontserratRegular;
         line-height: 1.25em;
         color: settings.$colorAccessibleGrey;
         padding-bottom: 1.25rem;
         display: inline-block;
         max-width: fit-content;
      }
   }
}

.form {
   transition: all 300ms;

   &__wrapper {
      background-color: settings.$colorWhite;
      border-radius: .5rem;
      flex-shrink: 0;
      flex-grow: 1;
   }

   &__background {
      padding: 1.5rem;
   }

   &__title {
      position: relative;
      z-index: 1;
      font-size: 1.5rem;
      @include MontserratMedium;
      line-height: 1.25em;
      margin-bottom: 1.5rem;

      strong {
         display: inline-block;
         position: relative;
         @include MontserratBold;
      }

      strong::before {
         content: "";
         z-index: -1;
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 28%;
         background-color: settings.$purpleColor;
      }
   }

   label {
      display: block;
      font-size: 1rem;
      @include MontserratBold;
      color: settings.$colorBlack;
      margin-top: 1.5rem;
      padding-bottom: .5rem;
      line-height: 1.5em;

      span {
         font-size: .875rem;
         @include MontserratRegular;
         line-height: 1.5em;
      }
   }

   .indication-message {
      line-height: 1.5em;
   }

   input,
   textarea,
   select {
      color: settings.$colorAccessibleGrey;
      min-width: 100%;
      max-width: 100%;
      padding: .75rem;
      border-radius: .5rem;
      border: 1px solid #E1E0E6;
      @include MontserratRegular;
   }

   .form__submit {
      margin-top: 1.5rem;
      @include MontserratBold;
      font-size: .875rem;
      color: settings.$colorWhite;
      border: 2px solid settings.$purpleColor;
      transition: all 300ms;
      margin-bottom: 0;

      &:hover,
      &:focus {
         cursor: pointer;
         color: settings.$purpleColor;
         background-color: transparent;
      }
   }
}

@media (min-width: 768px) {
   .page__contact {
      padding: 10rem 1.25rem 6rem;

      &-title {
         position: relative;
         z-index: 1;
         font-size: 2rem;
         @include MontserratMedium;
         line-height: 1.25em;
         margin-bottom: 1.5rem;
      }
   }
   .contact__information {
      &-title {
         font-size: 1.75rem;
      }
   }
   .form {
      align-items: flex-start;
      max-width: settings.$widthFullPage;
      margin: 0 auto;
      display: flex;
      gap: 2rem;
      justify-content: space-between;

      &__title {
         font-size: 1.75rem;
      }

      &__wrapper {
         max-width: 50%;
      }
   }
}

.product {
   .product-faq,
   .product-form {
      strong {
         &::before {
            background-color: settings.$orangeColor;
         }
      }

      .form__submit {
         border: 2px solid settings.$orangeColor;
         background-color: settings.$orangeColor;
         &:hover{
            color: settings.$orangeColor;
            background-color: transparent;
         }
      }
   }
}


@media (min-width: 995px) {
   .page__contact {
      padding: 5rem 1.25rem;
   }
}

@media (min-width: settings.$widthFullPage) {
   .partner-form,
   .volunteering-form,
   .support-form,
   .product-form {
      padding: 4rem 0 4rem;

      .page__contact {
         &-title {
            font-size: 2rem;
         }
      }
   }
   .page__contact {
      padding: 6rem 0;

      &-title {
         position: relative;
         z-index: 1;
         font-size: 3rem;
         @include MontserratMedium;
         line-height: 1.25em;
         margin-bottom: 1.5rem;
         max-width: 40rem;
      }
   }
   .contact__information {
      &-title {
         font-size: 2rem;
      }
   }
   .form {
      gap: 6rem;

      &__title {
         font-size: 2rem;
      }

      input,
      textarea,
      select {
         max-width: 32rem;
      }
   }
   .volunteering-form .form {
      gap: 7rem;
   }
}

[id="form"] {
   padding-top: 8em; /* Ajoutez la valeur appropriée pour compenser la position de l'ancre */
   margin-top: -8em; /* Ajoutez la valeur négative correspondante à la valeur de padding-top */
}

.page__contact {
   [id="form"] {
      padding-top: 0; /* Ajoutez la valeur appropriée pour compenser la position de l'ancre */
      margin-top: 0; /* Ajoutez la valeur négative correspondante à la valeur de padding-top */
   }
}