@use "../settings/settings";

.single__news{
   &-title{
      padding: 8rem 1.25rem 1rem;
      font-size: 2rem;
      @include MontserratBold;
      line-height: 1.25em;
   }
   &-date{
      padding: 1rem 1.25rem 2rem;
      font-size: 1rem;
      @include MontserratRegular;
      line-height: 1.5em;
   }
   &-img{
      display: block;
      border-radius: .5rem;
      margin: 0 auto;
      padding: 0 1.25rem;
      width: 100%;
      height: auto;
   }
   &-intro{
      padding: 2rem 1.25rem 0;
      font-size: 1rem;
      @include MontserratRegular;
      line-height: 1.5em;
   }
   &-text,
   &-end{
      padding: 1rem 1.25rem 0;
      font-size: 1rem;
      @include MontserratRegular;
      line-height: 1.5em;
   }
   padding-bottom: 4rem;
}

@media (min-width: 765px){
   .single__news{
      max-width: settings.$widthFullPage;
      margin: 0 auto;
      display: grid;
      grid-template-columns: minmax(0, 1fr) 18.75rem;
      padding: 10rem 0 8rem;
      &-title{
         padding: 0 1.25rem 1rem;
      }
      &-background{
         max-width: settings.$widthFullPage;
         //grid-column: 1/8;
         //border-right: 1px solid black;
      }
      &-img{
         max-width: 40rem;
         padding: 0;
         margin: 0;
      }
      &-wrapper{
         max-width: settings.$widthFullPage;
      }
      &-intro,
      &-text,
      &-end{
         max-width: 45rem;
         padding: 1rem 0 1rem;
      }
      .news{
         margin-left: 2rem;
         background-color: transparent;
         padding: 0 1.25rem 1rem;
         max-width: settings.$widthFullPage;
         //grid-column: 8/12;
         &__container-title{
            font-size: 1rem;
         }
         &__title{
            font-size: 1.75rem; //28px
            line-height: 1.28em; // 36px
            padding-top: 0;
            padding-bottom: .5rem;
            //border-bottom: 1px solid black;
         }
         &__wrapper{
            grid-template-columns: 1fr;
         }
      }
   }
}

@media (min-width: 995px) {
   .single__news{
         padding: 2rem 1.25rem 5rem;
      &-title{
         max-width: 49rem;
         padding: 0;
      }
      &-intro,
      &-text,
      &-end{
         max-width: 49rem;
        font-size: 1rem;
      }
      &-date{
         padding: 1rem 0 2rem;
      }
      .news{
         padding: 0 0 1rem;
      }
   }
}

@media (min-width: settings.$widthFullPage) {
   .single__news{
      grid-template-columns:minmax(0, 1fr) 21.75rem;
      &-img{
         max-width: 49rem;
         max-height: 30rem;
         object-fit: cover;
      }
   }
}