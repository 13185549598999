@use "../settings/settings";
.cliniclowns {
   &-main,
   &-who,
   &-formation,
   &-contact,
   &-animal,
   &-members,
   {
      display: flex;
      flex-direction: column;
      padding: 4rem 1.25rem 0;

      &__title {
         position: relative;
         z-index: 1;
         font-size: 2rem;
         @include MontserratMedium;
         line-height: 1.25em;
         margin-bottom: 1.5rem;

         strong {
            display: inline-block;
            position: relative;
            @include MontserratBold;
         }

         strong::before {
            content: "";
            z-index: -1;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 35%;
            background-color: settings.$orangeColor;
         }
      }

      &__text {
         color: $colorAccessibleGrey;
         font-size: .875rem;
         @include MontserratRegular;
         line-height: 1.55em;
         margin-bottom: 1rem;
      }

      &__link {
         display: inline-block;
         font-size: 1.25rem;
         @include MontserratBold;
         color: settings.$colorTextLink;
         margin: 1rem 0;
         padding: 1rem 1.5rem;
         max-width: 14rem;
         max-width: fit-content;
         transition: all 300ms;

         &::before {
            content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-o.svg");
            transition: translate 300ms;
            display: inline-block;
            width: .5rem;
            height: .95rem;
            translate: -1rem;
         }

         &:hover,
         &:focus {
            color: settings.$orangeColor;

            &::before {
               translate: -.4rem;
            }
         }
      }
   }

   &-main {
      gap: 2rem;

      &__text {
         margin-bottom: 4rem;
      }
   }

   &-main {
      padding-top: 1rem;
   }

   &-ariane {
      &__container {
         max-width: settings.$widthFullPage;
         margin: 0 auto;
         padding: 5rem 1.25rem 0;
         display: flex;
      }

      &__link {
         font-size: .875rem;
         @include MontserratRegular;
         color: settings.$colorBlack;
         margin-right: .5rem;
         display: inline-flex;
         align-items: center;
         transition: all 300ms;
         transform: scale(1);
         transform-origin: center;

         &:hover,&:focus{
            transform: scale(1.1);
         }

         &::after {
            content: url("/wp-content/themes/clinicoeurs/resources/img/fleche-service.svg");
            width: .5rem;
            height: 1.1rem;
            margin-left: .5rem;
            color: settings.$colorAccessibleGrey;
         }
      }
   }

   &__img {
      order: -1;
      width: 100%;
      height: auto;
      border-radius: 2rem;

      &-wrapper {
         order: -1;
         position: relative;
      }
   }

   &-who,
   &-formation {
      &__text {
         margin-bottom: 0;
      }

      &__wrapper {
         display: flex;
         flex-direction: column;
      }

      &__img {
         border-radius: 2rem;
         max-width: 100%;
         height: auto;
      }
   }

   &-contact {
      &__text {
         margin-bottom: 0;
      }
   }

   &-formation {
      &__img {
         margin-bottom: 4rem;
      }

      &__text {
         margin-bottom: 1rem;
      }
   }

   &-main,
   &-members {
      max-width: settings.$widthFullPage;
      margin: 0 auto;
   }

   &-who,
   &-formation,
   &-contact,
   &-animal {
      background-color: white;

      &__wrapper {
         max-width: settings.$widthFullPage;
         margin: 0 auto;
      }
   }

   &-members {
      padding: 4rem 0 4rem;

      &__title {
         padding: 0 1.25rem 1rem;
      }

      &__wrapper {
         padding: 0 1.25rem 1.5rem;
         display: flex;
         flex-direction: row;
         gap: 1.25rem;
         overflow: scroll;

         img {
            width :15rem;
            height :15rem;
            object-fit: cover;
         }
      }
   }

   .news {
      background-color: transparent;
   }

   .gallery__wrapper-text {
      position: relative;
      z-index: 1;

      strong {
         display: inline-block;
         position: relative;
      }

      strong::before {
         content: "";
         z-index: -1;
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 35%;
         background-color: settings.$orangeColor;
      }
   }
}

p.cliniclowns-ariane__link {
   &:last-of-type {
      @include MontserratBold;
      color: settings.$colorBlack;

      &::after {
         content: '';
      }
   }
}

@media (min-width: 765px) {
   .cliniclowns {
      &-ariane__container {
         padding: 6rem 1.25rem 0;
      }

      &-members {
         &__wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            overflow: initial;

            img {
               width :15rem;
               height :15rem;
               object-fit: cover;
            }
         }
      }

      &-main {
         display: flex;
         flex-direction: row;
         margin: 0 auto;
         max-width: settings.$widthFullPage;
         align-items: stretch;
         padding-bottom: 2rem;

         &__title {
            strong {
               display: inline-block;
            }
         }

         &__wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 30rem;
            order: -1;
         }
      }

      &-who,
      &-contact {
         max-width: initial;
         padding-bottom: 2rem;

         &__text {
            margin-bottom: 0;
         }

         &__wrapper {
            max-width: settings.$widthFullPage;
            margin: 0 auto;
            padding: 4rem 0 2rem;
            display: flex;
            flex-direction: row;
            align-items: center;
         }

         .cliniclowns-who__img,
         .cliniclowns-formation__img {
            max-width: 50%;
            margin-left: 2rem;
            margin-bottom: 0;
         }
      }

      &-formation,
      &-animal {
         max-width: initial;
         padding-bottom: 2rem;

         &__text {
            margin-bottom: 0;
         }

         &__wrapper {
            max-width: settings.$widthFullPage;
            margin: 0 auto;
            padding: 2rem 0 4rem;
            display: flex;
            flex-direction: row;
            align-items: center;
         }

         .cliniclowns-formation__img {
            order: -1;
            max-width: 50%;
            margin-right: 2rem;
            margin-bottom: 0;
         }
      }
   }
}

@media (min-width: 995px) {
   .cliniclowns {
      &-ariane__container {
         padding: 1.25rem 1.25rem 0;
      }

      &-members {
         &__wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
         }
      }

      &-main {
         padding-top: 4rem;
         padding-bottom: 5rem;
         justify-content: space-between;
         overflow: visible;

         &__title {
            font-size: 3rem;
            line-height: 1.16em;
         }
      }

      &__img {
         max-width: 80%;
         margin: 0 auto;
         display: block;
         &-wrapper {
            overflow: visible;
            max-width: 50%;
         }
      }
   }
}

@media (min-width: settings.$widthFullPage) {
   .cliniclowns {
      &-ariane__container {
         padding: 1.25rem 0 0;
      }
      &-members {
         &__title {
            padding: 0 0 1rem;
         }

         &__wrapper {
            padding: 0 0 1.5rem;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
         }
      }
      &-main {
         padding: 2rem 0 6rem;
      }
      &__img {
         padding: 0;
      }

      &-who,
      &-formation,
      &-contact,
      &-animal {
         &__wrapper {
            justify-content: space-between;

            &-text {
               max-width: 45%;
            }
         }
      }

      .gallery__wrapper {
         &-text {
            padding: 3rem 0 2rem;
         }

         &-img {
            padding: 0 0 3rem;
         }
      }
   }

   .creations {
      &__wrapper {
         padding: 0 0 2rem;
      }
   }
}
/* Gallery : infinite slider */
/* CSS HSL */
$purple: hsla(263, 45%, 49%, 1);
$blue: hsla(210, 90%, 43%, 1);
$blue-light: hsla(196, 80%, 44%, 1);
$green: hsla(163, 71%, 47%, 1);
$yellow: hsla(51, 82%, 66%, 1);
$orange: hsla(15, 84%, 59%, 1);
$red-light: hsla(0, 91%, 68%, 1);
$red: hsla(1, 97%, 65%, 1);
$pink: hsla(347, 90%, 65%, 1);

/* Shadows */
$shadow-base: 0 3px 6px rgba(0, 0, 0, 0.16);
$image-width: 250px;
$slider-image-count: 9;

.gallery-service {
   padding: 2.35em 0;
   background: white;
   &-container{
      max-width: 80rem;
      margin: 0 auto;
   }
   span {
      display: block;
      text-align: center;
      color: $red;
   }

   h2 {
      padding: 0 1.25rem;
   }

   @keyframes slider {
      0% {
         transform: translateX(50px);
      }
      100% {
         transform: translateX(($slider-image-count - 1)*$image-width*-1);
      }
   }

   .gallery__slider {
      margin-top: 2.5em;
      display: flex;
      width: 200%;

      &__img {
         animation: slider 20s linear infinite;
         display: flex;
         gap: 0 1.5em;
         margin-left: 1.5rem;

         img {
            border-radius: 1rem;
            min-width: $image-width;
            max-height: 200px;
            object-fit: cover;
         }
      }
   }

   .gallery__slider:hover {
      &__img {
         -webkit-animation-play-state: paused;
         animation-play-state: paused;
      }
   }
}

@media screen and (min-width: 1250px) {
   .gallery-service {
      background: white;
      span, h2 {
         text-align: left;
         padding: 0;
      }

      .gallery__slider {
         margin: 3.5em auto 3em;

         &__img {
            img {
               min-width: $image-width * 1.5;
               max-height: 300px;
            }
         }
      }
   }
}
.pagination .prev,
.pagination .next{
   display: initial;
   position: initial;
   font-size: 1rem;
   color: black;
}
.pagination .next{
   padding-left: 1rem;
}
.pagination .prev{
   padding-right: 1rem;
}
.pagination .current{
   font-size: 1.25rem;
   font-weight: bold;
   color: black;
}
.page-numbers {
   font-size: 1rem;
   font-weight: lighter;
   color: grey;
}

.cliniclowns,
.clinitalents,
.clinisnoezs,
.clinijuniors{
   overflow: hidden;
}