@font-face {
   font-family: 'Poppins';
   font-weight: 400;
   font-style: normal;
   font-display: fallback;
   src: url('/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf') format('TrueType');
}
@font-face {
   font-family: 'Poppins';
   font-weight: 700;
   font-style: normal;
   font-display: fallback;
   src: url('/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf') format('TrueType');
}

@font-face {
   font-family: 'Montserrat';
   font-weight: 600;
   font-style: normal;
   font-display: fallback;
   src: url('/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf') format('TrueType');
}

@font-face {
   font-family: 'Montserrat';
   font-weight: 500;
   font-style: normal;
   font-display: fallback;
   src: url('/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf') format('TrueType');
}

@font-face {
   font-family: 'Montserrat';
   font-weight: 700;
   font-style: normal;
   font-display: fallback;
   src: url('/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf') format('TrueType');
}

@font-face {
   font-family: 'Montserrat';
   font-weight: 400;
   font-style: normal;
   font-display: fallback;
   src: url('/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf') format('TrueType');
}

/* MAX WIDTH PAGE*/
$widthFullPage : 80rem;

/* COLOUR*/
$colorAccessibleGrey : #51565D;
$colorTextLink : #292929;

$colorWhite : #ffffff;
$colorBlack : #1C1C1C;
$orangeColor : #EF6D51; //#DE6D55;
$purpleColor : #9359c6; //#B883CD;
$blueColor : #66B7B4;
$greenColor : #55BA8F;
$yellowColor : #FFCC41; //#F6C84A;
$backgroundColor : #FBF5F3;
$blueMenu : #0074be;
$darkBlueColor : #0980a7;
$darkGreenColor : #37814d;

/* SIZE */

$borderRadiusMobile : 4rem;

/* FONT MIXIN */
@mixin MontserratBold {
   font-family: 'Montserrat', Arial, Helvetica, 'Roboto', 'Open Sans', sans-serif;
   font-weight: 700;
}
@mixin MontserratMedium {
   font-family: 'Montserrat', Arial, Helvetica, 'Roboto', 'Open Sans', sans-serif;
   font-weight: 500;
}
@mixin MontserratRegular {
   font-family: 'Montserrat', Arial, Helvetica, 'Roboto', 'Open Sans', sans-serif;
   font-weight: 400;
}
@mixin MontserratLight{
   font-family: 'Montserrat', Arial, Helvetica, 'Roboto', 'Open Sans', sans-serif;
   font-weight: 300;
}

@mixin BebasNeueFamily{
   font-family: 'Bebas Neue', 'Helvetica Neue', Helvetica, Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
@mixin GeorgousFont{
   font-family: 'Gorgeous Serif Font','Didot', 'Bodoni MT', 'Baskerville', 'Times New Roman', serif;
}

.hidden{
   position: absolute;
   overflow: hidden;
   clip: rect(0 0 0 0);
   height: 1px; width: 1px;
   margin: -1px;
   padding: 0;
   border: 0;
}

@mixin hidden {
   position: absolute;
   overflow: hidden;
   clip: rect(0 0 0 0);
   height: 1px; width: 1px;
   max-height: 1px;
   margin: -1px;
   padding: 0;
   border: 0;
   opacity: 0;
   visibility: hidden;
   transition:opacity 1s, max-height .5s;
}