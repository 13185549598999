@use "../settings/settings";

.news {
   position: relative;
   background-color: settings.$colorWhite;
   padding: 5rem 1.25rem;

   &__background {
      max-width: settings.$widthFullPage;
      margin: 0 auto;
   }

   &__title {
      position: relative;
      z-index: 1;
      font-size: 2rem;
      @include MontserratMedium;
      line-height: 1.5em;

      strong {
         @include MontserratBold;
         position: relative;
      }

      strong::before {
         content: "";
         z-index: -1;
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 35%;
         background-color: settings.$orangeColor;
      }
   }

   &__container {
      transition: all 300ms;
      margin-top: 2rem;
      position: relative;

      &:focus,
      &:hover {
         scale: 1.05;
      }

      &-title {
         font-size: 1.25rem;
         @include MontserratMedium;
         color: settings.$colorWhite;
         position: absolute;
         bottom: 1rem;
         left: 1rem;
         line-height: 1.25em;
         z-index: 2;
         padding-right: 1rem;
      }

      &-wrapper {
         &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: settings.$colorBlack;
            opacity: 0.5;
            border-radius: .5rem;
            z-index: 1;
         }
      }
   }

   &__img {
      position: relative;
      max-width: 100%;
      height: auto;
      max-height: 20rem;
      object-fit: cover;
      border-radius: .5rem;
   }
}

@media (min-width: 765px) {
   .news {
      padding-top: 0;

      &__title {
         padding-top: 4rem;
      }

      &__wrapper {
         margin-top: 4rem;
         margin-bottom: 4rem;
      }

      &__container {
         margin-top: -3px;

         &-wrapper {
            &::after {
               height: 99.5%;
            }
         }
      }

      &__wrapper {
         margin-top: 2rem;
         display: grid;
         grid-template-columns: repeat(2, 1fr);
         grid-template-rows: repeat(2, 1fr);
         gap: 2rem;
      }
   }
}

@media (min-width: 995px) {
   .news {
      &__container {
         &-title {
            font-size: 1.75rem;
            line-height: 1.25em;
            max-width: 85%;
         }
      }
   }
}