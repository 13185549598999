@use "../settings/settings";

.clinisnoezs {
   .svg {
      &-ell1 {
         fill: settings.$orangeColor;
         left: -10%;
         top: -1%;
         max-width: 20%;
      }

      &-ell2 {
         fill: settings.$yellowColor;
         max-width: 50%;
         left: 64%;
         bottom: -4%;
         z-index: 1;
      }

      &-rect1 {
         fill: settings.$purpleColor;
         right: -4rem;
         bottom: 7rem;
      }

      &-rect2 {
         fill: settings.$greenColor;
         max-width: 26%;
         left: -8%;
         top: 70%;
      }
   }
   &-main{
      overflow: hidden;
   }
   &__background {
      max-width: settings.$widthFullPage;
      margin: 0 auto;
   }

   &-main,
   &-who,
   &-where,
   {
      padding: 2rem 1.25rem 0;
      display: flex;
      flex-direction: column;

      &__title {
         position: relative;
         z-index: 1;
         font-size: 2rem;
         @include MontserratMedium;
         line-height: 1.5em;
         margin-bottom: 1.5rem;

         strong {
            display: inline-block;
            position: relative;
            @include MontserratBold;
         }

         strong::before {
            content: "";
            z-index: -1;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 35%;
            background-color: settings.$purpleColor;
         }
      }

      &__text {
         color: $colorAccessibleGrey;
         font-size: .875rem;
         @include MontserratRegular;
         line-height: 1.55em;
         margin-bottom: 1rem;
      }

      &__link {
         display: inline-block;
         font-size: 1rem;
         @include MontserratBold;
         color: settings.$colorTextLink;
         margin: 1rem 0;
         padding: 1rem 1.5rem;
         max-width: 14rem;
         max-width: fit-content;

         &::before {
            content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-p.svg");
            transition: translate 300ms;
            display: inline-block;
            width: .5rem;
            height: .95rem;
            translate: -1rem;
         }

         &:hover,
         &:focus {
            color: settings.$purpleColor;

            &::before {
               translate: -.4rem;
            }
         }
      }
   }

   &-main {
      max-width: settings.$widthFullPage;
      margin: 0 auto;

      &__text {
         color: $colorAccessibleGrey;
         font-size: .875rem;
         @include MontserratRegular;
         line-height: 1.55em;
         margin-bottom: 3rem;
      }
   }

   &-recruitment {
      &__text {
         margin-bottom: 0;
      }
   }

   &__img {
      border-radius: 2rem;
      max-width: 100%;
      height: auto;
      margin-bottom: 4rem;
   }

   &-who,
   &-where {
      background-color: settings.$colorWhite;
   }

   &-members {
      margin-bottom: 4rem;

      &__title {
         padding: 2.5rem 1.25rem 1.5rem;
         font-size: 2rem;
         @include MontserratMedium;
         line-height: 1.5em;

         strong {
            @include MontserratBold;
            display: inline-block;
            position: relative;
         }

         strong::before {
            content: "";
            z-index: -1;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 35%;
            background-color: settings.$purpleColor;
         }
      }

      &__wrapper {
         display: flex;
         gap: 1.25rem;
         overflow: scroll;
         padding: 0 1.25rem 1rem;
      }
   }
}

.clinisnoezs {
   &-main {
      padding-top: 1rem;

      .clinisnoezs__img {
         order: -1;
         margin-bottom: 2rem;
      }
   }

   &__img {
      border-radius: 2rem;
      max-width: 100%;
      height: 100%;
      margin-bottom: 4rem;

      &-wrapper {
         order: -1;
      }
   }
}

@media (min-width: 765px) {
   .clinisnoezs {
      &-members {
         max-width: settings.$widthFullPage;
         margin: 0 auto;
         padding: 4rem 0;

         &__wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 1rem;
            row-gap: 1rem;
            overflow: initial;
            justify-items: stretch;
            align-items: stretch;
            object-fit: cover;
         }
      }

      &-main {
         flex-direction: row;
         justify-content: space-between;
         align-items: center;
         padding: 1rem 1.25rem 4rem;

         .clinisnoezs__img {
            margin-bottom: 0;
            border-radius: 2rem;
         }

         &__background {
            max-width: 25rem;
            margin-right: 2rem;
         }
      }

      &__img {
         &-wrapper {
            order: 0;
         }
      }

      &__background {
         display: flex;
         flex-direction: row;
         align-items: center;
      }

      &-who {
         padding-top: 4rem;

         .clinisnoezs__img {
            max-width: 50%;
            order: -1;
            margin: 0;
            margin-right: 2rem;
         }
      }

      &-where {
         padding: 4rem 1.25rem;

         .clinisnoezs__img {
            max-width: 50%;
            margin: 0;
            margin-left: 4rem;
         }
      }
   }
}

@media (min-width: 995px) {
   .clinisnoezs {
      &-main {
         padding: 5rem 1.25rem 6rem;
         &__title {
            font-size: 3rem;
            line-height: 1.16em;
         }
      }

      &-members {
         &__wrapper {
            grid-template-columns: 1fr 1fr 1fr 1fr;
         }
      }
      .cliniclowns__img-wrapper{
         order: 1;
      }
   }
}

@media (min-width: settings.$widthFullPage) {
   .clinisnoezs {
      &-main {
         overflow: visible;
         padding: 2rem 0 6rem;
         .clinisnoezs__img{
            max-width: 93%;
         }
      }

      &__background {
         justify-content: space-between;
      }

      &-who,
      &-where {
         &__background {
            max-width: 45%;
         }
      }

      &-members {
         &__title{
            padding: 2.5rem 0 1.5rem;
         }
         &__wrapper {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            padding: 0 0 4rem;
         }
      }
   }
}