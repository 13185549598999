@use "../settings/settings";

.volunteering {
   &__img {
      max-width: 100%;
      height: auto;
      order: -1;
      border-radius: 2rem;
      margin-bottom: 2rem;
   }

   &-main {
      display: flex;
      flex-direction: column;
      padding: 6rem 1.25rem 4rem;

      &__title {
         font-size: 2rem; // 32px
         @include MontserratMedium;
         line-height: 1.125em; // 36px

         strong {
            display: inline-block;
            position: relative;
            @include MontserratBold;
         }

         strong::before {
            content: "";
            z-index: -1;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 35%;
            background-color: settings.$purpleColor;
         }
      }

      &__text {
         margin-top: 1rem;
         font-size: .875rem;
         line-height: 1.5em;
         @include MontserratRegular;
         color: settings.$colorAccessibleGrey;
      }
   }

   &-profil {

      &__title {
         position: relative;
         z-index: 1;
         padding: 2rem 1.25rem 0;
         font-size: 2rem;
         @include MontserratMedium;
         line-height: 1.5em;

         strong {
            display: inline-block;
            z-index: 0;
            position: relative;
            @include MontserratBold;
         }

         strong::before {
            content: "";
            z-index: -1;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 35%;
            background-color: settings.$purpleColor;
         }
      }

      &__text {
         padding: 0 1.25rem 2rem;
         margin-top: 1rem;
         font-size: .875rem;
         line-height: 1.5em;
         @include MontserratRegular;
         color: settings.$colorAccessibleGrey;
      }
   }

   &-card {
      &__wrapper {
         position: relative;
         padding: 1.5rem;
         border-radius: 1rem;
         background-color: white;
         max-width: 90%;
         margin: 0 auto;
         box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      }

      &__sro {
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         right: 0;
         font-size: 0;
      }

      &__title {
         font-size: 1.5rem;
         @include MontserratBold;
         padding-bottom: .5rem;
      }

      &__text {
         font-size: .875rem;
         line-height: 1.55em;
         @include MontserratRegular;
         padding-bottom: 1.25rem;
         color: settings.$colorAccessibleGrey;
      }

      &__frequence,
      &__location {
         font-size: .875rem;
         line-height: 1.55em;
         @include MontserratRegular;
         padding-bottom: 1.25rem;
         color: settings.$colorAccessibleGrey;

         &-title {
            font-size: 1.125rem;
            @include MontserratMedium;
            padding-bottom: .5rem;
         }
      }
   }
}


.volunteering-contact {
   &__title {
      padding: 3rem 1.25rem 0;
      font-size: 2rem;
      @include MontserratMedium;

      strong {
         display: inline-block;
         position: relative;
         @include MontserratBold;
      }

      strong::before {
         content: "";
         z-index: -1;
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 35%;
         background-color: settings.$orangeColor;
      }
   }
}

.field {
   &__label {
      margin-top: 1.5rem;
      font-size: 1.125rem;
      @include MontserratBold;
      display: block;
   }

   &__input {
      font-size: 1rem;
      @include MontserratRegular;
      background-color: transparent;
      border: none;
      padding: .5rem 0;
      border-bottom: 1px solid black;
      width: 100%;
   }

   &__textarea {
      font-size: 1.125rem;
      @include MontserratRegular;
      background-color: transparent;
      border: none;
      padding-bottom: .25rem;
      border-bottom: 1px solid black;
      width: 100%;
   }

   &-name {
      //display: flex;
      //gap: 1rem;
   }
}

[label="firstname"] {
   margin-top: 0;
}

.sliderButtonsContainer {
   display: none;
}

.sliderContainer {
   overflow: scroll;
}

@media (min-width: 765px) {
   .volunteering {
      &-background {
         display: flex;
         flex-direction: row;
         margin: 0 auto;
         max-width: settings.$widthFullPage;
         gap: 2rem;
      }

      &-main {
         padding: 6rem 1.25rem 4rem;
         max-width: settings.$widthFullPage;
         margin: 0 auto;
         display: flex;
         justify-content: space-between;
         flex-direction: row;
         align-items: center;

         &__background {
            padding-right: 2rem;
            max-width: 45%;
         }

         .volunteering__img {
            order: 0;
            max-width: 50%;
            margin: 0;
         }
      }

      &-card__wrapper {
         min-width: 20rem;
      }

      &-profil {
         background-color: settings.$colorWhite;
         padding: 3rem 0;

         &__title {
            strong {
               position: relative;
               z-index: 1;
            }
         }

         &__background {
            max-width: 50%;

            &-card {
               display: flex;
               flex-direction: row;
               gap: 1.5rem;
               overflow: scroll;
               padding: 1rem;
            }
         }
      }

      &-contact {
         margin: 0 auto;
         max-width: settings.$widthFullPage;
         padding: 4rem 1.25rem;

         &__title {
            padding: 0;
         }

         &__wrapper {
            max-width: 40%;
         }
      }

      &__contact__submit {
         margin-top: 2rem;
         font-size: 1rem;
         @include MontserratBold;
         text-transform: uppercase;
         color: settings.$colorWhite;
         background-color: settings.$orangeColor;
         padding: 1rem 1.25rem;
         border: none;
         border-radius: .5rem;
      }
   }
}

@media (min-width: 995px) {
   .volunteering {
      &-main {
         padding: 5rem 1.25rem;

         &__title {
            font-size: 3rem;
            line-height: 1.16em;
         }
      }
   }
}

@media (min-width: settings.$widthFullPage) {
   .volunteering {
      &-main {
         padding: 6rem 0;
      }

      &-profil {
         &__title {
            padding: 0;
         }

         &__text {
            padding: 0 0 2rem;
         }

         &__background {
            max-width: 35%;
         }
      }
   }
}