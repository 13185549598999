@use "../settings/settings";

.partner {
   &__img {
      border-radius: 2rem;
      max-width: 100%;
      height: auto;
   }

   &-main {
      padding: 6rem 1.25rem 4rem;
      display: flex;
      flex-direction: column;
      max-width: settings.$widthFullPage;
      margin: 0 auto;

      &__title {
         font-size: 2rem;
         @include MontserratMedium;
         line-height: 1.25em;
         margin-bottom: 1.5rem;
         position: relative;
         z-index: 1;

         strong {
            @include MontserratBold;
            position: relative;
            z-index: 1;
            display: inline-block;
         }

         strong::before {
            content: "";
            z-index: -1;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 35%;
            background-color: settings.$blueColor;
         }
      }

      &__text {
         font-size: .875rem;
         @include MontserratRegular;
         line-height: 1.5em;
         color: settings.$colorAccessibleGrey;
      }

      .partner__img {
         order: -1;
         margin-bottom: 2rem;
      }
   }

   &__background {
      max-width: settings.$widthFullPage;
      margin: 0 auto;
   }

   &-obtain {
      background-color: settings.$colorWhite;

      &__background {
         margin: 0 auto;
         max-width: settings.$widthFullPage;
         padding: 4rem 1.25rem;
      }

      &__title {
         font-size: 2rem;
         @include MontserratMedium;
         line-height: 1.25em;
         margin-bottom: 1.5rem;

         strong {
            display: inline-block;
            @include MontserratBold;
            position: relative;
            z-index: 1;
         }

         strong::before {
            content: "";
            z-index: -1;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 35%;
            background-color: settings.$blueColor;
         }
      }

      &__text {
         font-size: 1rem;
         @include MontserratRegular;
         line-height: 1.5em;
         color: settings.$colorAccessibleGrey;
         margin-bottom: 1.5rem;
      }
   }

   &__gallery {
      background-color: settings.$colorWhite;

      &-background {
         margin: 0 auto;
         max-width: settings.$widthFullPage;
         padding-bottom: 4rem;
      }

      &-title {
         font-size: 2rem;
         @include MontserratMedium;
         line-height: 1.25em;
         margin-bottom: 1.5rem;
         padding: 4rem 1.25rem 0;

         strong {
            @include MontserratBold;
            position: relative;
            z-index: 1;
            display: inline-block;
         }

         strong::before {
            content: "";
            z-index: -1;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 35%;
            background-color: settings.$blueColor;
         }
      }

      &-wrapper {
         display: grid;
         grid-template-columns: 1fr;
         grid-template-rows: 1fr;
         gap: 2rem;
         justify-items: center;
         object-fit: cover;
      }

      &-img {
         max-width: 80%;
         height: auto;
         transition: all 300ms;
         &-inactive{
            max-width: 80%;
            height: auto;
            transition: all 1s;
            filter: grayscale(100%);
         }
         &:hover {
            transform-origin: center;
            transform: scale(1.2);
         }
      }
   }

   .contact__information {
      background-color: transparent;
   }
}

.form {
   &__title {
      strong::before {
         background-color: settings.$purpleColor;
      }
   }

   &__submit {
      background-color: settings.$purpleColor;
   }
}

.partner {
   .form {
      strong::before {
         background-color: settings.$blueColor;
      }
      &__submit {
         border-color: settings.$blueColor;
         background-color: settings.$blueColor;
         &:hover{
            color: settings.$blueColor;
         }
      }
   }
}

@media (min-width: 765px) {
   .partner {
      &__img {
         max-width: 50%;
      }

      &-main {
         padding-top: 6rem;
         display: flex;
         flex-direction: row;
         align-items: center;
         padding-bottom: 4rem;

         .partner__img {
            order: 0;
            margin: 0;
            margin-left: 2rem;
         }

         &__wrapper {

         }
      }

      &-obtain {

         &__background {
            padding: 8rem 1.25rem;
            display: flex;
            flex-direction: row;
            align-items: center;

            .partner__img {
               margin-right: 2rem;
            }
         }

         &__text-wrapper {
            order: 1;

         }
      }
   }
   .partner__gallery {
      &-wrapper {
         display: grid;
         grid-template-columns: 1fr 1fr 1fr 1fr;
      }
   }
}

@media (min-width: 995px) {
   .partner {
      &-main {
         padding: 5rem 1.25rem;

         &__title {
            font-size: 3rem;
            line-height: 1.16em;
         }
      }
   }
}

@media (min-width: settings.$widthFullPage) {
   .partner {
      &-main {
         padding: 6rem 0;
         justify-content: space-between;

         &__title {
            font-size: 3rem;
            line-height: 1.16em;
         }

         &__wrapper {
            max-width: 42%;
         }
      }

      &-obtain {
         &__background {
            justify-content: space-between;
            padding: 8rem 0;
         }

         &__text {
            font-size: .875rem;

            &-wrapper {
               max-width: 45%;
            }
         }
      }

      &__gallery-title {
         padding: 4rem 0 0;
      }
   }
}