@use "../settings/settings";

.footers__background {
   max-width: settings.$widthFullPage;
   margin: 0 auto;
}

.footers {
   background-color: #313131;

   &__menu {
      padding: 2rem 1.25rem 1rem;

      &-li {
         font-size: 1.5rem;
         @include MontserratBold;
         color: white;
         margin: 1.5rem 0 .5rem;

         li {
            font-size: 1rem;
            @include MontserratRegular;
            color: white;
            padding: .75rem 0 .65rem;

            &:first-of-type {
               padding-top: 1.5rem;
            }

            a {
               cursor: pointer;
               flex: 0;
               color: white;
               transition: all 300ms;
               border-bottom: 1px solid transparent;
               &:focus,&:hover{
                  //font-size: 1.2rem;
                  border-color: white;
               }
            }
         }
      }
   }
}
.secondaryFooters {
   background-color: settings.$colorAccessibleGrey;
   &-wrapper{
      margin: 0 auto;
      max-width: settings.$widthFullPage;
      display: flex;
      flex-direction: column;
      padding: 1.5rem 1.25rem 1.5rem;
      color: white;
      font-size: 1rem;
      @include MontserratRegular;
   }
   div{
      max-width: settings.$widthFullPage;
   }
   p {
      margin-bottom: 1rem;
   }

   a {
      color: white;
      font-size: 1rem;
      @include MontserratRegular;
      border-bottom: 1px solid transparent;
      transition: all 300ms;
      &:hover,
      &:focus{
         border-bottom: 1px solid settings.$colorWhite;
      }
   }
   &-container{
      display: flex;
      flex-direction: column;
   }
}

@media (min-width: 765px) {
   .footers {
      &__menu {
         padding: 2rem 1.25rem 1rem;
      }
   }
   .secondaryFooters {

      &-wrapper{
         display: flex;
         flex-direction: row;
         justify-content: space-between;
         margin: 0 auto;
         div{
            margin: 0;
         }
      }
      p{
         margin-bottom: 0;
      }
      &-container{
         display: flex;
         flex-direction: row;
         gap: 2rem;
      }
      a{
         padding: 0;
      }
   }
}

@media (min-width: 900px) {
   .footers {
      &__menu {
         &-first {
            display: flex;
            justify-content: space-between;
            gap: 1rem;
         }
      }
   }
}
@media (min-width: settings.$widthFullPage) {
   .secondaryFooters {
      &-wrapper{
         padding: 1.5rem 0;
      }
   }
   .footers {
      padding: 0;
   }
   .footers {
      &__menu {
         padding: 2rem 0 1rem;
      }
   }
}