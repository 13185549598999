.video-actu{
   border-radius: .5rem;
   max-width: 40rem;
   height: auto;
   width: 100%;
   max-height: 30rem;
   background-color: rgba(0,0,0,0.6);
   border-radius: 1rem;
   width: 100%;
   margin: 2rem auto;
   max-width: 90%;
   .wp-video{
      border-radius: .5rem;
      max-width: 40rem;
      height: auto;
      width: 100% !important;
      max-height: 30rem;
   }
   video{
      border-radius: .5rem;
      max-width: 40rem;
      height: auto;
      width: 100%;
      max-height: 30rem;
   }
}

@media (min-width: 765px) {
   .video-actu{
      width: 100%;
      margin: 2rem auto 2rem 0;
      max-width: 40rem;
   }
   .main__news {
      padding-top: 6rem;

      .news {
         background-color: transparent;

         &__title {
            font-size: 3rem;
         }

         &__wrapper {
            grid-template-columns: repeat(3, 1fr);
         }
      }
   }
}

@media (min-width: 995px) {
   .main__news {
      padding-top: 0;
   }
}

@media (min-width: 995px) {
   .main__news {
      padding-top: 0;
      .news {
         background-color: transparent;
         &__title {
            padding-top: 2rem;
         }
      }
   }
}

