@use "../settings/settings";

.support {
   &-main {
      padding: 4rem 0;
      &__text,
      &__title {
         padding: 0 1.25rem;
      }
   }

   &-main,
   &-donation,
   &-team {
      &__title {
         position: relative;
         z-index: 1;
         font-size: 2rem;
         @include MontserratMedium;
         line-height: 1.25em;
         margin-bottom: 1.5rem;

         strong {
            display: inline-block;
            position: relative;
            @include MontserratBold;
         }

         strong::before {
            content: "";
            z-index: -1;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 35%;
            background-color: settings.$orangeColor;
         }
      }

      &__text {
         color: $colorAccessibleGrey;
         font-size: .875rem;
         @include MontserratRegular;
         line-height: 1.55em;
         margin-bottom: 1rem;
         max-width: 35rem;
      }

   }

   &__img {
      order: -1;
      width: 100%;
      height: auto;
      border-radius: 2rem;

      &-background {
         max-width: 100%;
         height: 100%;
      }

      &-wrapper {
         margin: 1rem 1.25rem 1rem;
         order: -1;
         position: relative;
      }
   }

   &-main {
      display: flex;
      flex-direction: column;
   }

   &-wrapper {
      border-radius: 1rem;
      margin-bottom: 2rem;

      &-container {
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         border-radius: 1rem;
         background: linear-gradient(rgba(54, 54, 54, 0) 0%, rgba(54, 54, 54, 0.88) 71.43%, #363636 100%);
         height: 100%;
      }

      &-title {
         padding: 8rem 1.25rem 1.5rem;
         font-size: 1.5rem; //24px
         line-height: 1.16em; //28px
         @include MontserratBold;
         color: settings.$colorWhite;
      }

      &-text {
         font-size: .875rem;
         @include MontserratRegular;
         line-height: 1.55em;
         padding: 0 1.25rem 1.5rem;
         color: settings.$colorWhite;
         max-width: 31rem;
      }

      &-link {
         font-size: 1rem;
         @include MontserratBold;
         padding: 1rem 1.5rem;
         border-radius: .5rem;
         background-color: settings.$darkBlueColor;
         color: white;
         display: inline-block;
         margin: auto 0 1.25rem 1.5rem;
         border: 2px solid settings.$darkBlueColor;
         transition: all 300ms;
         &:hover{
            background-color: transparent;
            color: settings.$darkBlueColor;
         }
      }
   }

   .support-team {
      .support-wrapper-link {
         background-color: settings.$purpleColor;
         border-color: settings.$purpleColor;
         margin-top: auto;

         &:hover{
            background-color: transparent;
            color: settings.$purpleColor;
         }
      }
   }

   &-donation {
      background-color: settings.$colorWhite;

      &__background {
         margin: 0;
         max-width: settings.$widthFullPage;
         padding: 4rem 1.25rem;
      }
   }

   &-team {
      margin: 0;
      max-width: settings.$widthFullPage;
      padding: 4rem 1.25rem;
   }
}

.money {
   background-image: url("/wp-content/themes/clinicoeurs/resources/img/template-support/don-money.jpg");
}

.material {
   background-image: url("/wp-content/themes/clinicoeurs/resources/img/template-support/don-material.jpg");
}

.article {
   background-image: url("/wp-content/themes/clinicoeurs/resources/img/template-support/article.jpg");
}

.legs {
   background-image: url("/wp-content/themes/clinicoeurs/resources/img/template-support/legs-en-duo.jpg");
}

.support-benevole {
   background-image: url("/wp-content/themes/clinicoeurs/resources/img/template-support/benevole.jpg");

}

.partner-benevole {
   background-image: url("/wp-content/themes/clinicoeurs/resources/img/template-support/partner.jpg");
}

.money,
.material,
.article,
.legs,
.support-benevole,
.partner-benevole {
   background-size: cover;
   background-position: center;
}

.event {
   background-color: settings.$colorWhite;

   &__background {
      padding-bottom: 2rem;
      margin: 0 auto;
      max-width: settings.$widthFullPage;
   }

   &__title {
      padding: 4rem 1.25rem 0;
      font-size: 2rem;
      display: inline-block;
      position: relative;
      z-index: 1;
      @include MontserratRegular;
      strong {
         @include MontserratBold;
         position: relative;
         z-index: 1;
         display: inline-block;
      }

      strong::before {
         content: "";
         z-index: -1;
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 35%;
         background-color: settings.$purpleColor;
      }
   }

   &__text {
      padding: 2rem 1.25rem 0;
      font-size: .875rem;
      line-height: 1.5em;
      @include MontserratRegular;
      color: settings.$colorAccessibleGrey;
   }

   &__link {
      font-size: 1rem;
      @include MontserratBold;
      padding: 1rem 1.5rem;
      margin: 3rem 1.25rem 0;
      background-color: settings.$purpleColor;
      color: settings.$colorWhite;
      display: inline-block;
      border-radius: .5rem;
   }

   &__img {
      border-radius: .5rem;
      margin: 2rem auto 4rem;
      display: block;
      max-width: 90%;
      height: auto;
   }
}
.support {
   .form {
      strong::before {
         background-color: settings.$greenColor;
      }
      &__submit {
         border-color: settings.$darkGreenColor;
         background-color: settings.$darkGreenColor;
         &:hover{
            color: settings.$darkGreenColor;
         }
      }
   }
}

@media (min-width: 765px) {
   .support {
      &__img{
         max-width: 30rem;
         &-wrapper{
            margin: 0;
         }
      }
      &-main {
         margin: 0 auto;
         max-width: settings.$widthFullPage;
         padding: 8rem 1.25rem 4rem;
         display: flex;
         justify-content: space-between;
         flex-direction: row;
         align-items: center;
         &__title,
         &__text{
            padding: 0;
         }
         &__background {
            order: -1;
            max-width: 30rem;
            margin-right: 2rem;
         }
      }

      &-donation {
         &__background {
            margin: 0 auto;
            max-width: settings.$widthFullPage;
         }

         &__wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2rem;
            margin-bottom: 2rem;
         }
      }

      &-wrapper {
         margin-bottom: 0;
         &-title {
            padding-top: 16rem;
         }
      }

      &-team {
         max-width: settings.$widthFullPage;
         margin: 0 auto;
      }
   }
   .material {
      .support-wrapper-text {
         padding-bottom: 3rem;
         max-width: 35rem;
      }
   }
   .event {
      &__wrapper {
         max-width: 50%;
      }

      &__background {
         max-width: settings.$widthFullPage;
         margin: 0 auto;
         padding-bottom: 4rem;
         display: flex;
         flex-direction: row;
         justify-content: space-between;
         align-items: center;
      }

      &__img {
         margin: 4rem 0;
         border-radius: 1rem;
      }
   }
}

@media (min-width: 995px) {
   .support{
      &-main{
         padding: 5rem 1.25rem;
         &__title {
            font-size: 3rem; //48px
            line-height: 1.16em; //56px
         }
      }
   }
   .event{
      &__title{
         padding-top: 0;
      }
      &__wrapper{
         max-width: 35rem;
      }
      &__img{
         margin-right: 1.25rem;
      }
   }
}
@media (min-width: settings.$widthFullPage) {
   .event{
      &__title,
      &__text{
         padding-left: 0;
         padding-right: 0;
      }
      &__link{
         margin-left: 0;
      }
      &__wrapper{
         max-width: 45%;
      }
      &__img{
         margin-right: 0;
      }
   }
   .support{
      &-main{
         padding: 5rem 0 ;
         &__title{
            padding-left: 0;
            padding-right: 0;
         }
         &__text{
            padding-left: 0;
            padding-right: 0;
         }
      }
      &-donation{
         &__background{
            padding: 4rem 0;
         }
      }
      &-team{
         padding: 4rem 0;
      }
   }
}