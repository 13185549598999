.js-enabled{
   .underline{
      strong::before{
         width: 0;
         transition: width 750ms ease-in-out;
      }
   }
   .underline-js{
      strong::before{
         width: 100%;
      }
   }
}