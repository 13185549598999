@use "../settings/settings";

.clinijuniors {
   .svg {
      &-ell1 {
         fill: settings.$orangeColor;
         left: -10%;
         top: -11%;
         max-width: 20%;
      }

      &-ell2 {
         fill: settings.$yellowColor;
         max-width: 50%;
         left: 64%;
         bottom: -10%;
      }

      &-rect1 {
         fill: settings.$yellowColor;
         right: -4rem;
         bottom: 10rem;
         max-width: 46%;
      }

      &-rect2 {
         fill: settings.$blueColor;
         max-width: 33%;
         left: -13%;
         top: 54%;
         z-index: 1;
      }
      &-rect3 {
         fill: settings.$greenColor;
         max-width: 12%;
         right: 4%;
         top: 82%;
         z-index: 1;
      }
   }
   &-main {
      overflow: hidden;
   }

   &__background {
      max-width: settings.$widthFullPage;
      margin: 0 auto;
   }
   &-learn{
      &__background{
         padding-bottom: 1rem;
      }
   }

   &-learn,
   &-who {
      background-color: settings.$colorWhite;
   }

   &-main,
   &-who,
   &-learn,
   {
      display: flex;
      flex-direction: column;
      padding: 2rem 1.25rem 0;

      &__title {
         position: relative;
         z-index: 1;
         font-size: 2rem;
         @include MontserratMedium;
         line-height: 1.25em;
         margin-bottom: 1.5rem;

         strong {
            display: inline-block;
            position: relative;
            @include MontserratBold;
         }

         strong::before {
            content: "";
            z-index: -1;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 35%;
            background-color: settings.$blueColor;
         }
      }

      &__text {
         color: $colorAccessibleGrey;
         font-size: .875rem;
         @include MontserratRegular;
         line-height: 1.55em;
      }

      &__link {
         display: inline-block;
         font-size: 1rem;
         @include MontserratBold;
         color: settings.$colorTextLink;
         margin: 1rem 0;
         padding: 1rem 1.5rem;
         max-width: 14rem;
         max-width: fit-content;

         &::before {
            content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-b.svg");
            transition: translate 300ms;
            display: inline-block;
            width: .5rem;
            height: .95rem;
            translate: -1rem;
         }

         &:hover,
         &:focus {
            color: settings.$blueColor;

            &::before {
               translate: -.4rem;
            }
         }
      }
   }

   &-recruitment {
      &__text {
         margin-bottom: 0;
      }
   }

   &-members {
      margin-bottom: 4rem;

      &__title {
         padding: 2.5rem 1.25rem 1.5rem;
         font-size: 2rem;
         @include MontserratMedium;
         line-height: 1.25em;

         strong {
            position: relative;
            @include MontserratBold;
            display: inline-block;
         }

         strong::before {
            content: "";
            z-index: -1;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 35%;
            background-color: settings.$blueColor;
         }
      }

      &__wrapper {
         display: flex;
         gap: 1.25rem;
         overflow: scroll;
         padding: 0 1.25rem 1rem;
      }
   }

   &__img {
      border-radius: 2rem;
      max-width: 100%;
      height: auto;
      margin-bottom: 2rem;

      &-wrapper {
         order: -1;
         position: relative;
      }
   }

   &-main {
      padding: 1rem 1.25rem 4rem;
   }
}

.clinijuniors {
   &-who,
   &-learn {
      .clinijuniors__img {
         margin-bottom: 4rem;
      }
   }
}


@media (min-width: 765px) {
   .clinijuniors__img-wrapper {
      margin-right: 0;
      justify-self: center;
   }
   .clinijuniors {
      &__img {
         margin: 0;
      }

      &-members {
         max-width: settings.$widthFullPage;
         margin: 0 auto;
         padding: 4rem 0;

         &__wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            justify-items: stretch;
            align-items: stretch;
            object-fit: cover;
            overflow: initial;
         }
      }

      &-who,
      &-learn {
         padding: 4rem 1.25rem;

         .clinijuniors__img {
            max-width: 50%;
            margin-bottom: 0;
            margin-left: 2rem;
         }
      }

      &-who {
         .clinijuniors__img {
            order: -1;
            margin-right: 2rem;
            margin-left: 0;
         }
      }

      &-main {
         justify-content: space-between;
         padding: 1rem 1.25rem 6rem;
         display: flex;
         flex-direction: row;
         max-width: settings.$widthFullPage;
         margin: 0 auto;

         .clinijuniors__img {
            border-radius: 4rem;
            max-width: 32rem;
         }

         &__wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 25rem;
            order: -1;
            margin-right: 2rem;
         }
      }

      &__background {
         display: flex;
         flex-direction: row;
         align-items: center;
      }

      &-activity {
         padding: 4rem 0;

         &__title {
            padding: 2rem 0 1rem;
         }

         &-wrapper {
            overflow: initial;
            max-width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
         }
      }
   }
   .recruitment-img {
      order: -1;
      margin-right: 2rem;
      margin-left: 0;
   }

}

@media (min-width: 995px) {
   .clinijuniors {
      &-main {
         padding: 5rem 1.25rem 6rem;
         &__title {
            font-size: 3rem;
            line-height: 1.16em;
         }
      }

      &-members {
         &__wrapper {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            column-gap: 1rem;
            row-gap: 1.75rem;
         }
      }
   }
}

@media (min-width: settings.$widthFullPage) {
   .clinijuniors {
      &-main {
         padding: 2rem 0 6rem;
         overflow: visible;
      }

      &__background {
         justify-content: space-between;
      }

      &-who,
      &-learn {
         &__background {
            max-width: 45%;
         }
      }

      &-members {
         &__title{
            padding: 2.5rem 0 1.5rem;
         }
         &__wrapper {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            padding: 0 0 4rem;
         }
      }
   }
}