@use "../settings/settings";

.faq-container {

   border-radius: .5rem .5rem 0 0;
   background-color: settings.$colorWhite;
   box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.faq {
   display: flex;
   justify-content: flex-start;
   flex-wrap: wrap;
   align-items: center;
   box-sizing: border-box;
   background: transparent;
   padding: 1.5rem 2rem 0 1.5rem;
   position: relative;
   overflow: hidden;
}

.faq:not(:first-child) {
   border-top: 1px solid #e6e6e6;
}

.faq-title {
   @include MontserratBold;
   margin: 0 2rem 1.5rem 0 ;
   line-height: 1.5em;
   transition: color 750ms ease, padding 750ms ease;
}

.faq-text {
   transition: all 750ms;
   overflow: hidden;
   height: 1px;
   @include MontserratRegular;
   line-height: 1.5rem;
}

.faq.active {
   background-color: #f8f8f8;
   box-shadow: inset 4px 0 0 0 settings.$purpleColor;
}

.faq.active .faq-title {
   color: #8b48c7;
}

.faq.active .faq-text {
   height: 14rem;
   margin-bottom: 1.5rem;
}

@media (min-width: 375px) {
   .faq.active .faq-text {
      height: 11rem;
   }
}
@media (min-width: 425px) {
   .faq.active .faq-text {
      height: 9rem;
   }
}
@media (min-width: 650px) {
   .faq.active .faq-text {
      height: 5rem;
   }
}
@media (min-width: 1100px) {
   .faq.active .faq-text {
      height: 6rem;
   }
}
@media (min-width: 1200px) {
   .faq.active .faq-text {
      height: 6rem;
   }
}
@media (min-width: 1300px) {
   .faq.active .faq-text {
      height: 5rem;
   }
}
.faq-toggle {
   background-color: transparent;
   border: 1px solid #e6e6e6;
   color: inherit;
   border-radius: 50%;
   cursor: pointer;
   font-size: 0;
   position: absolute;
   top: 1.25rem;
   right: 30px;
   height: 30px;
   width: 30px;
   transition: 0.3s ease;
   display: flex;
   justify-content: center;
   align-items: center;
   &:before{
      content: url("/wp-content/themes/clinicoeurs/resources/img/fleche.svg");
      width: .5rem;
      rotate: 90deg;
      transition: all 300ms;
   }
}

.faq-toggle:focus {
   outline: none;
}

.faq.active .faq-toggle {
   background-color: #f8f8f8;
   color: #fff;
   &:before{
      content: url("/wp-content/themes/clinicoeurs/resources/img/fleche.svg");
      width: .5rem;
      rotate: 270deg;
   }
}

@media (min-width: 765px){
   .faq{
      .active .faq-text {
         max-height: 17rem;
      }
      &-container{
         border-radius: .5rem .5rem 0 0;
      }
   }
   .product{
      &__img {
         margin-top: 0;
         width: 120%;
         height: auto;
         object-fit: cover;
         border-radius: 0 0 1rem 1rem;
      }
      &-faq {
         &__background {
            position: relative;
            z-index: 1;
            background-color: settings.$backgroundColor;
            padding-left: 0;
         }
      }
   }
}

@media (min-width: 1000px) {
   .product-faq{
      display: flex;
      align-items: flex-start;
      &__background{
         min-width: 27rem;
      }
   }
   .faq-container{
      border-radius: .5rem;
   }
   .product__img{
      max-width: 50%;
      border-radius: 1rem;
   }
}
@media (min-width: settings.$widthFullPage) {
   .product{
      &-faq {
         &__background {
            padding-left: 0;
         }
      }
   }
}