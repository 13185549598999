@use "../settings/settings";

[lang] body {
   background: settings.$backgroundColor repeat url("../../resources/img/fond-color.svg");
   background-size: 50%;
}

@keyframes ellipseBackground {
   0% {
      rotate: 0;
   }
   100% {
      rotate: 360deg;
   }
}

@keyframes rectBackground {
   0% {
      rotate: 0;
   }
   100% {
      rotate: -360deg;
   }
}

.svg {
   &-ell1 {
      position: absolute;
      fill: settings.$yellowColor;
      max-width: 30%;
      left: -4%;
      bottom: 15%;
      height: auto;
   }

   &-ell2 {
      position: absolute;
      z-index: -1;
      fill: settings.$orangeColor;
      max-width: 50%;
      left: 10%;
      bottom: 15%;
      height: auto;
   }

   &-rect1 {
      position: absolute;
      z-index: -1;
      fill: settings.$purpleColor;
      max-width: 60%;
      height: auto;
      right: -1rem;
      bottom: 2rem;
      animation: ellipseBackground 15s infinite linear;
   }

   &-rect2 {
      position: absolute;
      z-index: -1;
      fill: settings.$greenColor;
      max-width: 18%;
      height: auto;
      left: 4%;
      top: 30%;
      animation: rectBackground 16s infinite linear;
   }

   &-rect3 {
      position: absolute;
      fill: settings.$orangeColor;
      max-width: 10%;
      height: auto;
      right: 4%;
      top: 15%;
      animation: ellipseBackground 11s infinite linear;
   }
}

.index {
   overflow: hidden;
   padding: 5rem 1.25rem 5rem;
   display: flex;
   flex-direction: column;
   align-items: flex-start;

   strong {
      position: relative;
      display: inline-block;
      @include MontserratBold;

      &:last-of-type::before {
         background-color: settings.$greenColor;
      }
   }

   strong::before {
      content: "";
      z-index: -1;
      position: absolute;
      bottom: 6px;
      left: 0;
      width: 100%;
      height: 27%;
      background-color: settings.$orangeColor;
   }

   &__title {
      font-size: 2rem;
      @include MontserratMedium;
      line-height: 1.5em;
      padding-top: 1rem;
   }

   &__description {
      padding-top: 1rem;
      font-size: .875rem;
      @include MontserratRegular;
      line-height: 1.55em;
      text-align: left;
      color: settings.$colorAccessibleGrey;
   }

   &__link {
      display: inline-block;
      font-size: 1.25rem;
      @include MontserratBold;
      color: settings.$colorTextLink;
      margin: 2.5rem 0 0;
      padding-left: 1rem;
      transition: all 300ms;

      &::before {
         content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-o.svg");
         transition: translate 300ms;
         display: inline-block;
         width: .5rem;
         height: .95rem;
         translate: -1rem;
      }

      &:hover,
      &:focus {
         color: settings.$orangeColor;

         &::before {
            translate: -.4rem;
         }
      }
   }

   &__img {
      order: -1;
      width: 100%;
      height: auto;
      border-radius: 2rem;

      &-wrapper {
         position: relative;
         order: -1;
      }
   }

}

.services {
   background-color: settings.$colorWhite;
   padding: 5rem 1.25rem;

   &__background {
      max-width: settings.$widthFullPage;
      margin: 0 auto;
   }

   &__title {
      position: relative;
      z-index: 1;
      font-size: 2rem;
      @include MontserratMedium;
      line-height: 1.5em;

      strong {
         position: relative;
         display: inline-block;
         @include MontserratBold;
      }

      strong::before {
         content: "";
         z-index: -1;
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 35%;
         background-color: settings.$purpleColor;
      }
   }

   &__sro {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: 0;
   }

   &__text-link {
      @include MontserratBold;
      font-size: .875rem;
      color: settings.$colorTextLink;
      display: inline-flex;
      transition: all 300ms;
      margin-top: auto;
      padding: 1rem 1rem 1rem 1.5rem;
      cursor: pointer;

      &::before {
         content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-o.svg");
         display: inline-block;
         transition: translate 300ms;
         width: .5rem;
         height: .95rem;
         translate: -1.5rem;
      }
   }

   &__card {
      display: flex;
      text-align: left;
      flex-direction: column;
      margin: 2rem auto 0;
      padding: 1rem;
      max-width: 24rem;
      min-height: 36rem; // MOBILE
      max-height: 460px;
      border-radius: .5rem;
      background-color: #fffcfa;
      box-shadow: 0 3px 6px #ffefe6;
      border: 1px solid settings.$orangeColor;
      position: relative;

      &:hover .services__text-link,
      &:focus .services__text-link {
         color: settings.$orangeColor;

         &::before {
            translate: -.5rem;
         }
      }

      &:nth-child(2) {
         background: #f8fffb;
         border: 1px solid settings.$greenColor;
         box-shadow: 0px 3px 6px #e5ffef;
      }

      &:nth-child(3) {
         background: #faffff;
         border: 1px solid settings.$blueColor;
         box-shadow: 0px 3px 6px #e3fffe;
      }

      &:nth-child(4) {
         background: #fbfaff;
         border: 1px solid settings.$purpleColor;
         box-shadow: 0px 3px 6px #f2efff;
      }

      &-title {
         text-align: left;
         position: relative;
         transition: all 300ms;
         font-size: 1.5rem;
         @include MontserratBold;
         margin: -2rem 0 0;
         padding-top: 1.5rem;
         border-top: 2px solid lightgrey;
      }

      &-description {
         color: settings.$colorAccessibleGrey;
         text-align: left;
         width: 100%;
         padding: 1rem 0;
         font-size: .875rem;
         @include MontserratRegular;
         line-height: 1.5em;
      }

      &-img {
         width: 100%;
         max-width: 12rem;
         min-height: 14rem;
         object-fit: cover;
         height: auto;
         order: -1;
         position: relative;
         top: -2rem;
         align-self: center;
      }
   }
}

.services__card:hover,
.services__card:focus {
   [data-name="Cliniclowns"] {
      color: settings.$orangeColor;
   }

   [data-name="Clinitalents"] {
      color: settings.$greenColor;
   }

   [data-name="Clinisnoezs"] {
      color: settings.$purpleColor;
   }

   [data-name="Clinijuniors"] {
      color: settings.$blueColor;
   }
}

[data-name="Cliniclowns"] {
   &::before {
      content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-o.svg");
   }
}

[data-name="Clinitalents"] {
   &::before {
      content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-v.svg");
   }
}

[data-name="Clinisnoezs"] {
   &::before {
      content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-p.svg");
   }
}

[data-name="Clinijuniors"] {
   &::before {
      content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-b.svg");
   }
}

.creations {
   padding: 0 0 4rem;
   background-color: settings.$colorWhite;

   &__background {
      max-width: settings.$widthFullPage;
      margin: 0 auto;
   }

   &__title {
      position: relative;
      padding: 0 1.25rem 0;
      z-index: 1;
      font-size: 2rem;
      @include MontserratMedium;
      line-height: 1.5em;

      strong {
         display: inline-block;
         @include MontserratBold;
         position: relative;
      }

      strong::before {
         content: "";
         z-index: -1;
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 35%;
         background-color: settings.$orangeColor;
      }
   }
}

.product-main__link {
   transition: all 300ms;

   &:hover,
   &:focus {
      scale: 1.1;
   }

   &:nth-child(1) {
      .product-main__card-title {
         background-color: settings.$purpleColor;
      }
   }

   &:nth-child(2) {
      .product-main__card-title {
         background-color: settings.$orangeColor;
      }
   }

   &:nth-child(3) {
      .product-main__card-title {
         background-color: settings.$darkBlueColor;
      }
   }

   &:nth-child(4) {
      .product-main__card-title {
         background-color: settings.$darkGreenColor;
      }
   }

   &:nth-child(5) {
      .product-main__card-title {
         background-color: settings.$darkBlueColor;
      }
   }

   &:nth-child(6) {
      .product-main__card-title {
         background-color: settings.$purpleColor;
      }
   }

   &:nth-child(7) {
      .product-main__card-title {
         background-color: settings.$darkGreenColor;
      }
   }

   &:nth-child(8) {
      .product-main__card-title {
         background-color: settings.$orangeColor;
      }
   }
}

.volunteers {
   max-width: settings.$widthFullPage;
   margin: 0 auto;
   padding: 5rem 1.25rem;
   display: flex;
   flex-direction: column;
   align-items: flex-start;

   &__title {
      padding-top: 1.5rem;
      position: relative;
      z-index: 1;
      font-size: 2rem;
      @include MontserratMedium;
      line-height: 1.5em;

      strong {
         display: inline-block;
         @include MontserratBold;
         position: relative;
      }

      strong::before {
         content: "";
         z-index: -1;
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 35%;
         background-color: settings.$greenColor;
      }
   }

   &__description {
      padding-top: 1rem;
      font-size: .875rem;
      @include MontserratRegular;
      line-height: 1.55em;
      text-align: left;
      color: settings.$colorAccessibleGrey;
   }

   &__link {
      transition: all 300ms;
      margin-top: 1.5rem;
      display: inline-block;
      font-size: 1.25rem;
      @include MontserratBold;
      color: settings.$colorTextLink;
      padding: 1rem 1.5rem;
      border-radius: .5rem;

      &::before {
         content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-v.svg");
         display: inline-block;
         transition: translate 300ms;
         width: .5rem;
         height: .95rem;
         translate: -1.5rem;
      }

      &:focus,
      &:hover {
         color: settings.$greenColor;

         &::before {
            translate: -.5rem;
         }
      }
   }

   &__container {
      order: -1;
      position: relative;
   }

   &__firstImage {
      max-width: 100%;
      height: auto;
      border-radius: 2rem;
      position: relative;
   }

   &__secondImage {
      max-width: 100%;
      height: auto;
      border-radius: 2rem;
   }
}

.reviews {
   background-color: settings.$colorWhite;
   padding: 0 0 5rem;

   &__background {
      max-width: settings.$widthFullPage;
      margin: 0 auto;
   }

   &__title {
      padding: 0 1.25rem;
      position: relative;
      z-index: 1;
      font-size: 2rem;
      @include MontserratMedium;
      line-height: 1.5em;

      strong {
         display: inline-block;
         @include MontserratBold;
         position: relative;
      }

      strong::before {
         content: "";
         z-index: -1;
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 35%;
         background-color: settings.$purpleColor;
      }
   }

   &__container {
      margin: 2rem 1rem 0 0;
      padding: 1.5rem;
      border: 1px solid settings.$colorAccessibleGrey;
      min-height: 12rem;
      max-height: 12rem;
      min-width: 20rem;
      background-color: settings.$colorWhite;
      border-radius: 1rem;
   }

   &__wrapper {
      padding: 0 1.25rem 1rem;
      overflow-x: scroll;
      overflow-y: auto;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      &-text {
         display: flex;
         justify-content: flex-start;
         align-items: center;
         padding-bottom: 1.5rem;
      }
   }

   &__img {
      display: inline-block;
      max-width: 4rem;
      max-height: 4rem;
      object-fit: cover;
      border-radius: 2rem;
      height: auto;
      margin-right: 1rem;
   }

   &__name {
      font-size: .875rem;
      @include MontserratBold;
      order: 1;
   }

   &__description {
      line-height: 1.25em;
      color: settings.$colorAccessibleGrey;
   }
}


@media (min-width: 375px) {
   .services {
      &__card {
         min-height: 36rem;
      }

      &__text-link {
         font-size: 1rem;
      }
   }
}

@media (min-width: 765px) {
   .svg {
      &-ell1 {
         position: absolute;
         fill: settings.$yellowColor;
         max-width: 30%;
         left: -4%;
         bottom: 15%;
         height: auto;
      }

      &-ell2 {
         position: absolute;
         z-index: -1;
         fill: settings.$orangeColor;
         max-width: 50%;
         left: 10%;
         bottom: 25%;
         height: auto;
      }

      &-rect1 {
         position: absolute;
         z-index: -1;
         fill: settings.$purpleColor;
         max-width: 60%;
         height: auto;
         right: 0;
         bottom: 18%;
      }
   }
   .index {
      margin: 0 auto;
      max-width: settings.$widthFullPage;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;

      &__wrapper {
         padding: 4rem 0;
         order: -1;
         display: flex;
         flex-direction: column;
         justify-content: center;
         max-width: 25rem;
      }

      &__img {
         display: inline-block;
         max-width: 100%;
         object-fit: cover;
      }

      &__link {
         white-space: nowrap;
         max-width: max-content;
      }
   }
   .services {
      &__wrapper {
         display: grid;
         flex-wrap: wrap;
         justify-content: space-between;
         grid-template-columns: 1fr 1fr;
         row-gap: 0;
         column-gap: 2rem;
         flex: 0;
      }

      &__card {
         width: 100%;
         max-width: 25rem;
      }
   }
   .creations {
      &__wrapper {
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
      }

      &__card {
         max-width: 25%;

         &-title {
            font-size: 1.15rem;
         }
      }
   }
   .volunteers {
      display: flex;
      flex-direction: row;
      align-items: center;

      &__wrapper {
         align-items: flex-start;
         display: flex;
         flex-direction: column;
         margin-left: 2rem;
         max-width: 45%;
      }

      &__container {
         width: 160%;
      }

      &__secondImage {

         object-fit: cover;
      }
   }
   .reviews {
      padding-bottom: 3rem;

      &__container {
         max-width: 20rem;
         min-width: 20rem;
         border: .5px solid settings.$colorAccessibleGrey;
      }

      &__wrapper {
         overflow-x: scroll;
         overflow-y: auto;
         padding: 1rem 0;

         &-text {
            display: flex;
            align-items: center;
            padding-bottom: 1.5rem;
         }
      }
   }
}

@media (min-width: 995px) {
   .index {
      padding: 5rem 1.25rem;

      &__link {
         margin: 1.5rem 0;
      }

      strong::before {
         content: "";
         z-index: -1;
         position: absolute;
         bottom: 0px;
         left: 0;
         width: 100%;
         height: 28%;
         background-color: settings.$orangeColor;
      }

      &__wrapper {
         max-width: 32rem;
         padding: 6rem 0 6rem 0;
      }

      &__title {
         font-size: 3rem;
         line-height: 3.5rem;
      }
   }
   .services__wrapper {
      gap: 1.25rem;
   }
   .services__card-img {
      min-height: 14rem;
      max-height: 14rem;
   }
}

@media (min-width: 1160px) {
   .index {
      justify-content: space-between;
   }
   .creations {
      &__title {
         padding: 0 1.25rem 2rem;
      }

      &__wrapper {
         padding: 0 0 2rem;
      }
   }
   .volunteers {
      padding: 5rem 1.25rem;
   }
}

.construct__figure {
   text-align: center;
   position: relative;
}

.construct__mode {
   position: absolute;
   background-color: white;
   border-radius: 0 0 1rem 0;
   font-weight: bold;
   font-size: 1.2rem;
   font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
   padding: .5rem;
}

@media (min-width: settings.$widthFullPage) {
   .services__wrapper {
      grid-template-columns: repeat(4, 1fr);
   }
   .index {
      overflow: visible;
      justify-content: flex-start;
      gap: 10rem;
      padding: 5rem 0;

      &__wrapper {
         padding: 6rem 0;
      }
   }
   .volunteers {
      padding: 5rem 0;

      &__container {
         width: 54%;
      }

      &__wrapper {
         max-width: 40%;
      }
   }
   .creations {
      &__title {
         padding: 0 0 2rem;
      }
   }
   .reviews {
      &__title {
         padding: 0;
      }
   }
}

@media (max-width: 375px) {
   .index {
      &__link {
         line-height: 1.5em;
      }
   }
}