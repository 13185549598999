@use "../settings/settings";

.clinitalents {
   &-main {
      overflow: hidden;
   }

   &-main,
   &-who,
   &-recruitment,
   &-members,
   {
      max-width: settings.$widthFullPage;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      padding: 2rem 1.25rem 0;

      &__title {
         position: relative;
         z-index: 1;
         font-size: 2rem;
         @include MontserratMedium;
         line-height: 1.5em;
         margin-bottom: 1.5rem;

         strong {
            display: inline-block;
            position: relative;
            @include MontserratBold;
         }

         strong::before {
            content: "";
            z-index: -1;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 35%;
            background-color: settings.$greenColor;
         }
      }

      &__text {
         color: $colorAccessibleGrey;
         font-size: .875rem;
         @include MontserratRegular;
         line-height: 1.55em;
         margin-bottom: 1rem;
      }

      &__link {
         display: inline-block;
         font-size: 1.25rem;
         @include MontserratBold;
         color: settings.$colorTextLink;
         margin: 1rem 0;
         padding: 1rem 1.5rem;
         border-radius: .5rem;
         max-width: 14rem;
         max-width: fit-content;
         transition: all 300ms;

         &::before {
            content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-v.svg");
            transition: translate 300ms;
            display: inline-block;
            width: .5rem;
            height: .95rem;
            translate: -1rem;
         }

         &:hover,
         &:focus {
            color: settings.$greenColor;

            &::before {
               translate: -.4rem;
            }
         }
      }
   }

   &-recruitment {
      &__text {
         margin-bottom: 0;
      }
   }

   &-members {
      padding: 4rem 0 0;

      &__title {
         padding: 0 1.25rem;
      }

      &__wrapper {
         display: flex;
         overflow: scroll;
         padding: 0 1.25rem 4rem;
      }
   }

   &-who,
   &-recruitment {
      max-width: initial;
      background-color: settings.$colorWhite;
   }

   &__background {
      max-width: settings.$widthFullPage;
      margin: 0 auto;
   }

   &-formation__img {
      max-width: 100%;
      height: auto;
      border-radius: 2rem;
      margin-bottom: 4rem;
   }

   &-main {
      padding-top: 1rem;
      gap: 2rem;

      &__text {
         margin-bottom: 4rem;
      }
   }
}

.members-clinitalents-img {
   margin-right: 1.25rem;

   &:last-of-type {
      margin-right: 0;
   }
}


.clinitalents {
   &-activity {
      max-width: settings.$widthFullPage;
      margin: 0 auto;

      &-wrapper {
         display: flex;
         overflow: scroll;
      }

      &__title {
         padding: 2rem 1.25rem 0;
         position: relative;
         z-index: 1;
         font-size: 2rem;
         @include MontserratMedium;
         line-height: 1.5em;
         margin-bottom: 1.5rem;

         strong {
            @include MontserratBold;
            display: inline-block;
            position: relative;
         }

         strong::before {
            content: "";
            z-index: -1;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 35%;
            background-color: settings.$greenColor;
         }
      }
   }
}

.cards {
   &__activity {
      display: flex;
      padding: 0 1.25rem 1rem;
      margin-bottom: 3rem;

      &-wrapper {
         background-color: settings.$colorWhite;
         border-radius: 0 1rem 1rem 0;
         padding: 1.5rem 1.5rem;
         margin-right: 2rem;
      }

      &-title {
         font-size: 1.25rem;
         @include MontserratBold;
         margin-bottom: 1.5rem;
      }

      p {
         font-size: .875rem;
         @include MontserratRegular;
         line-height: 1.55em;
         color: settings.$colorAccessibleGrey;
      }

      &-img {
         border-radius: 1rem 0 0 1rem;
         order: -1;
         min-width: 35%;
         height: auto;
         max-width: 20rem;
         object-fit: cover;
      }
   }
}

@media (min-width: 765px) {
   .members-clinitalents-img {
      margin-right: 0;
      justify-self: center;
   }
   .clinitalents {
      &-members {
         &__wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            justify-items: stretch;
            align-items: stretch;
            overflow: initial;
            object-fit: cover;
            column-gap: 1rem;
            row-gap: 1rem;
         }
      }

      &-who,
      &-activity,
      &-recruitment {
         padding: 4rem 1.25rem;
      }

      &-main {
         justify-content: space-between;
         padding: 0 1.25rem 4rem;
         display: flex;
         flex-direction: row;

         &__wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 30rem;
            order: -1;
         }
      }

      &__background {
         display: flex;
         flex-direction: row;
         align-items: center;
      }

      &-formation__img {
         max-width: 50%;
         margin: 0;
         margin-left: 2rem;
      }

      &-activity {
         &__title {
            padding: 2rem 0 1rem;
         }

         &-wrapper {
            overflow: initial;
            max-width: 100%;
            display: grid;
            gap: 2rem;
            grid-template-columns: 1fr 1fr;
            align-items: center;
         }
      }
   }
   .cards__activity {
      padding: 0;
      max-width: 100%;
      height: auto;
      align-self: stretch;
      margin-bottom: 0;
      max-height: 15rem;

      &-img {
         width: 15rem;
         height: 15rem;
      }
   }
   .cards__activity-wrapper {
      margin: 0;
   }
   .recruitment-img {
      order: -1;
      margin-right: 2rem;
      margin-left: 0;
   }

}

@media (min-width: 995px) {
   .clinitalents {
      &-main {
         padding: 5rem 1.25rem 6rem;

         &__title {
            font-size: 3rem;
            line-height: 1.16em;
         }
      }

      &-members {
         &__wrapper {
            grid-template-columns: 1fr 1fr 1fr 1fr;
         }
      }
   }
}

@media (min-width: settings.$widthFullPage) {
   .clinitalents {
      &-main {
         padding: 2rem 0 6rem;
         overflow: visible;
      }

      &__background {
         justify-content: space-between;
      }

      &-recruitment,
      &-who {
         &__wrapper {
            max-width: 45%;
         }
      }

      &-members {
         &__title {
            padding: 0;
         }

         &__wrapper {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            padding: 0 0 4rem;
         }
      }

      &-activity {
         padding: 4rem 0;
      }
   }

}