@use "../settings/settings";

/*SECTION 4 */
.volunteering {
   &-profil{
      &__background {
         max-width: 30rem;
      }
   }
   &-job {
      background-color: settings.$colorWhite;

      &-container {
         padding: 2.5rem 0;
      }
   }
}


.slider-container {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
}

.slider {
   margin: 0 1.5rem 0 1.5rem;
   text-align: left;
   border-radius: .5rem;
   position: relative;
}

.slides {
   display: flex;
   overflow-x: scroll;
   position: relative;
   scroll-behavior: smooth;
   scroll-snap-type: x mandatory;
   gap: 1.5rem;
}

.slide {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-right: 0;
   box-sizing: border-box;
   background: settings.$colorWhite;
   transform-origin: center center;
   transform: scale(1);
   scroll-snap-align: center;
}

.slider__nav {
   margin: 20px auto;
   text-align: center;
}

a .slide__next {
   transform: rotate(-45deg);
   right: 5%;
}

.slider__navlink {
   font-size: 0;
   transition: all 0.5s;
   display: inline-block;
   height: 10px;
   width: 10px;
   border-radius: 50%;
   margin: 0 6px 0 6px;
   color: settings.$colorBlack;
   background-color: settings.$colorWhite;
   border: 2px solid settings.$purpleColor;
}

.slider-navlink-active {
   background-color: settings.$purpleColor;
   width: 40px;
   height: 10px;
   border-radius: 1rem;
   color: settings.$colorWhite;
}

.card-reviews {
   padding: 2rem 1.25rem 1.25rem;
   border: 2px solid settings.$purpleColor;
   border-radius: 1rem;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   min-height: 24.75rem;
   min-width: 20.4rem;
   align-items: flex-start;
}

.card-reviews .quote {
   position: relative;
}

.card-reviews > .quote::before {
   content: url("../../../img/quote.svg");
   position: absolute;
   top: -8px;
   left: -8px;
   z-index: -1;
   display: inline-block;
   width: 1.5rem;
}

.card-reviews .quote::after {
   content: url("../../../img/quote.svg");
   position: absolute;
   bottom: 0;
   right: 0;
   z-index: -1;
   rotate: 180deg;
   display: inline-block;
   width: 1.5rem;
}

.card-reviews h3 {
   font-size: 1.5rem;
   line-height: 1.25em;
   @include MontserratBold;
   color: #0f141e;
   margin-bottom: 0.5rem;
   text-align: left;
}

.card-reviews p:first-of-type {
   font-size: 0.875rem;
   @include settings.MontserratRegular;
   line-height: 1.5rem;
   margin-bottom: 1.2rem;
}

.identity-card {
   margin-top: auto;
   display: flex;
   align-items: center;
}

.identity-card div {
   display: flex;
   flex-direction: column;
   justify-content: stretch;
}

.identity-card img,
.identity-container img {
   max-width: 4rem;
   height: auto;
   border-radius: 2rem;
}

.identity-card div > p {
   color: #0f141e;
   font-size: .875rem;
   @include settings.MontserratBold;
   line-height: 1.75; /*************CONVERSION*/
   margin: 0 0 0 1rem;
}

.identity-card div > p:first-of-type {
   @include settings.MontserratBold;
   line-height: 18px; /*************CONVERSION*/
   margin-bottom: .5rem;
}

.slider .anchor-hack {
   display: block;
   position: relative;
   height: 0;
   top: -17rem;
   visibility: hidden;
}

.slider span[id="slide-4"] {
   top: -18.2rem;
}

.slider span[id="slide-6"],
.slider span[id="slide-5"],
.slider span[id="slide-4"],
.slider span[id="slide-3"],
.slider span[id="slide-2"] {
   right: -8.2rem;
}

.arrow-left,
.arrow-right {
   background: transparent;
   border: none;
   display: none;
}

@media (min-width: 390px) {
   .slides {
      max-width: 21rem;
      margin: 0 auto;
      padding-bottom: .5rem;
   }
}

@media (min-width: 720px) {
   .volunteering-job-container {
      overflow: hidden;
   }
   button[href="#slide-2"],
   button[href="#slide-3"] {
      display: none;
   }

   .slides {
      max-width: 43rem;
      margin: 0 auto;
   }
   .slider span[id="slide-4"] {
      right: -20rem;
   }
   .arrow-left,
   .arrow-right {
      font-size: 0;
      display: none;
      opacity: 1;
      transition: all 200ms;
      position: absolute;
      top: 11rem;
      z-index: 2;
   }

   .arrow-hidden {
      opacity: 0;
   }
}

@media (min-width: 995px) {
   .arrow-left{
      left: -1rem;
   }
   .arrow-right{
      right: -1rem;
   }
   .arrow-left,
   .arrow-right {
      display: initial;
   }
   .volunteering-job-container {
      max-width: settings.$widthFullPage;
      margin: 0 auto;
      padding: 5rem 0;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
   }
   .volunteering-profil {
      &__background {
         display: flex;
         flex-direction: column;
         justify-content: start;
      }

      &__title {
         padding-top: 2rem;
      }
   }

}

@media (max-width: 375px) {
   .card-reviews{
      min-width: 100%;
   }
}