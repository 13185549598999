@use "../settings/settings";

a.evitement {
   display: inline-block;
   color: #555;
   background: #fff;
   padding: .5em;
   position: absolute;
   left: -99999px;
   z-index: 100;
}

a.evitement:focus {
   left: 0;
}

header {
   width: 100%;
   position: fixed;
   z-index: 3;
   background-color: white;
   height: 4rem;
   padding: 1rem 1.5rem;
   box-shadow: 0 1px 5px rgba(0, 0, 0, 0.16);
}

/* LOGO */
.logo-mobile {
   font-size: 2rem;

   img {
      margin: 2px 0;
      width: 8rem;
      height: auto;
      z-index: 5;
   }
}

/* BURGER MENU */
label[id="burger"] {
   z-index: 15;
}

.burger-wrapper {
   font-size: 0;
   padding: 12px 8px 8px;
   position: absolute;
   top: 0.3rem;
   right: 1.25rem;
}

.bar1,
.bar2,
.bar3 {
   opacity: 1;
   transition: all 500ms;
   transform: translate(0, 0);
   display: block;
   width: 24px;
   height: 2px;
   background-color: black;
   margin: 6px 0;
   transform-origin: center;
   border-radius: 1rem;
}

[id="checkbox_toggle"]:checked ~ .menu {
   z-index: 4;
   top: 0;
   right: 0;
   opacity: 1;
}

[id="checkbox_toggle"] {
   height: 40px;
   width: 40px;
   position: absolute;
   top: 0.8rem;
   right: 0.8rem;
   opacity: 0;
}


[id="checkbox_toggle"]:checked ~ label > .bar1 {
   transition: all 500ms;
   //translate: 0 9px;
   //rotate: 45deg;
   transform: translate(0, 9px) rotate(45deg);
}

[id="checkbox_toggle"]:checked ~ label > .bar2 {
   transition: all 200ms;
   transform: translateX(20px);
   opacity: 0;
}

[id="checkbox_toggle"]:checked ~ label > .bar3 {
   transition: all 500ms;
   transform: translate(0, -7px) rotate(-45deg);
}

.menu {
   position: fixed;
   z-index: 4;
   top: 0;
   right: -100rem;
   background-color: settings.$colorWhite;
   display: flex;
   gap: .5rem;
   font-size: 1rem;
   transition: all 400ms 100ms;
   flex-direction: column;
   align-content: center;
   align-items: flex-start;
   justify-content: flex-start;
   margin-top: 3.9rem;
   height: 100vh;
   width: 100vw;
   opacity: 0;
   overflow-y: scroll;

   &-li {
      margin-bottom: .5rem;
   }

   a {
      font-size: 1.5rem;
      line-height: 1.66em;
      @include MontserratBold;
      color: settings.$colorAccessibleGrey;
   }

   [aria-current='page'] {
      color: settings.$colorBlack;
      border-bottom: 2px solid settings.$purpleColor;
   }
}

.menu-li-dropdown {
   position: relative;
   margin-bottom: .5rem;
}

.sub-menu {
   background-color: settings.$colorWhite;
   max-height: 0;
   min-height: 0;
   opacity: 1;
   overflow: hidden;
   transition: translate .5s, max-height .5s;
}

[id="check-box-services"] {
   position: absolute;
   opacity: 0;
   width: 100%;
   height: 2rem;
}

[id="check-box-services"]:checked ~ .sub-menu {
   max-height: 10rem;
   min-height: 0;
   translate: 1rem;
}

.menu-dropdown {
   font-size: 1.5rem;
   line-height: 1.66em;
   @include MontserratBold;
   color: settings.$colorAccessibleGrey;
}

.container-top-menu {
   li {
      a {
         text-transform: uppercase;
      }
   }

   .menu__top-contact {
      a {
         text-transform: initial;
      }
   }
}

.top-menu__background {
   background-color: rgba(189, 189, 189, 0.25);
   width: 100%;
   order: 1;
}

.top-menu-container {
   display: flex;
   flex-direction: column;
   width: 100%;
   padding: 2rem 1.25rem 14rem;

   .top-contact {
      font-size: 1.25rem;
      padding-bottom: 1rem;
      line-height: initial;
   }
}

.container {
   padding: 1rem 1.25rem;
}

.langages {
   &__container {
      display: flex;
      gap: 1rem;
   }

   &__item {
      a {
         font-size: 1.25rem;
         text-transform: uppercase;
      }

      &-current {
         .langages__link {
            color: settings.$blueMenu;
            border-bottom: 4px solid settings.$blueMenu;
         }
      }
   }
}

[lang="fr"],
[lang="de"],
[lang="nl"],
[lang="en"] {
   .socialmedia {
      display: flex;
      gap: 1rem;
      margin-bottom: 1rem;

      .socialmedia__network {
         &--fb,
         &--tw,
         &--ig,
         &--tk {
            transform-origin: center;

            &:hover,
            &:focus {
               &::before {
                  transform: scale(1.2);
               }
            }
         }

         &--fb {
            font-size: 0;

            &::before {
               content: url("/wp-content/themes/clinicoeurs/resources/img/social-media/facebook-black.svg");
               display: inline-block;
               width: 2.25rem;
               height: auto;
               transition: all 300ms;
            }
         }

         &--tw {
            font-size: 0;

            &::before {
               content: url("/wp-content/themes/clinicoeurs/resources/img/social-media/twitter-black.svg");
               display: inline-block;
               width: 2.25rem;
               height: auto;
               transition: all 300ms;
            }
         }

         &--ig {
            font-size: 0;

            &::before {
               content: url("/wp-content/themes/clinicoeurs/resources/img/social-media/instagram-black.svg");
               display: inline-block;
               width: 2.25rem;
               height: auto;
               transition: all 300ms;
            }
         }

         &--tk {
            font-size: 0;

            &::before {
               content: url("/wp-content/themes/clinicoeurs/resources/img/social-media/tiktok-black.svg");
               display: inline-block;
               width: 2.25rem;
               height: auto;
               transition: all 300ms;
            }
         }
      }
   }
}


.logo-desktop {
   display: none;
}

.top-menu-container .menu__top-contact a {
   font-size: 1.25rem;
   color: #000000b5;
}

@media (min-width: 995px) {
   [lang="fr"],
   [lang="en"] {
      header {
         position: sticky;
      }

      .menu {
         width: 100%;
         position: initial;
         height: initial;
         opacity: 1;
         top: 0;
         left: 0;
         margin-top: 0;
         padding: 0;
         transition: all 0.5s;
         overflow: visible;
         background-color: initial;
         max-width: initial;
         gap: 0;
         display: flex;
         flex-direction: column;
         justify-content: flex-end;
         align-items: flex-start;
         transition: all 0.1ms;

         [aria-current=page] {
            &::after {
               width: 100%;
            }
         }

         &-li {
            margin-bottom: 0;
         }

         li a {
            position: relative;

            &::after {
               transition: all 300ms ease-out;
               position: absolute;
               bottom: -.125rem;
               left: 0;
               right: 0;
               margin: auto;
               width: 0;
               content: "";
               color: transparent;
               background: settings.$purpleColor;
               min-height: 2px;
               max-height: 2px;
            }
         }

         .menu-li:hover a::after,
         .menu-li-dropdown li:hover:first-of-type a::after,
         .menu-li:focus-within a::after,
         .menu-li-dropdown li:focus-within:first-of-type a::after,
         {
            width: 100%;
         }

         .activeMenu a::after {
            transition: all 300ms ease-out;
            position: absolute;
            bottom: -.25rem;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            content: "."  / "";
            color: transparent;
            background: white;
            min-height: 2px;
            max-height: 2px;
         }

         li {
            padding: 1.5rem 0;
            color: white;
         }

         li:first-of-type:hover,
         li:first-of-type:focus-within,
         .sub-menu li:focus-within,
         .sub-menu li:hover,
         .top-contact li:focus-within,
         .top-contact li:hover {
            a {
               color: settings.$colorBlack;

               &::after {
                  width: 100%;
               }
            }
         }

         .menu-li:hover a {
            color: settings.$colorBlack;
         }

         > li {
            position: relative;
         }

         a {
            line-height: 1.25rem;
            display: block;
            font-size: .875rem;
            color: settings.$colorAccessibleGrey;
            transition: color .2s;
            overflow: visible;
         }

         &-dropdown {
            font-size: .875rem;
            line-height: 1.55em;
            cursor: pointer;

            &:hover {
               color: settings.$colorBlack;
               cursor: pointer;
            }
         }

         &-li-dropdown {
            margin-bottom: 0;

            &::after {
               content: url("/wp-content/themes/clinicoeurs/resources/img/fleche.svg");
               width: .375rem;
               rotate: 90deg;
               translate: 0 1px;
               display: inline-block;
               margin-left: .25rem;
               transform-origin: center;
               transition: transform 300ms;
            }

            &:hover {
               &::after {
                  transform: rotate(180deg);
               }
            }

            &:focus-within .menu-dropdown,
            &:hover .menu-dropdown {
               color: settings.$colorBlack;
            }
         }
      }

      .top-menu-container {
         display: flex;
         flex-direction: row;
         order: initial;
         padding: 0 1rem;

      }

      .container {
         max-width: 68.25rem;
         display: flex;
         width: 76vw;
         padding: 0;
         gap: 1.5rem;
         align-items: center;
         flex: none;
      }

      input[type="checkbox"] {
         display: none;
      }

      body {
         margin: 0 auto;
         background-color: settings.$colorWhite;
      }

      .burger,
      .burger-wrapper, [id="checkbox_toggle"] {
         display: none;
      }

      .logo-mobile {
         display: flex;
         margin-right: 5rem;
         align-items: center;
      }

      // On garde
      .background-nav {
         background-color: settings.$colorWhite;
         -webkit-box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.5);
         -moz-box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.5);
         box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.5);
      }

      header {
         background-color: settings.$colorWhite;
         top: 0;
         left: 0;
         padding: 0;
         margin: 0 auto;
         height: auto;
      }

      .nav {
         display: flex;
         align-items: center;
         width: 100%;
         //max-width: settings.$widthFullPage;
         //margin: 0 auto;
      }

      .sub-menu {
         visibility: hidden;
         overflow: hidden;
         height: 0;
         top: 5rem;
         padding: 0;
         -webkit-box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.7);
         -moz-box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.7);
         box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.7);
         z-index: 11;
         position: absolute;
         background-color: settings.$colorWhite;
         border-radius: .5rem;
         min-height: initial;
         max-height: initial;
         /* Nouvelles propriétés pour centrer le sous-menu */
         left: 50%; /* Déplace le sous-menu à 50% de la largeur de son parent */
         transform: translateX(-50%); /* Déplace le sous-menu de moitié de sa propre largeur vers la gauche */

         li {
            background-color: settings.$colorWhite;
            padding: .5rem 1rem .5rem 1rem;
            display: flex;
            flex-direction: column;
            transition: all 0.5s;
            line-height: 1.5em;

            a {
               font-size: 1rem;
               @include settings.MontserratBold;
               color: settings.$colorAccessibleGrey;
               background-color: settings.$colorWhite;
               padding-bottom: 0;
               display: flex;
               flex-direction: column;
            }
         }

         li:first-of-type {
            padding-top: .25rem;
         }

         li:last-of-type {
            padding-bottom: .25rem;
         }
      }

      .menu-li-dropdown:focus-within .sub-menu,
      .menu-li-dropdown:hover .sub-menu,
      .menu-dropdown:focus-within ~ .sub-menu,
      .menu-dropdown:hover ~ .sub-menu {
         visibility: initial;
         height: initial;
         overflow: initial;
         transition: visibility .3s, top .5s;
         padding: 1.5rem 1rem 1.5rem 1rem;
         top: 3.5rem;
      }

      // GROS MENU DESKTOP
      .menu-logo-container {
         display: flex;
         flex-wrap: nowrap;
      }

      .menu-logo-container .logo-desktop {
         display: flex;
         align-items: center;
         margin-right: 4rem;
         line-height: 0;
         font-size: 0;
      }

      .logo-mobile {
         display: none;
      }

      .menu-li:last-of-type a::after {
         display: none;
         min-height: 0;
         max-height: 0;
      }

      .menu-li:last-of-type:hover a,
      .menu-li:last-of-type a:focus,
      {
         background-color: settings.$colorWhite;
         color: settings.$purpleColor;
         border: 2px solid settings.$purpleColor;
      }

      .menu-li:last-of-type {
         padding: 0;
      }

      .menu-li:last-of-type a {
         padding: .75rem 1.25rem;
         border-radius: .5rem;
         background-color: settings.$purpleColor;
         border: 2px solid settings.$purpleColor;
         transition: all 300ms;
         color: white;
         @include MontserratBold;
      }

      .menu-li:last-of-type,
      .menu-li:last-of-type a,
      {
         margin-left: auto;
      }

      //MENU DU DESSUS
      .socialmedia {
         margin-right: auto;
         margin-bottom: 0;

         .socialmedia__network--fb::before,
         .socialmedia__network--ig::before,
         .socialmedia__network--tw::before,
         .socialmedia__network--tk::before {
            width: 1.25rem;
         }
      }

      .socialmedia__network {
         min-height: 1.25rem;
         max-height: 1.25rem;
      }

      .top-menu-container {
         background-color: transparent;
         max-width: settings.$widthFullPage;
         margin: 0 auto;
         align-items: center;
         justify-content: flex-end;
         padding: 0 1.25rem;
      }

      .menu-logo-container {
         max-width: settings.$widthFullPage;
         margin: 0 auto;
      }

      .langages__container .langages__item .langages__link {
         &::after {
            background: #0074be;
            max-height: 4px;
            bottom: -.25rem;
         }

         &:hover,
         &:focus {
            &::after {
               width: 100%;
            }

            color: settings.$colorBlack;
         }
      }

      .langages__container .langages__item {
         padding: 0;
      }

      .menu .top-contact {
         display: flex;
         gap: 2rem;
         font-size: .875rem;
         padding: .5rem 2rem .5rem 0;
         order: 1;
      }

      .langages {
         order: 1;
      }

      .top-menu__background {
         order: initial;
         width: 99vw;
         background-color: rgba(189, 189, 189, 0.25);
      }

      .menu-logo__background {
         width: 100vw;
         background-color: settings.$colorWhite;
      }

      .menu .menu__top-contact {
         padding: 0;
      }

      .top-menu-container .menu__top-contact a {
         font-size: .875rem;
         color: #000000b5;
      }
   }
}

@media (min-width: 1245px) {
   [lang="de"],
   [lang="nl"] {
      header {
         position: sticky;
      }

      .menu {
         width: 100%;
         position: initial;
         height: initial;
         opacity: 1;
         top: 0;
         left: 0;
         margin-top: 0;
         padding: 0;
         transition: all 0.5s;
         overflow: visible;
         background-color: initial;
         max-width: initial;
         gap: 0;
         display: flex;
         flex-direction: column;
         justify-content: flex-end;
         align-items: flex-start;
         transition: all 0.1ms;

         [aria-current=page] {
            &::after {
               width: 100%;
            }
         }

         &-li {
            margin-bottom: 0;
         }

         li a {
            position: relative;

            &::after {
               transition: all 300ms ease-out;
               position: absolute;
               bottom: -.125rem;
               left: 0;
               right: 0;
               margin: auto;
               width: 0;
               content: "";
               color: transparent;
               background: settings.$purpleColor;
               min-height: 2px;
               max-height: 2px;
            }
         }

         .menu-li:hover a::after,
         .menu-li-dropdown li:hover:first-of-type a::after,
         .menu-li:focus-within a::after,
         .menu-li-dropdown li:focus-within:first-of-type a::after,
         {
            width: 100%;
         }

         .activeMenu a::after {
            transition: all 300ms ease-out;
            position: absolute;
            bottom: -.25rem;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            content: "."  / "";
            color: transparent;
            background: white;
            min-height: 2px;
            max-height: 2px;
         }

         li {
            padding: 1.5rem 0;
            color: white;
         }

         li:first-of-type:hover,
         li:first-of-type:focus-within,
         .sub-menu li:focus-within,
         .sub-menu li:hover,
         .top-contact li:focus-within,
         .top-contact li:hover {
            a {
               color: settings.$colorBlack;

               &::after {
                  width: 100%;
               }
            }
         }

         .menu-li:hover a {
            color: settings.$colorBlack;
         }

         > li {
            position: relative;
         }

         a {
            line-height: 1.25rem;
            display: block;
            font-size: .875rem;
            color: settings.$colorAccessibleGrey;
            transition: color .2s;
            overflow: visible;
         }

         &-dropdown {
            font-size: .875rem;
            line-height: 1.55em;
            cursor: pointer;

            &:hover {
               color: settings.$colorBlack;
               cursor: pointer;
            }
         }

         &-li-dropdown {
            margin-bottom: 0;

            &::after {
               content: url("/wp-content/themes/clinicoeurs/resources/img/fleche.svg");
               width: .375rem;
               rotate: 90deg;
               translate: 0 1px;
               display: inline-block;
               margin-left: .25rem;
               transform-origin: center;
               transition: transform 300ms;
            }

            &:hover {
               &::after {
                  transform: rotate(180deg);
               }
            }

            &:focus-within .menu-dropdown,
            &:hover .menu-dropdown {
               color: settings.$colorBlack;
            }
         }
      }

      .top-menu-container {
         display: flex;
         flex-direction: row;
         order: initial;
         padding: 0 1rem;

      }

      .container {
         max-width: 68.25rem;
         display: flex;
         width: 80vw;
         padding: 0;
         gap: 1.5rem;
         align-items: center;
         flex: none;
      }

      input[type="checkbox"] {
         display: none;
      }

      body {
         margin: 0 auto;
         background-color: settings.$colorWhite;
      }

      .burger,
      .burger-wrapper, [id="checkbox_toggle"] {
         display: none;
      }

      .logo-mobile {
         display: flex;
         margin-right: 5rem;
         align-items: center;
      }

      // On garde
      .background-nav {
         background-color: settings.$colorWhite;
         -webkit-box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.5);
         -moz-box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.5);
         box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.5);
      }

      header {
         background-color: settings.$colorWhite;
         top: 0;
         left: 0;
         padding: 0;
         margin: 0 auto;
         height: auto;
      }

      .nav {
         display: flex;
         align-items: center;
         width: 100%;
         //max-width: settings.$widthFullPage;
         //margin: 0 auto;
      }

      .sub-menu {
         visibility: hidden;
         overflow: hidden;
         height: 0;
         top: 5rem;
         padding: 0;
         -webkit-box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.7);
         -moz-box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.7);
         box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.7);
         z-index: 11;
         position: absolute;
         background-color: settings.$colorWhite;
         border-radius: .5rem;
         min-height: initial;
         max-height: initial;
         /* Nouvelles propriétés pour centrer le sous-menu */
         left: 50%; /* Déplace le sous-menu à 50% de la largeur de son parent */
         transform: translateX(-50%); /* Déplace le sous-menu de moitié de sa propre largeur vers la gauche */

         li {
            background-color: settings.$colorWhite;
            padding: .5rem 1rem .5rem 1rem;
            display: flex;
            flex-direction: column;
            transition: all 0.5s;
            line-height: 1.5em;

            a {
               font-size: 1rem;
               @include settings.MontserratBold;
               color: settings.$colorAccessibleGrey;
               background-color: settings.$colorWhite;
               padding-bottom: 0;
               display: flex;
               flex-direction: column;
            }
         }

         li:first-of-type {
            padding-top: .25rem;
         }

         li:last-of-type {
            padding-bottom: .25rem;
         }
      }

      .menu-li-dropdown:focus-within .sub-menu,
      .menu-li-dropdown:hover .sub-menu,
      .menu-dropdown:focus-within ~ .sub-menu,
      .menu-dropdown:hover ~ .sub-menu {
         visibility: initial;
         height: initial;
         overflow: initial;
         transition: visibility .3s, top .5s;
         padding: 1.5rem 1rem 1.5rem 1rem;
         top: 3.5rem;
      }

      // GROS MENU DESKTOP
      .menu-logo-container {
         display: flex;
         flex-wrap: nowrap;
      }

      .menu-logo-container .logo-desktop {
         display: flex;
         align-items: center;
         margin-right: 4rem;
         line-height: 0;
         font-size: 0;
      }

      .logo-mobile {
         display: none;
      }

      .menu-li:last-of-type a::after {
         display: none;
         min-height: 0;
         max-height: 0;
      }

      .menu-li:last-of-type:hover a,
      .menu-li:last-of-type a:focus,
      {
         background-color: settings.$colorWhite;
         color: settings.$purpleColor;
         border: 2px solid settings.$purpleColor;
      }

      .menu-li:last-of-type {
         padding: 0;
      }

      .menu-li:last-of-type a {
         padding: .75rem 1.25rem;
         border-radius: .5rem;
         background-color: settings.$purpleColor;
         border: 2px solid settings.$purpleColor;
         transition: all 300ms;
         color: white;
         @include MontserratBold;
      }

      .menu-li:last-of-type,
      .menu-li:last-of-type a,
      {
         margin-left: auto;
      }

      //MENU DU DESSUS
      .socialmedia {
         margin-right: auto;
         margin-bottom: 0;

         .socialmedia__network--fb::before,
         .socialmedia__network--ig::before,
         .socialmedia__network--tw::before,
         .socialmedia__network--tk::before {
            width: 1.25rem;
         }
      }

      .socialmedia__network {
         min-height: 1.25rem;
         max-height: 1.25rem;
      }

      .top-menu-container {
         background-color: transparent;
         max-width: settings.$widthFullPage;
         margin: 0 auto;
         align-items: center;
         justify-content: flex-end;
         padding: 0 1.25rem;
      }

      .menu-logo-container {
         max-width: settings.$widthFullPage;
         margin: 0 auto;
      }

      .langages__container .langages__item .langages__link {
         &::after {
            background: #0074be;
            max-height: 4px;
            bottom: -.25rem;
         }

         &:hover,
         &:focus {
            &::after {
               width: 100%;
            }

            color: settings.$colorBlack;
         }
      }

      .langages__container .langages__item {
         padding: 0;
      }

      .menu .top-contact {
         display: flex;
         gap: 2rem;
         font-size: .875rem;
         padding: .5rem 2rem .5rem 0;
         order: 1;
      }

      .langages {
         order: 1;
      }

      .top-menu__background {
         order: initial;
         width: 99vw;
         background-color: rgba(189, 189, 189, 0.25);
      }

      .menu-logo__background {
         width: 100vw;
         background-color: settings.$colorWhite;
      }

      .menu .menu__top-contact {
         padding: 0;
      }

      .top-menu-container .menu__top-contact a {
         font-size: .875rem;
         color: #000000b5;
      }
   }
}

@media (min-width: 1100px) {
   [lang="de"],
   [lang="fr"],
   [lang="en"],
   [lang="nl"] {
      .container {
         max-width: 68.25rem;
         width: 78vw;
      }
   }
}

@media (min-width: 1200px) {
   [lang="de"],
   [lang="fr"],
   [lang="en"],
   [lang="nl"] {
      .container {
         max-width: 66.25rem;
         width: 80vw;
      }
   }
}

@media (min-width: 1300px) {
   [lang="de"],
   [lang="fr"],
   [lang="en"],
   [lang="nl"] {
      .container {
         max-width: 67.25rem;
         width: 80vw;
      }
   }
}

@media (min-width: settings.$widthFullPage) {
   [lang="de"],
   [lang="fr"],
   [lang="en"],
   [lang="nl"] {
      body {
         margin: 0 auto;
         background-color: settings.$colorWhite;
      }

      .container {
         width: 83.5vw;
      }

      .top-menu-container {
         padding: 0;
      }
   }
}