@charset "UTF-8";
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

/* FIN DU RESET DE MEYER */
.hidden {
  transition: all 0.1s ease-in-out;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

a.evitement {
  display: inline-block;
  color: #555;
  background: #fff;
  padding: 0.5em;
  position: absolute;
  left: -99999px;
  z-index: 100;
}

a.evitement:focus {
  left: 0;
}

header {
  width: 100%;
  position: fixed;
  z-index: 3;
  background-color: white;
  height: 4rem;
  padding: 1rem 1.5rem;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.16);
}

/* LOGO */
.logo-mobile {
  font-size: 2rem;
}
.logo-mobile img {
  margin: 2px 0;
  width: 8rem;
  height: auto;
  z-index: 5;
}

/* BURGER MENU */
label[id=burger] {
  z-index: 15;
}

.burger-wrapper {
  font-size: 0;
  padding: 12px 8px 8px;
  position: absolute;
  top: 0.3rem;
  right: 1.25rem;
}

.bar1,
.bar2,
.bar3 {
  opacity: 1;
  transition: all 500ms;
  transform: translate(0, 0);
  display: block;
  width: 24px;
  height: 2px;
  background-color: black;
  margin: 6px 0;
  transform-origin: center;
  border-radius: 1rem;
}

[id=checkbox_toggle]:checked ~ .menu {
  z-index: 4;
  top: 0;
  right: 0;
  opacity: 1;
}

[id=checkbox_toggle] {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  opacity: 0;
}

[id=checkbox_toggle]:checked ~ label > .bar1 {
  transition: all 500ms;
  transform: translate(0, 9px) rotate(45deg);
}

[id=checkbox_toggle]:checked ~ label > .bar2 {
  transition: all 200ms;
  transform: translateX(20px);
  opacity: 0;
}

[id=checkbox_toggle]:checked ~ label > .bar3 {
  transition: all 500ms;
  transform: translate(0, -7px) rotate(-45deg);
}

.menu {
  position: fixed;
  z-index: 4;
  top: 0;
  right: -100rem;
  background-color: #ffffff;
  display: flex;
  gap: 0.5rem;
  font-size: 1rem;
  transition: all 400ms 100ms;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 3.9rem;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  overflow-y: scroll;
}
.menu-li {
  margin-bottom: 0.5rem;
}
.menu a {
  font-size: 1.5rem;
  line-height: 1.66em;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  color: #51565D;
}
.menu [aria-current=page] {
  color: #1C1C1C;
  border-bottom: 2px solid #9359c6;
}

.menu-li-dropdown {
  position: relative;
  margin-bottom: 0.5rem;
}

.sub-menu {
  background-color: #ffffff;
  max-height: 0;
  min-height: 0;
  opacity: 1;
  overflow: hidden;
  transition: translate 0.5s, max-height 0.5s;
}

[id=check-box-services] {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 2rem;
}

[id=check-box-services]:checked ~ .sub-menu {
  max-height: 10rem;
  min-height: 0;
  translate: 1rem;
}

.menu-dropdown {
  font-size: 1.5rem;
  line-height: 1.66em;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  color: #51565D;
}

.container-top-menu li a {
  text-transform: uppercase;
}
.container-top-menu .menu__top-contact a {
  text-transform: initial;
}

.top-menu__background {
  background-color: rgba(189, 189, 189, 0.25);
  width: 100%;
  order: 1;
}

.top-menu-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 1.25rem 14rem;
}
.top-menu-container .top-contact {
  font-size: 1.25rem;
  padding-bottom: 1rem;
  line-height: initial;
}

.container {
  padding: 1rem 1.25rem;
}

.langages__container {
  display: flex;
  gap: 1rem;
}
.langages__item a {
  font-size: 1.25rem;
  text-transform: uppercase;
}
.langages__item-current .langages__link {
  color: #0074be;
  border-bottom: 4px solid #0074be;
}

[lang=fr] .socialmedia,
[lang=de] .socialmedia,
[lang=nl] .socialmedia,
[lang=en] .socialmedia {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}
[lang=fr] .socialmedia .socialmedia__network--fb, [lang=fr] .socialmedia .socialmedia__network--tw, [lang=fr] .socialmedia .socialmedia__network--ig, [lang=fr] .socialmedia .socialmedia__network--tk,
[lang=de] .socialmedia .socialmedia__network--fb,
[lang=de] .socialmedia .socialmedia__network--tw,
[lang=de] .socialmedia .socialmedia__network--ig,
[lang=de] .socialmedia .socialmedia__network--tk,
[lang=nl] .socialmedia .socialmedia__network--fb,
[lang=nl] .socialmedia .socialmedia__network--tw,
[lang=nl] .socialmedia .socialmedia__network--ig,
[lang=nl] .socialmedia .socialmedia__network--tk,
[lang=en] .socialmedia .socialmedia__network--fb,
[lang=en] .socialmedia .socialmedia__network--tw,
[lang=en] .socialmedia .socialmedia__network--ig,
[lang=en] .socialmedia .socialmedia__network--tk {
  transform-origin: center;
}
[lang=fr] .socialmedia .socialmedia__network--fb:hover::before, [lang=fr] .socialmedia .socialmedia__network--fb:focus::before, [lang=fr] .socialmedia .socialmedia__network--tw:hover::before, [lang=fr] .socialmedia .socialmedia__network--tw:focus::before, [lang=fr] .socialmedia .socialmedia__network--ig:hover::before, [lang=fr] .socialmedia .socialmedia__network--ig:focus::before, [lang=fr] .socialmedia .socialmedia__network--tk:hover::before, [lang=fr] .socialmedia .socialmedia__network--tk:focus::before,
[lang=de] .socialmedia .socialmedia__network--fb:hover::before,
[lang=de] .socialmedia .socialmedia__network--fb:focus::before,
[lang=de] .socialmedia .socialmedia__network--tw:hover::before,
[lang=de] .socialmedia .socialmedia__network--tw:focus::before,
[lang=de] .socialmedia .socialmedia__network--ig:hover::before,
[lang=de] .socialmedia .socialmedia__network--ig:focus::before,
[lang=de] .socialmedia .socialmedia__network--tk:hover::before,
[lang=de] .socialmedia .socialmedia__network--tk:focus::before,
[lang=nl] .socialmedia .socialmedia__network--fb:hover::before,
[lang=nl] .socialmedia .socialmedia__network--fb:focus::before,
[lang=nl] .socialmedia .socialmedia__network--tw:hover::before,
[lang=nl] .socialmedia .socialmedia__network--tw:focus::before,
[lang=nl] .socialmedia .socialmedia__network--ig:hover::before,
[lang=nl] .socialmedia .socialmedia__network--ig:focus::before,
[lang=nl] .socialmedia .socialmedia__network--tk:hover::before,
[lang=nl] .socialmedia .socialmedia__network--tk:focus::before,
[lang=en] .socialmedia .socialmedia__network--fb:hover::before,
[lang=en] .socialmedia .socialmedia__network--fb:focus::before,
[lang=en] .socialmedia .socialmedia__network--tw:hover::before,
[lang=en] .socialmedia .socialmedia__network--tw:focus::before,
[lang=en] .socialmedia .socialmedia__network--ig:hover::before,
[lang=en] .socialmedia .socialmedia__network--ig:focus::before,
[lang=en] .socialmedia .socialmedia__network--tk:hover::before,
[lang=en] .socialmedia .socialmedia__network--tk:focus::before {
  transform: scale(1.2);
}
[lang=fr] .socialmedia .socialmedia__network--fb,
[lang=de] .socialmedia .socialmedia__network--fb,
[lang=nl] .socialmedia .socialmedia__network--fb,
[lang=en] .socialmedia .socialmedia__network--fb {
  font-size: 0;
}
[lang=fr] .socialmedia .socialmedia__network--fb::before,
[lang=de] .socialmedia .socialmedia__network--fb::before,
[lang=nl] .socialmedia .socialmedia__network--fb::before,
[lang=en] .socialmedia .socialmedia__network--fb::before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/social-media/facebook-black.svg");
  display: inline-block;
  width: 2.25rem;
  height: auto;
  transition: all 300ms;
}
[lang=fr] .socialmedia .socialmedia__network--tw,
[lang=de] .socialmedia .socialmedia__network--tw,
[lang=nl] .socialmedia .socialmedia__network--tw,
[lang=en] .socialmedia .socialmedia__network--tw {
  font-size: 0;
}
[lang=fr] .socialmedia .socialmedia__network--tw::before,
[lang=de] .socialmedia .socialmedia__network--tw::before,
[lang=nl] .socialmedia .socialmedia__network--tw::before,
[lang=en] .socialmedia .socialmedia__network--tw::before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/social-media/twitter-black.svg");
  display: inline-block;
  width: 2.25rem;
  height: auto;
  transition: all 300ms;
}
[lang=fr] .socialmedia .socialmedia__network--ig,
[lang=de] .socialmedia .socialmedia__network--ig,
[lang=nl] .socialmedia .socialmedia__network--ig,
[lang=en] .socialmedia .socialmedia__network--ig {
  font-size: 0;
}
[lang=fr] .socialmedia .socialmedia__network--ig::before,
[lang=de] .socialmedia .socialmedia__network--ig::before,
[lang=nl] .socialmedia .socialmedia__network--ig::before,
[lang=en] .socialmedia .socialmedia__network--ig::before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/social-media/instagram-black.svg");
  display: inline-block;
  width: 2.25rem;
  height: auto;
  transition: all 300ms;
}
[lang=fr] .socialmedia .socialmedia__network--tk,
[lang=de] .socialmedia .socialmedia__network--tk,
[lang=nl] .socialmedia .socialmedia__network--tk,
[lang=en] .socialmedia .socialmedia__network--tk {
  font-size: 0;
}
[lang=fr] .socialmedia .socialmedia__network--tk::before,
[lang=de] .socialmedia .socialmedia__network--tk::before,
[lang=nl] .socialmedia .socialmedia__network--tk::before,
[lang=en] .socialmedia .socialmedia__network--tk::before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/social-media/tiktok-black.svg");
  display: inline-block;
  width: 2.25rem;
  height: auto;
  transition: all 300ms;
}

.logo-desktop {
  display: none;
}

.top-menu-container .menu__top-contact a {
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.7098039216);
}

@media (min-width: 995px) {
  [lang=fr] header,
  [lang=en] header {
    position: sticky;
  }
  [lang=fr] .menu,
  [lang=en] .menu {
    width: 100%;
    position: initial;
    height: initial;
    opacity: 1;
    top: 0;
    left: 0;
    margin-top: 0;
    padding: 0;
    transition: all 0.5s;
    overflow: visible;
    background-color: initial;
    max-width: initial;
    gap: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    transition: all 0.1ms;
  }
  [lang=fr] .menu [aria-current=page]::after,
  [lang=en] .menu [aria-current=page]::after {
    width: 100%;
  }
  [lang=fr] .menu-li,
  [lang=en] .menu-li {
    margin-bottom: 0;
  }
  [lang=fr] .menu li a,
  [lang=en] .menu li a {
    position: relative;
  }
  [lang=fr] .menu li a::after,
  [lang=en] .menu li a::after {
    transition: all 300ms ease-out;
    position: absolute;
    bottom: -0.125rem;
    left: 0;
    right: 0;
    margin: auto;
    width: 0;
    content: "";
    color: transparent;
    background: #9359c6;
    min-height: 2px;
    max-height: 2px;
  }
  [lang=fr] .menu .menu-li:hover a::after,
  [lang=fr] .menu .menu-li-dropdown li:hover:first-of-type a::after,
  [lang=fr] .menu .menu-li:focus-within a::after,
  [lang=fr] .menu .menu-li-dropdown li:focus-within:first-of-type a::after,
  [lang=en] .menu .menu-li:hover a::after,
  [lang=en] .menu .menu-li-dropdown li:hover:first-of-type a::after,
  [lang=en] .menu .menu-li:focus-within a::after,
  [lang=en] .menu .menu-li-dropdown li:focus-within:first-of-type a::after {
    width: 100%;
  }
  [lang=fr] .menu .activeMenu a::after,
  [lang=en] .menu .activeMenu a::after {
    transition: all 300ms ease-out;
    position: absolute;
    bottom: -0.25rem;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    content: "."/"";
    color: transparent;
    background: white;
    min-height: 2px;
    max-height: 2px;
  }
  [lang=fr] .menu li,
  [lang=en] .menu li {
    padding: 1.5rem 0;
    color: white;
  }
  [lang=fr] .menu li:first-of-type:hover a,
  [lang=fr] .menu li:first-of-type:focus-within a,
  [lang=fr] .menu .sub-menu li:focus-within a,
  [lang=fr] .menu .sub-menu li:hover a,
  [lang=fr] .menu .top-contact li:focus-within a,
  [lang=fr] .menu .top-contact li:hover a,
  [lang=en] .menu li:first-of-type:hover a,
  [lang=en] .menu li:first-of-type:focus-within a,
  [lang=en] .menu .sub-menu li:focus-within a,
  [lang=en] .menu .sub-menu li:hover a,
  [lang=en] .menu .top-contact li:focus-within a,
  [lang=en] .menu .top-contact li:hover a {
    color: #1C1C1C;
  }
  [lang=fr] .menu li:first-of-type:hover a::after,
  [lang=fr] .menu li:first-of-type:focus-within a::after,
  [lang=fr] .menu .sub-menu li:focus-within a::after,
  [lang=fr] .menu .sub-menu li:hover a::after,
  [lang=fr] .menu .top-contact li:focus-within a::after,
  [lang=fr] .menu .top-contact li:hover a::after,
  [lang=en] .menu li:first-of-type:hover a::after,
  [lang=en] .menu li:first-of-type:focus-within a::after,
  [lang=en] .menu .sub-menu li:focus-within a::after,
  [lang=en] .menu .sub-menu li:hover a::after,
  [lang=en] .menu .top-contact li:focus-within a::after,
  [lang=en] .menu .top-contact li:hover a::after {
    width: 100%;
  }
  [lang=fr] .menu .menu-li:hover a,
  [lang=en] .menu .menu-li:hover a {
    color: #1C1C1C;
  }
  [lang=fr] .menu > li,
  [lang=en] .menu > li {
    position: relative;
  }
  [lang=fr] .menu a,
  [lang=en] .menu a {
    line-height: 1.25rem;
    display: block;
    font-size: 0.875rem;
    color: #51565D;
    transition: color 0.2s;
    overflow: visible;
  }
  [lang=fr] .menu-dropdown,
  [lang=en] .menu-dropdown {
    font-size: 0.875rem;
    line-height: 1.55em;
    cursor: pointer;
  }
  [lang=fr] .menu-dropdown:hover,
  [lang=en] .menu-dropdown:hover {
    color: #1C1C1C;
    cursor: pointer;
  }
  [lang=fr] .menu-li-dropdown,
  [lang=en] .menu-li-dropdown {
    margin-bottom: 0;
  }
  [lang=fr] .menu-li-dropdown::after,
  [lang=en] .menu-li-dropdown::after {
    content: url("/wp-content/themes/clinicoeurs/resources/img/fleche.svg");
    width: 0.375rem;
    rotate: 90deg;
    translate: 0 1px;
    display: inline-block;
    margin-left: 0.25rem;
    transform-origin: center;
    transition: transform 300ms;
  }
  [lang=fr] .menu-li-dropdown:hover::after,
  [lang=en] .menu-li-dropdown:hover::after {
    transform: rotate(180deg);
  }
  [lang=fr] .menu-li-dropdown:focus-within .menu-dropdown, [lang=fr] .menu-li-dropdown:hover .menu-dropdown,
  [lang=en] .menu-li-dropdown:focus-within .menu-dropdown,
  [lang=en] .menu-li-dropdown:hover .menu-dropdown {
    color: #1C1C1C;
  }
  [lang=fr] .top-menu-container,
  [lang=en] .top-menu-container {
    display: flex;
    flex-direction: row;
    order: initial;
    padding: 0 1rem;
  }
  [lang=fr] .container,
  [lang=en] .container {
    max-width: 68.25rem;
    display: flex;
    width: 76vw;
    padding: 0;
    gap: 1.5rem;
    align-items: center;
    flex: none;
  }
  [lang=fr] input[type=checkbox],
  [lang=en] input[type=checkbox] {
    display: none;
  }
  [lang=fr] body,
  [lang=en] body {
    margin: 0 auto;
    background-color: #ffffff;
  }
  [lang=fr] .burger,
  [lang=fr] .burger-wrapper, [lang=fr] [id=checkbox_toggle],
  [lang=en] .burger,
  [lang=en] .burger-wrapper,
  [lang=en] [id=checkbox_toggle] {
    display: none;
  }
  [lang=fr] .logo-mobile,
  [lang=en] .logo-mobile {
    display: flex;
    margin-right: 5rem;
    align-items: center;
  }
  [lang=fr] .background-nav,
  [lang=en] .background-nav {
    background-color: #ffffff;
    -webkit-box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.5);
    -moz-box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.5);
    box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.5);
  }
  [lang=fr] header,
  [lang=en] header {
    background-color: #ffffff;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0 auto;
    height: auto;
  }
  [lang=fr] .nav,
  [lang=en] .nav {
    display: flex;
    align-items: center;
    width: 100%;
  }
  [lang=fr] .sub-menu,
  [lang=en] .sub-menu {
    visibility: hidden;
    overflow: hidden;
    height: 0;
    top: 5rem;
    padding: 0;
    -webkit-box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.7);
    -moz-box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.7);
    box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.7);
    z-index: 11;
    position: absolute;
    background-color: #ffffff;
    border-radius: 0.5rem;
    min-height: initial;
    max-height: initial;
    /* Nouvelles propriétés pour centrer le sous-menu */
    left: 50%; /* Déplace le sous-menu à 50% de la largeur de son parent */
    transform: translateX(-50%); /* Déplace le sous-menu de moitié de sa propre largeur vers la gauche */
  }
  [lang=fr] .sub-menu li,
  [lang=en] .sub-menu li {
    background-color: #ffffff;
    padding: 0.5rem 1rem 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    transition: all 0.5s;
    line-height: 1.5em;
  }
  [lang=fr] .sub-menu li a,
  [lang=en] .sub-menu li a {
    font-size: 1rem;
    font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
    font-weight: 700;
    color: #51565D;
    background-color: #ffffff;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
  }
  [lang=fr] .sub-menu li:first-of-type,
  [lang=en] .sub-menu li:first-of-type {
    padding-top: 0.25rem;
  }
  [lang=fr] .sub-menu li:last-of-type,
  [lang=en] .sub-menu li:last-of-type {
    padding-bottom: 0.25rem;
  }
  [lang=fr] .menu-li-dropdown:focus-within .sub-menu,
  [lang=fr] .menu-li-dropdown:hover .sub-menu,
  [lang=fr] .menu-dropdown:focus-within ~ .sub-menu,
  [lang=fr] .menu-dropdown:hover ~ .sub-menu,
  [lang=en] .menu-li-dropdown:focus-within .sub-menu,
  [lang=en] .menu-li-dropdown:hover .sub-menu,
  [lang=en] .menu-dropdown:focus-within ~ .sub-menu,
  [lang=en] .menu-dropdown:hover ~ .sub-menu {
    visibility: initial;
    height: initial;
    overflow: initial;
    transition: visibility 0.3s, top 0.5s;
    padding: 1.5rem 1rem 1.5rem 1rem;
    top: 3.5rem;
  }
  [lang=fr] .menu-logo-container,
  [lang=en] .menu-logo-container {
    display: flex;
    flex-wrap: nowrap;
  }
  [lang=fr] .menu-logo-container .logo-desktop,
  [lang=en] .menu-logo-container .logo-desktop {
    display: flex;
    align-items: center;
    margin-right: 4rem;
    line-height: 0;
    font-size: 0;
  }
  [lang=fr] .logo-mobile,
  [lang=en] .logo-mobile {
    display: none;
  }
  [lang=fr] .menu-li:last-of-type a::after,
  [lang=en] .menu-li:last-of-type a::after {
    display: none;
    min-height: 0;
    max-height: 0;
  }
  [lang=fr] .menu-li:last-of-type:hover a,
  [lang=fr] .menu-li:last-of-type a:focus,
  [lang=en] .menu-li:last-of-type:hover a,
  [lang=en] .menu-li:last-of-type a:focus {
    background-color: #ffffff;
    color: #9359c6;
    border: 2px solid #9359c6;
  }
  [lang=fr] .menu-li:last-of-type,
  [lang=en] .menu-li:last-of-type {
    padding: 0;
  }
  [lang=fr] .menu-li:last-of-type a,
  [lang=en] .menu-li:last-of-type a {
    padding: 0.75rem 1.25rem;
    border-radius: 0.5rem;
    background-color: #9359c6;
    border: 2px solid #9359c6;
    transition: all 300ms;
    color: white;
    font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
    font-weight: 700;
  }
  [lang=fr] .menu-li:last-of-type,
  [lang=fr] .menu-li:last-of-type a,
  [lang=en] .menu-li:last-of-type,
  [lang=en] .menu-li:last-of-type a {
    margin-left: auto;
  }
  [lang=fr] .socialmedia,
  [lang=en] .socialmedia {
    margin-right: auto;
    margin-bottom: 0;
  }
  [lang=fr] .socialmedia .socialmedia__network--fb::before,
  [lang=fr] .socialmedia .socialmedia__network--ig::before,
  [lang=fr] .socialmedia .socialmedia__network--tw::before,
  [lang=fr] .socialmedia .socialmedia__network--tk::before,
  [lang=en] .socialmedia .socialmedia__network--fb::before,
  [lang=en] .socialmedia .socialmedia__network--ig::before,
  [lang=en] .socialmedia .socialmedia__network--tw::before,
  [lang=en] .socialmedia .socialmedia__network--tk::before {
    width: 1.25rem;
  }
  [lang=fr] .socialmedia__network,
  [lang=en] .socialmedia__network {
    min-height: 1.25rem;
    max-height: 1.25rem;
  }
  [lang=fr] .top-menu-container,
  [lang=en] .top-menu-container {
    background-color: transparent;
    max-width: 80rem;
    margin: 0 auto;
    align-items: center;
    justify-content: flex-end;
    padding: 0 1.25rem;
  }
  [lang=fr] .menu-logo-container,
  [lang=en] .menu-logo-container {
    max-width: 80rem;
    margin: 0 auto;
  }
  [lang=fr] .langages__container .langages__item .langages__link::after,
  [lang=en] .langages__container .langages__item .langages__link::after {
    background: #0074be;
    max-height: 4px;
    bottom: -0.25rem;
  }
  [lang=fr] .langages__container .langages__item .langages__link:hover, [lang=fr] .langages__container .langages__item .langages__link:focus,
  [lang=en] .langages__container .langages__item .langages__link:hover,
  [lang=en] .langages__container .langages__item .langages__link:focus {
    color: #1C1C1C;
  }
  [lang=fr] .langages__container .langages__item .langages__link:hover::after, [lang=fr] .langages__container .langages__item .langages__link:focus::after,
  [lang=en] .langages__container .langages__item .langages__link:hover::after,
  [lang=en] .langages__container .langages__item .langages__link:focus::after {
    width: 100%;
  }
  [lang=fr] .langages__container .langages__item,
  [lang=en] .langages__container .langages__item {
    padding: 0;
  }
  [lang=fr] .menu .top-contact,
  [lang=en] .menu .top-contact {
    display: flex;
    gap: 2rem;
    font-size: 0.875rem;
    padding: 0.5rem 2rem 0.5rem 0;
    order: 1;
  }
  [lang=fr] .langages,
  [lang=en] .langages {
    order: 1;
  }
  [lang=fr] .top-menu__background,
  [lang=en] .top-menu__background {
    order: initial;
    width: 99vw;
    background-color: rgba(189, 189, 189, 0.25);
  }
  [lang=fr] .menu-logo__background,
  [lang=en] .menu-logo__background {
    width: 100vw;
    background-color: #ffffff;
  }
  [lang=fr] .menu .menu__top-contact,
  [lang=en] .menu .menu__top-contact {
    padding: 0;
  }
  [lang=fr] .top-menu-container .menu__top-contact a,
  [lang=en] .top-menu-container .menu__top-contact a {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.7098039216);
  }
}
@media (min-width: 1245px) {
  [lang=de] header,
  [lang=nl] header {
    position: sticky;
  }
  [lang=de] .menu,
  [lang=nl] .menu {
    width: 100%;
    position: initial;
    height: initial;
    opacity: 1;
    top: 0;
    left: 0;
    margin-top: 0;
    padding: 0;
    transition: all 0.5s;
    overflow: visible;
    background-color: initial;
    max-width: initial;
    gap: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    transition: all 0.1ms;
  }
  [lang=de] .menu [aria-current=page]::after,
  [lang=nl] .menu [aria-current=page]::after {
    width: 100%;
  }
  [lang=de] .menu-li,
  [lang=nl] .menu-li {
    margin-bottom: 0;
  }
  [lang=de] .menu li a,
  [lang=nl] .menu li a {
    position: relative;
  }
  [lang=de] .menu li a::after,
  [lang=nl] .menu li a::after {
    transition: all 300ms ease-out;
    position: absolute;
    bottom: -0.125rem;
    left: 0;
    right: 0;
    margin: auto;
    width: 0;
    content: "";
    color: transparent;
    background: #9359c6;
    min-height: 2px;
    max-height: 2px;
  }
  [lang=de] .menu .menu-li:hover a::after,
  [lang=de] .menu .menu-li-dropdown li:hover:first-of-type a::after,
  [lang=de] .menu .menu-li:focus-within a::after,
  [lang=de] .menu .menu-li-dropdown li:focus-within:first-of-type a::after,
  [lang=nl] .menu .menu-li:hover a::after,
  [lang=nl] .menu .menu-li-dropdown li:hover:first-of-type a::after,
  [lang=nl] .menu .menu-li:focus-within a::after,
  [lang=nl] .menu .menu-li-dropdown li:focus-within:first-of-type a::after {
    width: 100%;
  }
  [lang=de] .menu .activeMenu a::after,
  [lang=nl] .menu .activeMenu a::after {
    transition: all 300ms ease-out;
    position: absolute;
    bottom: -0.25rem;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    content: "."/"";
    color: transparent;
    background: white;
    min-height: 2px;
    max-height: 2px;
  }
  [lang=de] .menu li,
  [lang=nl] .menu li {
    padding: 1.5rem 0;
    color: white;
  }
  [lang=de] .menu li:first-of-type:hover a,
  [lang=de] .menu li:first-of-type:focus-within a,
  [lang=de] .menu .sub-menu li:focus-within a,
  [lang=de] .menu .sub-menu li:hover a,
  [lang=de] .menu .top-contact li:focus-within a,
  [lang=de] .menu .top-contact li:hover a,
  [lang=nl] .menu li:first-of-type:hover a,
  [lang=nl] .menu li:first-of-type:focus-within a,
  [lang=nl] .menu .sub-menu li:focus-within a,
  [lang=nl] .menu .sub-menu li:hover a,
  [lang=nl] .menu .top-contact li:focus-within a,
  [lang=nl] .menu .top-contact li:hover a {
    color: #1C1C1C;
  }
  [lang=de] .menu li:first-of-type:hover a::after,
  [lang=de] .menu li:first-of-type:focus-within a::after,
  [lang=de] .menu .sub-menu li:focus-within a::after,
  [lang=de] .menu .sub-menu li:hover a::after,
  [lang=de] .menu .top-contact li:focus-within a::after,
  [lang=de] .menu .top-contact li:hover a::after,
  [lang=nl] .menu li:first-of-type:hover a::after,
  [lang=nl] .menu li:first-of-type:focus-within a::after,
  [lang=nl] .menu .sub-menu li:focus-within a::after,
  [lang=nl] .menu .sub-menu li:hover a::after,
  [lang=nl] .menu .top-contact li:focus-within a::after,
  [lang=nl] .menu .top-contact li:hover a::after {
    width: 100%;
  }
  [lang=de] .menu .menu-li:hover a,
  [lang=nl] .menu .menu-li:hover a {
    color: #1C1C1C;
  }
  [lang=de] .menu > li,
  [lang=nl] .menu > li {
    position: relative;
  }
  [lang=de] .menu a,
  [lang=nl] .menu a {
    line-height: 1.25rem;
    display: block;
    font-size: 0.875rem;
    color: #51565D;
    transition: color 0.2s;
    overflow: visible;
  }
  [lang=de] .menu-dropdown,
  [lang=nl] .menu-dropdown {
    font-size: 0.875rem;
    line-height: 1.55em;
    cursor: pointer;
  }
  [lang=de] .menu-dropdown:hover,
  [lang=nl] .menu-dropdown:hover {
    color: #1C1C1C;
    cursor: pointer;
  }
  [lang=de] .menu-li-dropdown,
  [lang=nl] .menu-li-dropdown {
    margin-bottom: 0;
  }
  [lang=de] .menu-li-dropdown::after,
  [lang=nl] .menu-li-dropdown::after {
    content: url("/wp-content/themes/clinicoeurs/resources/img/fleche.svg");
    width: 0.375rem;
    rotate: 90deg;
    translate: 0 1px;
    display: inline-block;
    margin-left: 0.25rem;
    transform-origin: center;
    transition: transform 300ms;
  }
  [lang=de] .menu-li-dropdown:hover::after,
  [lang=nl] .menu-li-dropdown:hover::after {
    transform: rotate(180deg);
  }
  [lang=de] .menu-li-dropdown:focus-within .menu-dropdown, [lang=de] .menu-li-dropdown:hover .menu-dropdown,
  [lang=nl] .menu-li-dropdown:focus-within .menu-dropdown,
  [lang=nl] .menu-li-dropdown:hover .menu-dropdown {
    color: #1C1C1C;
  }
  [lang=de] .top-menu-container,
  [lang=nl] .top-menu-container {
    display: flex;
    flex-direction: row;
    order: initial;
    padding: 0 1rem;
  }
  [lang=de] .container,
  [lang=nl] .container {
    max-width: 68.25rem;
    display: flex;
    width: 80vw;
    padding: 0;
    gap: 1.5rem;
    align-items: center;
    flex: none;
  }
  [lang=de] input[type=checkbox],
  [lang=nl] input[type=checkbox] {
    display: none;
  }
  [lang=de] body,
  [lang=nl] body {
    margin: 0 auto;
    background-color: #ffffff;
  }
  [lang=de] .burger,
  [lang=de] .burger-wrapper, [lang=de] [id=checkbox_toggle],
  [lang=nl] .burger,
  [lang=nl] .burger-wrapper,
  [lang=nl] [id=checkbox_toggle] {
    display: none;
  }
  [lang=de] .logo-mobile,
  [lang=nl] .logo-mobile {
    display: flex;
    margin-right: 5rem;
    align-items: center;
  }
  [lang=de] .background-nav,
  [lang=nl] .background-nav {
    background-color: #ffffff;
    -webkit-box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.5);
    -moz-box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.5);
    box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.5);
  }
  [lang=de] header,
  [lang=nl] header {
    background-color: #ffffff;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0 auto;
    height: auto;
  }
  [lang=de] .nav,
  [lang=nl] .nav {
    display: flex;
    align-items: center;
    width: 100%;
  }
  [lang=de] .sub-menu,
  [lang=nl] .sub-menu {
    visibility: hidden;
    overflow: hidden;
    height: 0;
    top: 5rem;
    padding: 0;
    -webkit-box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.7);
    -moz-box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.7);
    box-shadow: -1px 1px 28px -6px rgba(150, 150, 150, 0.7);
    z-index: 11;
    position: absolute;
    background-color: #ffffff;
    border-radius: 0.5rem;
    min-height: initial;
    max-height: initial;
    /* Nouvelles propriétés pour centrer le sous-menu */
    left: 50%; /* Déplace le sous-menu à 50% de la largeur de son parent */
    transform: translateX(-50%); /* Déplace le sous-menu de moitié de sa propre largeur vers la gauche */
  }
  [lang=de] .sub-menu li,
  [lang=nl] .sub-menu li {
    background-color: #ffffff;
    padding: 0.5rem 1rem 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    transition: all 0.5s;
    line-height: 1.5em;
  }
  [lang=de] .sub-menu li a,
  [lang=nl] .sub-menu li a {
    font-size: 1rem;
    font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
    font-weight: 700;
    color: #51565D;
    background-color: #ffffff;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
  }
  [lang=de] .sub-menu li:first-of-type,
  [lang=nl] .sub-menu li:first-of-type {
    padding-top: 0.25rem;
  }
  [lang=de] .sub-menu li:last-of-type,
  [lang=nl] .sub-menu li:last-of-type {
    padding-bottom: 0.25rem;
  }
  [lang=de] .menu-li-dropdown:focus-within .sub-menu,
  [lang=de] .menu-li-dropdown:hover .sub-menu,
  [lang=de] .menu-dropdown:focus-within ~ .sub-menu,
  [lang=de] .menu-dropdown:hover ~ .sub-menu,
  [lang=nl] .menu-li-dropdown:focus-within .sub-menu,
  [lang=nl] .menu-li-dropdown:hover .sub-menu,
  [lang=nl] .menu-dropdown:focus-within ~ .sub-menu,
  [lang=nl] .menu-dropdown:hover ~ .sub-menu {
    visibility: initial;
    height: initial;
    overflow: initial;
    transition: visibility 0.3s, top 0.5s;
    padding: 1.5rem 1rem 1.5rem 1rem;
    top: 3.5rem;
  }
  [lang=de] .menu-logo-container,
  [lang=nl] .menu-logo-container {
    display: flex;
    flex-wrap: nowrap;
  }
  [lang=de] .menu-logo-container .logo-desktop,
  [lang=nl] .menu-logo-container .logo-desktop {
    display: flex;
    align-items: center;
    margin-right: 4rem;
    line-height: 0;
    font-size: 0;
  }
  [lang=de] .logo-mobile,
  [lang=nl] .logo-mobile {
    display: none;
  }
  [lang=de] .menu-li:last-of-type a::after,
  [lang=nl] .menu-li:last-of-type a::after {
    display: none;
    min-height: 0;
    max-height: 0;
  }
  [lang=de] .menu-li:last-of-type:hover a,
  [lang=de] .menu-li:last-of-type a:focus,
  [lang=nl] .menu-li:last-of-type:hover a,
  [lang=nl] .menu-li:last-of-type a:focus {
    background-color: #ffffff;
    color: #9359c6;
    border: 2px solid #9359c6;
  }
  [lang=de] .menu-li:last-of-type,
  [lang=nl] .menu-li:last-of-type {
    padding: 0;
  }
  [lang=de] .menu-li:last-of-type a,
  [lang=nl] .menu-li:last-of-type a {
    padding: 0.75rem 1.25rem;
    border-radius: 0.5rem;
    background-color: #9359c6;
    border: 2px solid #9359c6;
    transition: all 300ms;
    color: white;
    font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
    font-weight: 700;
  }
  [lang=de] .menu-li:last-of-type,
  [lang=de] .menu-li:last-of-type a,
  [lang=nl] .menu-li:last-of-type,
  [lang=nl] .menu-li:last-of-type a {
    margin-left: auto;
  }
  [lang=de] .socialmedia,
  [lang=nl] .socialmedia {
    margin-right: auto;
    margin-bottom: 0;
  }
  [lang=de] .socialmedia .socialmedia__network--fb::before,
  [lang=de] .socialmedia .socialmedia__network--ig::before,
  [lang=de] .socialmedia .socialmedia__network--tw::before,
  [lang=de] .socialmedia .socialmedia__network--tk::before,
  [lang=nl] .socialmedia .socialmedia__network--fb::before,
  [lang=nl] .socialmedia .socialmedia__network--ig::before,
  [lang=nl] .socialmedia .socialmedia__network--tw::before,
  [lang=nl] .socialmedia .socialmedia__network--tk::before {
    width: 1.25rem;
  }
  [lang=de] .socialmedia__network,
  [lang=nl] .socialmedia__network {
    min-height: 1.25rem;
    max-height: 1.25rem;
  }
  [lang=de] .top-menu-container,
  [lang=nl] .top-menu-container {
    background-color: transparent;
    max-width: 80rem;
    margin: 0 auto;
    align-items: center;
    justify-content: flex-end;
    padding: 0 1.25rem;
  }
  [lang=de] .menu-logo-container,
  [lang=nl] .menu-logo-container {
    max-width: 80rem;
    margin: 0 auto;
  }
  [lang=de] .langages__container .langages__item .langages__link::after,
  [lang=nl] .langages__container .langages__item .langages__link::after {
    background: #0074be;
    max-height: 4px;
    bottom: -0.25rem;
  }
  [lang=de] .langages__container .langages__item .langages__link:hover, [lang=de] .langages__container .langages__item .langages__link:focus,
  [lang=nl] .langages__container .langages__item .langages__link:hover,
  [lang=nl] .langages__container .langages__item .langages__link:focus {
    color: #1C1C1C;
  }
  [lang=de] .langages__container .langages__item .langages__link:hover::after, [lang=de] .langages__container .langages__item .langages__link:focus::after,
  [lang=nl] .langages__container .langages__item .langages__link:hover::after,
  [lang=nl] .langages__container .langages__item .langages__link:focus::after {
    width: 100%;
  }
  [lang=de] .langages__container .langages__item,
  [lang=nl] .langages__container .langages__item {
    padding: 0;
  }
  [lang=de] .menu .top-contact,
  [lang=nl] .menu .top-contact {
    display: flex;
    gap: 2rem;
    font-size: 0.875rem;
    padding: 0.5rem 2rem 0.5rem 0;
    order: 1;
  }
  [lang=de] .langages,
  [lang=nl] .langages {
    order: 1;
  }
  [lang=de] .top-menu__background,
  [lang=nl] .top-menu__background {
    order: initial;
    width: 99vw;
    background-color: rgba(189, 189, 189, 0.25);
  }
  [lang=de] .menu-logo__background,
  [lang=nl] .menu-logo__background {
    width: 100vw;
    background-color: #ffffff;
  }
  [lang=de] .menu .menu__top-contact,
  [lang=nl] .menu .menu__top-contact {
    padding: 0;
  }
  [lang=de] .top-menu-container .menu__top-contact a,
  [lang=nl] .top-menu-container .menu__top-contact a {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.7098039216);
  }
}
@media (min-width: 1100px) {
  [lang=de] .container,
  [lang=fr] .container,
  [lang=en] .container,
  [lang=nl] .container {
    max-width: 68.25rem;
    width: 78vw;
  }
}
@media (min-width: 1200px) {
  [lang=de] .container,
  [lang=fr] .container,
  [lang=en] .container,
  [lang=nl] .container {
    max-width: 66.25rem;
    width: 80vw;
  }
}
@media (min-width: 1300px) {
  [lang=de] .container,
  [lang=fr] .container,
  [lang=en] .container,
  [lang=nl] .container {
    max-width: 67.25rem;
    width: 80vw;
  }
}
@media (min-width: 80rem) {
  [lang=de] body,
  [lang=fr] body,
  [lang=en] body,
  [lang=nl] body {
    margin: 0 auto;
    background-color: #ffffff;
  }
  [lang=de] .container,
  [lang=fr] .container,
  [lang=en] .container,
  [lang=nl] .container {
    width: 83.5vw;
  }
  [lang=de] .top-menu-container,
  [lang=fr] .top-menu-container,
  [lang=en] .top-menu-container,
  [lang=nl] .top-menu-container {
    padding: 0;
  }
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

[lang] body {
  background: #FBF5F3 repeat url("../../resources/img/fond-color.svg");
  background-size: 50%;
}

@keyframes ellipseBackground {
  0% {
    rotate: 0;
  }
  100% {
    rotate: 360deg;
  }
}
@keyframes rectBackground {
  0% {
    rotate: 0;
  }
  100% {
    rotate: -360deg;
  }
}
.svg-ell1 {
  position: absolute;
  fill: #FFCC41;
  max-width: 30%;
  left: -4%;
  bottom: 15%;
  height: auto;
}
.svg-ell2 {
  position: absolute;
  z-index: -1;
  fill: #EF6D51;
  max-width: 50%;
  left: 10%;
  bottom: 15%;
  height: auto;
}
.svg-rect1 {
  position: absolute;
  z-index: -1;
  fill: #9359c6;
  max-width: 60%;
  height: auto;
  right: -1rem;
  bottom: 2rem;
  animation: ellipseBackground 15s infinite linear;
}
.svg-rect2 {
  position: absolute;
  z-index: -1;
  fill: #55BA8F;
  max-width: 18%;
  height: auto;
  left: 4%;
  top: 30%;
  animation: rectBackground 16s infinite linear;
}
.svg-rect3 {
  position: absolute;
  fill: #EF6D51;
  max-width: 10%;
  height: auto;
  right: 4%;
  top: 15%;
  animation: ellipseBackground 11s infinite linear;
}

.index {
  overflow: hidden;
  padding: 5rem 1.25rem 5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.index strong {
  position: relative;
  display: inline-block;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
}
.index strong:last-of-type::before {
  background-color: #55BA8F;
}
.index strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 6px;
  left: 0;
  width: 100%;
  height: 27%;
  background-color: #EF6D51;
}
.index__title {
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.5em;
  padding-top: 1rem;
}
.index__description {
  padding-top: 1rem;
  font-size: 0.875rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.55em;
  text-align: left;
  color: #51565D;
}
.index__link {
  display: inline-block;
  font-size: 1.25rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  color: #292929;
  margin: 2.5rem 0 0;
  padding-left: 1rem;
  transition: all 300ms;
}
.index__link::before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-o.svg");
  transition: translate 300ms;
  display: inline-block;
  width: 0.5rem;
  height: 0.95rem;
  translate: -1rem;
}
.index__link:hover, .index__link:focus {
  color: #EF6D51;
}
.index__link:hover::before, .index__link:focus::before {
  translate: -0.4rem;
}
.index__img {
  order: -1;
  width: 100%;
  height: auto;
  border-radius: 2rem;
}
.index__img-wrapper {
  position: relative;
  order: -1;
}

.services {
  background-color: #ffffff;
  padding: 5rem 1.25rem;
}
.services__background {
  max-width: 80rem;
  margin: 0 auto;
}
.services__title {
  position: relative;
  z-index: 1;
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.5em;
}
.services__title strong {
  position: relative;
  display: inline-block;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
}
.services__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #9359c6;
}
.services__sro {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
}
.services__text-link {
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  color: #292929;
  display: inline-flex;
  transition: all 300ms;
  margin-top: auto;
  padding: 1rem 1rem 1rem 1.5rem;
  cursor: pointer;
}
.services__text-link::before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-o.svg");
  display: inline-block;
  transition: translate 300ms;
  width: 0.5rem;
  height: 0.95rem;
  translate: -1.5rem;
}
.services__card {
  display: flex;
  text-align: left;
  flex-direction: column;
  margin: 2rem auto 0;
  padding: 1rem;
  max-width: 24rem;
  min-height: 36rem;
  max-height: 460px;
  border-radius: 0.5rem;
  background-color: #fffcfa;
  box-shadow: 0 3px 6px #ffefe6;
  border: 1px solid #EF6D51;
  position: relative;
}
.services__card:hover .services__text-link, .services__card:focus .services__text-link {
  color: #EF6D51;
}
.services__card:hover .services__text-link::before, .services__card:focus .services__text-link::before {
  translate: -0.5rem;
}
.services__card:nth-child(2) {
  background: #f8fffb;
  border: 1px solid #55BA8F;
  box-shadow: 0px 3px 6px #e5ffef;
}
.services__card:nth-child(3) {
  background: #faffff;
  border: 1px solid #66B7B4;
  box-shadow: 0px 3px 6px #e3fffe;
}
.services__card:nth-child(4) {
  background: #fbfaff;
  border: 1px solid #9359c6;
  box-shadow: 0px 3px 6px #f2efff;
}
.services__card-title {
  text-align: left;
  position: relative;
  transition: all 300ms;
  font-size: 1.5rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  margin: -2rem 0 0;
  padding-top: 1.5rem;
  border-top: 2px solid lightgrey;
}
.services__card-description {
  color: #51565D;
  text-align: left;
  width: 100%;
  padding: 1rem 0;
  font-size: 0.875rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5em;
}
.services__card-img {
  width: 100%;
  max-width: 12rem;
  min-height: 14rem;
  object-fit: cover;
  height: auto;
  order: -1;
  position: relative;
  top: -2rem;
  align-self: center;
}

.services__card:hover [data-name=Cliniclowns],
.services__card:focus [data-name=Cliniclowns] {
  color: #EF6D51;
}
.services__card:hover [data-name=Clinitalents],
.services__card:focus [data-name=Clinitalents] {
  color: #55BA8F;
}
.services__card:hover [data-name=Clinisnoezs],
.services__card:focus [data-name=Clinisnoezs] {
  color: #9359c6;
}
.services__card:hover [data-name=Clinijuniors],
.services__card:focus [data-name=Clinijuniors] {
  color: #66B7B4;
}

[data-name=Cliniclowns]::before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-o.svg");
}

[data-name=Clinitalents]::before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-v.svg");
}

[data-name=Clinisnoezs]::before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-p.svg");
}

[data-name=Clinijuniors]::before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-b.svg");
}

.creations {
  padding: 0 0 4rem;
  background-color: #ffffff;
}
.creations__background {
  max-width: 80rem;
  margin: 0 auto;
}
.creations__title {
  position: relative;
  padding: 0 1.25rem 0;
  z-index: 1;
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.5em;
}
.creations__title strong {
  display: inline-block;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  position: relative;
}
.creations__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #EF6D51;
}

.product-main__link {
  transition: all 300ms;
}
.product-main__link:hover, .product-main__link:focus {
  scale: 1.1;
}
.product-main__link:nth-child(1) .product-main__card-title {
  background-color: #9359c6;
}
.product-main__link:nth-child(2) .product-main__card-title {
  background-color: #EF6D51;
}
.product-main__link:nth-child(3) .product-main__card-title {
  background-color: #0980a7;
}
.product-main__link:nth-child(4) .product-main__card-title {
  background-color: #37814d;
}
.product-main__link:nth-child(5) .product-main__card-title {
  background-color: #0980a7;
}
.product-main__link:nth-child(6) .product-main__card-title {
  background-color: #9359c6;
}
.product-main__link:nth-child(7) .product-main__card-title {
  background-color: #37814d;
}
.product-main__link:nth-child(8) .product-main__card-title {
  background-color: #EF6D51;
}

.volunteers {
  max-width: 80rem;
  margin: 0 auto;
  padding: 5rem 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.volunteers__title {
  padding-top: 1.5rem;
  position: relative;
  z-index: 1;
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.5em;
}
.volunteers__title strong {
  display: inline-block;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  position: relative;
}
.volunteers__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #55BA8F;
}
.volunteers__description {
  padding-top: 1rem;
  font-size: 0.875rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.55em;
  text-align: left;
  color: #51565D;
}
.volunteers__link {
  transition: all 300ms;
  margin-top: 1.5rem;
  display: inline-block;
  font-size: 1.25rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  color: #292929;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
}
.volunteers__link::before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-v.svg");
  display: inline-block;
  transition: translate 300ms;
  width: 0.5rem;
  height: 0.95rem;
  translate: -1.5rem;
}
.volunteers__link:focus, .volunteers__link:hover {
  color: #55BA8F;
}
.volunteers__link:focus::before, .volunteers__link:hover::before {
  translate: -0.5rem;
}
.volunteers__container {
  order: -1;
  position: relative;
}
.volunteers__firstImage {
  max-width: 100%;
  height: auto;
  border-radius: 2rem;
  position: relative;
}
.volunteers__secondImage {
  max-width: 100%;
  height: auto;
  border-radius: 2rem;
}

.reviews {
  background-color: #ffffff;
  padding: 0 0 5rem;
}
.reviews__background {
  max-width: 80rem;
  margin: 0 auto;
}
.reviews__title {
  padding: 0 1.25rem;
  position: relative;
  z-index: 1;
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.5em;
}
.reviews__title strong {
  display: inline-block;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  position: relative;
}
.reviews__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #9359c6;
}
.reviews__container {
  margin: 2rem 1rem 0 0;
  padding: 1.5rem;
  border: 1px solid #51565D;
  min-height: 12rem;
  max-height: 12rem;
  min-width: 20rem;
  background-color: #ffffff;
  border-radius: 1rem;
}
.reviews__wrapper {
  padding: 0 1.25rem 1rem;
  overflow-x: scroll;
  overflow-y: auto;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.reviews__wrapper-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1.5rem;
}
.reviews__img {
  display: inline-block;
  max-width: 4rem;
  max-height: 4rem;
  object-fit: cover;
  border-radius: 2rem;
  height: auto;
  margin-right: 1rem;
}
.reviews__name {
  font-size: 0.875rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  order: 1;
}
.reviews__description {
  line-height: 1.25em;
  color: #51565D;
}

@media (min-width: 375px) {
  .services__card {
    min-height: 36rem;
  }
  .services__text-link {
    font-size: 1rem;
  }
}
@media (min-width: 765px) {
  .svg-ell1 {
    position: absolute;
    fill: #FFCC41;
    max-width: 30%;
    left: -4%;
    bottom: 15%;
    height: auto;
  }
  .svg-ell2 {
    position: absolute;
    z-index: -1;
    fill: #EF6D51;
    max-width: 50%;
    left: 10%;
    bottom: 25%;
    height: auto;
  }
  .svg-rect1 {
    position: absolute;
    z-index: -1;
    fill: #9359c6;
    max-width: 60%;
    height: auto;
    right: 0;
    bottom: 18%;
  }
  .index {
    margin: 0 auto;
    max-width: 80rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
  }
  .index__wrapper {
    padding: 4rem 0;
    order: -1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 25rem;
  }
  .index__img {
    display: inline-block;
    max-width: 100%;
    object-fit: cover;
  }
  .index__link {
    white-space: nowrap;
    max-width: max-content;
  }
  .services__wrapper {
    display: grid;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    row-gap: 0;
    column-gap: 2rem;
    flex: 0;
  }
  .services__card {
    width: 100%;
    max-width: 25rem;
  }
  .creations__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .creations__card {
    max-width: 25%;
  }
  .creations__card-title {
    font-size: 1.15rem;
  }
  .volunteers {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .volunteers__wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    max-width: 45%;
  }
  .volunteers__container {
    width: 160%;
  }
  .volunteers__secondImage {
    object-fit: cover;
  }
  .reviews {
    padding-bottom: 3rem;
  }
  .reviews__container {
    max-width: 20rem;
    min-width: 20rem;
    border: 0.5px solid #51565D;
  }
  .reviews__wrapper {
    overflow-x: scroll;
    overflow-y: auto;
    padding: 1rem 0;
  }
  .reviews__wrapper-text {
    display: flex;
    align-items: center;
    padding-bottom: 1.5rem;
  }
}
@media (min-width: 995px) {
  .index {
    padding: 5rem 1.25rem;
  }
  .index__link {
    margin: 1.5rem 0;
  }
  .index strong::before {
    content: "";
    z-index: -1;
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 28%;
    background-color: #EF6D51;
  }
  .index__wrapper {
    max-width: 32rem;
    padding: 6rem 0 6rem 0;
  }
  .index__title {
    font-size: 3rem;
    line-height: 3.5rem;
  }
  .services__wrapper {
    gap: 1.25rem;
  }
  .services__card-img {
    min-height: 14rem;
    max-height: 14rem;
  }
}
@media (min-width: 1160px) {
  .index {
    justify-content: space-between;
  }
  .creations__title {
    padding: 0 1.25rem 2rem;
  }
  .creations__wrapper {
    padding: 0 0 2rem;
  }
  .volunteers {
    padding: 5rem 1.25rem;
  }
}
.construct__figure {
  text-align: center;
  position: relative;
}

.construct__mode {
  position: absolute;
  background-color: white;
  border-radius: 0 0 1rem 0;
  font-weight: bold;
  font-size: 1.2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  padding: 0.5rem;
}

@media (min-width: 80rem) {
  .services__wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
  .index {
    overflow: visible;
    justify-content: flex-start;
    gap: 10rem;
    padding: 5rem 0;
  }
  .index__wrapper {
    padding: 6rem 0;
  }
  .volunteers {
    padding: 5rem 0;
  }
  .volunteers__container {
    width: 54%;
  }
  .volunteers__wrapper {
    max-width: 40%;
  }
  .creations__title {
    padding: 0 0 2rem;
  }
  .reviews__title {
    padding: 0;
  }
}
@media (max-width: 375px) {
  .index__link {
    line-height: 1.5em;
  }
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.news {
  position: relative;
  background-color: #ffffff;
  padding: 5rem 1.25rem;
}
.news__background {
  max-width: 80rem;
  margin: 0 auto;
}
.news__title {
  position: relative;
  z-index: 1;
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.5em;
}
.news__title strong {
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  position: relative;
}
.news__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #EF6D51;
}
.news__container {
  transition: all 300ms;
  margin-top: 2rem;
  position: relative;
}
.news__container:focus, .news__container:hover {
  scale: 1.05;
}
.news__container-title {
  font-size: 1.25rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  color: #ffffff;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  line-height: 1.25em;
  z-index: 2;
  padding-right: 1rem;
}
.news__container-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1C1C1C;
  opacity: 0.5;
  border-radius: 0.5rem;
  z-index: 1;
}
.news__img {
  position: relative;
  max-width: 100%;
  height: auto;
  max-height: 20rem;
  object-fit: cover;
  border-radius: 0.5rem;
}

@media (min-width: 765px) {
  .news {
    padding-top: 0;
  }
  .news__title {
    padding-top: 4rem;
  }
  .news__wrapper {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .news__container {
    margin-top: -3px;
  }
  .news__container-wrapper::after {
    height: 99.5%;
  }
  .news__wrapper {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 2rem;
  }
}
@media (min-width: 995px) {
  .news__container-title {
    font-size: 1.75rem;
    line-height: 1.25em;
    max-width: 85%;
  }
}
.video-actu {
  border-radius: 0.5rem;
  max-width: 40rem;
  height: auto;
  width: 100%;
  max-height: 30rem;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 1rem;
  width: 100%;
  margin: 2rem auto;
  max-width: 90%;
}
.video-actu .wp-video {
  border-radius: 0.5rem;
  max-width: 40rem;
  height: auto;
  width: 100% !important;
  max-height: 30rem;
}
.video-actu video {
  border-radius: 0.5rem;
  max-width: 40rem;
  height: auto;
  width: 100%;
  max-height: 30rem;
}

@media (min-width: 765px) {
  .video-actu {
    width: 100%;
    margin: 2rem auto 2rem 0;
    max-width: 40rem;
  }
  .main__news {
    padding-top: 6rem;
  }
  .main__news .news {
    background-color: transparent;
  }
  .main__news .news__title {
    font-size: 3rem;
  }
  .main__news .news__wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 995px) {
  .main__news {
    padding-top: 0;
  }
}
@media (min-width: 995px) {
  .main__news {
    padding-top: 0;
  }
  .main__news .news {
    background-color: transparent;
  }
  .main__news .news__title {
    padding-top: 2rem;
  }
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.single__news {
  padding-bottom: 4rem;
}
.single__news-title {
  padding: 8rem 1.25rem 1rem;
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  line-height: 1.25em;
}
.single__news-date {
  padding: 1rem 1.25rem 2rem;
  font-size: 1rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5em;
}
.single__news-img {
  display: block;
  border-radius: 0.5rem;
  margin: 0 auto;
  padding: 0 1.25rem;
  width: 100%;
  height: auto;
}
.single__news-intro {
  padding: 2rem 1.25rem 0;
  font-size: 1rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5em;
}
.single__news-text, .single__news-end {
  padding: 1rem 1.25rem 0;
  font-size: 1rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5em;
}

@media (min-width: 765px) {
  .single__news {
    max-width: 80rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: minmax(0, 1fr) 18.75rem;
    padding: 10rem 0 8rem;
  }
  .single__news-title {
    padding: 0 1.25rem 1rem;
  }
  .single__news-background {
    max-width: 80rem;
  }
  .single__news-img {
    max-width: 40rem;
    padding: 0;
    margin: 0;
  }
  .single__news-wrapper {
    max-width: 80rem;
  }
  .single__news-intro, .single__news-text, .single__news-end {
    max-width: 45rem;
    padding: 1rem 0 1rem;
  }
  .single__news .news {
    margin-left: 2rem;
    background-color: transparent;
    padding: 0 1.25rem 1rem;
    max-width: 80rem;
  }
  .single__news .news__container-title {
    font-size: 1rem;
  }
  .single__news .news__title {
    font-size: 1.75rem;
    line-height: 1.28em;
    padding-top: 0;
    padding-bottom: 0.5rem;
  }
  .single__news .news__wrapper {
    grid-template-columns: 1fr;
  }
}
@media (min-width: 995px) {
  .single__news {
    padding: 2rem 1.25rem 5rem;
  }
  .single__news-title {
    max-width: 49rem;
    padding: 0;
  }
  .single__news-intro, .single__news-text, .single__news-end {
    max-width: 49rem;
    font-size: 1rem;
  }
  .single__news-date {
    padding: 1rem 0 2rem;
  }
  .single__news .news {
    padding: 0 0 1rem;
  }
}
@media (min-width: 80rem) {
  .single__news {
    grid-template-columns: minmax(0, 1fr) 21.75rem;
  }
  .single__news-img {
    max-width: 49rem;
    max-height: 30rem;
    object-fit: cover;
  }
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.url-field-wrapper {
  position: absolute;
  left: -9999px;
}

.partner-form,
.volunteering-form,
.support-form,
.product-form {
  padding: 4rem 1.25rem 4rem;
}

.formulars__success {
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  color: darkgreen;
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 1.5em;
}

.formulars__field_error,
.formulars__error {
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  color: red;
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 1.5em;
}

.formulars__field_error {
  margin-top: 0;
  margin-bottom: 2rem;
}

.page__contact {
  padding: 8rem 1.25rem 4rem;
}
.page__contact-title {
  position: relative;
  z-index: 1;
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.25em;
  margin-bottom: 1.5rem;
}
.page__contact-title strong {
  display: inline-block;
  position: relative;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
}
.page__contact-title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #9359c6;
}
.page__contact-text {
  max-width: 25rem;
  color: #51565D;
  font-size: 0.875rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.55em;
  margin-bottom: 1rem;
}

.contact__information {
  padding: 2rem 0 4rem;
}
.contact__information-title {
  position: relative;
  z-index: 1;
  font-size: 1.5rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.25em;
  margin-bottom: 1.5rem;
}
.contact__information-title strong {
  display: inline-block;
  position: relative;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
}
.contact__information-title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 28%;
  background-color: #9359c6;
}
.contact__information-list-text {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.25em;
}
.contact__information-list-text:last-of-type a {
  padding-bottom: 0;
}
.contact__information-list-link {
  padding-top: 0.25rem;
  font-size: 0.875rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.25em;
  color: #51565D;
  padding-bottom: 1.25rem;
  display: inline-block;
  max-width: fit-content;
}

.form {
  transition: all 300ms;
}
.form__wrapper {
  background-color: #ffffff;
  border-radius: 0.5rem;
  flex-shrink: 0;
  flex-grow: 1;
}
.form__background {
  padding: 1.5rem;
}
.form__title {
  position: relative;
  z-index: 1;
  font-size: 1.5rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.25em;
  margin-bottom: 1.5rem;
}
.form__title strong {
  display: inline-block;
  position: relative;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
}
.form__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 28%;
  background-color: #9359c6;
}
.form label {
  display: block;
  font-size: 1rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  color: #1C1C1C;
  margin-top: 1.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.5em;
}
.form label span {
  font-size: 0.875rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5em;
}
.form .indication-message {
  line-height: 1.5em;
}
.form input,
.form textarea,
.form select {
  color: #51565D;
  min-width: 100%;
  max-width: 100%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid #E1E0E6;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
}
.form .form__submit {
  margin-top: 1.5rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  color: #ffffff;
  border: 2px solid #9359c6;
  transition: all 300ms;
  margin-bottom: 0;
}
.form .form__submit:hover, .form .form__submit:focus {
  cursor: pointer;
  color: #9359c6;
  background-color: transparent;
}

@media (min-width: 768px) {
  .page__contact {
    padding: 10rem 1.25rem 6rem;
  }
  .page__contact-title {
    position: relative;
    z-index: 1;
    font-size: 2rem;
    font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
    font-weight: 500;
    line-height: 1.25em;
    margin-bottom: 1.5rem;
  }
  .contact__information-title {
    font-size: 1.75rem;
  }
  .form {
    align-items: flex-start;
    max-width: 80rem;
    margin: 0 auto;
    display: flex;
    gap: 2rem;
    justify-content: space-between;
  }
  .form__title {
    font-size: 1.75rem;
  }
  .form__wrapper {
    max-width: 50%;
  }
}
.product .product-faq strong::before,
.product .product-form strong::before {
  background-color: #EF6D51;
}
.product .product-faq .form__submit,
.product .product-form .form__submit {
  border: 2px solid #EF6D51;
  background-color: #EF6D51;
}
.product .product-faq .form__submit:hover,
.product .product-form .form__submit:hover {
  color: #EF6D51;
  background-color: transparent;
}

@media (min-width: 995px) {
  .page__contact {
    padding: 5rem 1.25rem;
  }
}
@media (min-width: 80rem) {
  .partner-form,
  .volunteering-form,
  .support-form,
  .product-form {
    padding: 4rem 0 4rem;
  }
  .partner-form .page__contact-title,
  .volunteering-form .page__contact-title,
  .support-form .page__contact-title,
  .product-form .page__contact-title {
    font-size: 2rem;
  }
  .page__contact {
    padding: 6rem 0;
  }
  .page__contact-title {
    position: relative;
    z-index: 1;
    font-size: 3rem;
    font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
    font-weight: 500;
    line-height: 1.25em;
    margin-bottom: 1.5rem;
    max-width: 40rem;
  }
  .contact__information-title {
    font-size: 2rem;
  }
  .form {
    gap: 6rem;
  }
  .form__title {
    font-size: 2rem;
  }
  .form input,
  .form textarea,
  .form select {
    max-width: 32rem;
  }
  .volunteering-form .form {
    gap: 7rem;
  }
}
[id=form] {
  padding-top: 8em; /* Ajoutez la valeur appropriée pour compenser la position de l'ancre */
  margin-top: -8em; /* Ajoutez la valeur négative correspondante à la valeur de padding-top */
}

.page__contact [id=form] {
  padding-top: 0; /* Ajoutez la valeur appropriée pour compenser la position de l'ancre */
  margin-top: 0; /* Ajoutez la valeur négative correspondante à la valeur de padding-top */
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.main {
  overflow: hidden;
}
.main__title {
  padding-top: 1.25rem;
}

.clinicoeurs .svg-ell1 {
  fill: #EF6D51;
  left: -10%;
  bottom: 10%;
}
.clinicoeurs .svg-ell2 {
  fill: #FFCC41;
  max-width: 50%;
  left: 65%;
  bottom: -10%;
}
.clinicoeurs .svg-rect1 {
  fill: #66B7B4;
  right: -4rem;
  bottom: 10rem;
}
.clinicoeurs .svg-rect2 {
  fill: #55BA8F;
  max-width: 18%;
  height: auto;
  left: -6%;
  top: 4%;
}
.clinicoeurs .svg-rect3 {
  fill: #9359c6;
  max-width: 15%;
  right: 4%;
  top: 10%;
}

.main,
.but,
.history,
.espace,
.benevole {
  max-width: 80rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 5rem 1.25rem 0;
}
.main__title,
.but__title,
.history__title,
.espace__title,
.benevole__title {
  position: relative;
  z-index: 1;
  font-size: 2rem;
  line-height: 1.25em;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  margin-bottom: 1.5rem;
}
.main__title strong,
.but__title strong,
.history__title strong,
.espace__title strong,
.benevole__title strong {
  position: relative;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  display: inline-block;
}
.main__title strong::before,
.but__title strong::before,
.history__title strong::before,
.espace__title strong::before,
.benevole__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #55BA8F;
}
.main__text,
.but__text,
.history__text,
.espace__text,
.benevole__text {
  color: #51565D;
  font-size: 0.875rem;
  line-height: 1.55em;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  margin-bottom: 2rem;
}

.main__text {
  margin-bottom: 5rem;
}

.but,
.history,
.espace,
.benevole {
  background-color: white;
  position: relative;
  z-index: 0;
}
.but__background,
.history__background,
.espace__background,
.benevole__background {
  max-width: 80rem;
}
.but__img-wrapper,
.history__img-wrapper,
.espace__img-wrapper,
.benevole__img-wrapper {
  margin-top: 4rem;
  position: relative;
}

.but__title strong::before {
  background-color: #9359c6;
}

.history {
  padding: 0 1.25rem 5rem;
}
.history-formation__img {
  margin-bottom: 0;
}
.history__title strong::before {
  background-color: #66B7B4;
}

.espace__title strong::before {
  background-color: #EF6D51;
}

.benevole__title strong {
  display: inline-block;
}
.benevole__title strong::before {
  background-color: #55BA8F;
}
.benevole__text {
  margin-bottom: 1.5rem;
}
.benevole__link {
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  color: #292929;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  display: inline-block;
  max-width: 16rem;
  max-width: fit-content;
  margin-bottom: 2rem;
  transition: all 300ms;
}
.benevole__link::before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-v.svg");
  transition: translate 300ms;
  display: inline-block;
  width: 0.5rem;
  height: 0.95rem;
  translate: -1rem;
}
.benevole__link:hover, .benevole__link:focus {
  color: #55BA8F;
}
.benevole__link:hover::before, .benevole__link:focus::before {
  translate: -0.4rem;
}

.reviews {
  background-color: initial;
}

.members {
  max-width: 80rem;
  margin: 0 auto;
  padding: 2rem 0 3rem;
}
.members__title {
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  padding: 0 1.25rem;
}
.members__title strong {
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  position: relative;
  display: inline-block;
}
.members__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background: linear-gradient(90deg, rgb(239, 109, 81) 0%, rgb(239, 109, 81) 26%, rgb(85, 186, 143) 26%, rgb(85, 186, 143) 49%, rgb(102, 183, 180) 49%, rgb(105, 177, 181) 75%, rgb(148, 90, 198) 75%, rgb(148, 90, 198) 100%);
}

.members-cliniclowns__title,
.members-clinitalents__title,
.members-clinijuniors__title,
.members-clinisnoezs__title {
  padding: 2.5rem 1.25rem 1.5rem;
  font-size: 1.5rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.15em;
}
.members-cliniclowns__title strong,
.members-clinitalents__title strong,
.members-clinijuniors__title strong,
.members-clinisnoezs__title strong {
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  display: inline-block;
  position: relative;
  z-index: 1;
}
.members-cliniclowns__title strong::before,
.members-clinitalents__title strong::before,
.members-clinijuniors__title strong::before,
.members-clinisnoezs__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #EF6D51;
}
.members-cliniclowns__wrapper,
.members-clinitalents__wrapper,
.members-clinijuniors__wrapper,
.members-clinisnoezs__wrapper {
  display: flex;
  gap: 1.25rem;
  overflow: scroll;
  padding: 0 1.25rem;
}
.members-cliniclowns__link,
.members-clinitalents__link,
.members-clinijuniors__link,
.members-clinisnoezs__link {
  display: inline-block;
  font-size: 0.875rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  color: #292929;
  margin: 1rem 0 1rem 1.25rem;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  transition: all 300ms;
}
.members-cliniclowns__link::before,
.members-clinitalents__link::before,
.members-clinijuniors__link::before,
.members-clinisnoezs__link::before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-o.svg");
  transition: translate 300ms;
  display: inline-block;
  width: 0.5rem;
  height: 0.95rem;
  translate: -1rem;
}
.members-cliniclowns__link:hover, .members-cliniclowns__link:focus,
.members-clinitalents__link:hover,
.members-clinitalents__link:focus,
.members-clinijuniors__link:hover,
.members-clinijuniors__link:focus,
.members-clinisnoezs__link:hover,
.members-clinisnoezs__link:focus {
  color: #EF6D51;
}
.members-cliniclowns__link:hover::before, .members-cliniclowns__link:focus::before,
.members-clinitalents__link:hover::before,
.members-clinitalents__link:focus::before,
.members-clinijuniors__link:hover::before,
.members-clinijuniors__link:focus::before,
.members-clinisnoezs__link:hover::before,
.members-clinisnoezs__link:focus::before {
  translate: -0.4rem;
}
.members-cliniclowns__container,
.members-clinitalents__container,
.members-clinijuniors__container,
.members-clinisnoezs__container {
  position: relative;
}
.members-cliniclowns-img,
.members-clinitalents-img,
.members-clinijuniors-img,
.members-clinisnoezs-img {
  width: 15rem;
  height: 15rem;
  object-fit: cover;
  border-radius: 2rem;
}
.members-cliniclowns-name,
.members-clinitalents-name,
.members-clinijuniors-name,
.members-clinisnoezs-name {
  max-width: 88%;
  line-height: 1.5em;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  background-color: white;
  padding: 1rem 1.5rem;
  border-radius: 0 1rem 1rem 1rem;
}

.members-clinitalents__title strong::before {
  background-color: #55BA8F;
}
.members-clinitalents__link::before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-v.svg");
}
.members-clinitalents__link:hover, .members-clinitalents__link:focus {
  color: #55BA8F;
}

.members-clinijuniors__title strong::before {
  background-color: #66B7B4;
}
.members-clinijuniors__link::before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-b.svg");
}
.members-clinijuniors__link:hover, .members-clinijuniors__link:focus {
  color: #66B7B4;
}

.members-clinisnoezs__title strong::before {
  background-color: #9359c6;
}
.members-clinisnoezs__link::before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-p.svg");
}
.members-clinisnoezs__link:hover, .members-clinisnoezs__link:focus {
  color: #9359c6;
}

.but-formation__img,
.espace-formation__img {
  border-radius: 2rem;
  max-width: 100%;
  height: auto;
  margin-bottom: 5rem;
}

.history,
.benevole {
  padding-top: 0;
  padding-bottom: 5rem;
}
.history-formation__img,
.benevole-formation__img {
  border-radius: 2rem;
  max-width: 100%;
  height: auto;
  margin-bottom: 0;
}

.clinicoeurs .reviews {
  padding-top: 4rem;
}

@media (min-width: 375px) {
  .members-cliniclowns__link,
  .members-clinitalents__link,
  .members-clinijuniors__link,
  .members-clinisnoezs__link {
    font-size: 1.125rem;
  }
  .benevole__link {
    font-size: 1.125rem;
  }
}
@media (min-width: 765px) {
  .members-cliniclowns-img,
  .members-clinitalents-img,
  .members-clinijuniors-img,
  .members-clinisnoezs-img {
    width: 15rem;
    height: 15rem;
    object-fit: cover;
    max-width: 100%;
  }
  .svg-cl-ell3 {
    bottom: -9%;
  }
  .but__img-wrapper,
  .history__img-wrapper,
  .espace__img-wrapper,
  .benevole__img-wrapper {
    margin-top: 0;
    max-width: 50%;
  }
  .main {
    padding-top: 6rem;
    display: flex;
    justify-content: space-between;
    gap: 4rem;
    flex-direction: row;
    margin: 0 auto;
    max-width: 80rem;
    align-items: stretch;
    padding-bottom: 5rem;
  }
  .main__title strong {
    display: inline-block;
  }
  .main__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 25rem;
    order: -1;
  }
  .main__text {
    margin-bottom: 0;
  }
  .but,
  .espace {
    max-width: initial;
    padding-bottom: 2rem;
  }
  .but__text,
  .espace__text {
    margin-bottom: 0;
  }
  .but__background,
  .espace__background {
    max-width: 80rem;
    margin: 0 auto;
    padding: 0 0 2rem;
    gap: 2rem;
    display: flex;
    align-items: center;
  }
  .but-formation__img,
  .espace-formation__img {
    margin-bottom: 0;
    min-width: 45%;
  }
  .history,
  .benevole {
    max-width: initial;
    padding-bottom: 2rem;
  }
  .history__img-wrapper,
  .benevole__img-wrapper {
    order: -1;
  }
  .history__text,
  .benevole__text {
    margin-bottom: 0;
  }
  .history__background,
  .benevole__background {
    max-width: 80rem;
    margin: 0 auto;
    padding: 2rem 0 4rem;
    display: flex;
    align-items: center;
  }
  .history-formation__img,
  .benevole-formation__img {
    margin-right: 2rem;
    margin-bottom: 0;
    max-width: 50%;
    order: -1;
  }
  .benevole__text {
    margin-bottom: 2rem;
  }
  .benevole__link {
    margin-bottom: 0;
  }
  .members {
    padding: 4rem 0;
  }
  .members__title {
    display: inline-block;
    font-size: 2.5rem;
    line-height: 1.2em;
    max-width: 60%;
  }
  .members-cliniclowns__title, .members-clinitalents__title, .members-clinisnoezs__title, .members-clinijuniors__title {
    font-size: 1.75rem;
    line-height: 1.25em;
    display: inline-block;
    max-width: 50%;
  }
  .members-cliniclowns__wrapper, .members-clinitalents__wrapper, .members-clinisnoezs__wrapper, .members-clinijuniors__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    overflow: initial;
  }
  .members-cliniclowns__link, .members-clinitalents__link, .members-clinisnoezs__link, .members-clinijuniors__link {
    margin-top: 2rem;
  }
}
@media (min-width: 995px) {
  .clinicoeurs .index__img-wrapper {
    max-width: 35rem;
  }
  .main {
    padding-top: 5rem;
  }
  .main__title {
    font-size: 3rem;
    line-height: 1.15em;
  }
  .main__wrapper {
    max-width: 30rem;
  }
  .members__title {
    font-size: 2.5rem;
  }
  .members-cliniclowns__title, .members-clinitalents__title, .members-clinisnoezs__title, .members-clinijuniors__title {
    font-size: 1.5rem;
  }
  .members-cliniclowns__wrapper, .members-clinitalents__wrapper, .members-clinisnoezs__wrapper, .members-clinijuniors__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .members-cliniclowns__link, .members-clinitalents__link, .members-clinisnoezs__link, .members-clinijuniors__link {
    font-size: 1.25rem;
    margin-top: 2rem;
  }
}
@media (min-width: 80rem) {
  .main {
    padding: 6rem 0;
    justify-content: flex-start;
    gap: 10rem;
  }
  .but__background,
  .espace__background {
    justify-content: space-between;
  }
  .but__background-wrapper,
  .espace__background-wrapper {
    max-width: 45%;
  }
  .history__background,
  .benevole__background {
    justify-content: space-between;
  }
  .history__background-wrapper,
  .benevole__background-wrapper {
    max-width: 45%;
  }
  .members__title {
    padding: 0;
    max-width: 40%;
  }
  .members__wrapper {
    padding: 0 0 2rem;
  }
  .members-cliniclowns__title,
  .members-clinitalents__title,
  .members-clinijuniors__title,
  .members-clinisnoezs__title {
    padding: 2.5rem 0 1.5rem;
  }
  .members-cliniclowns__wrapper,
  .members-clinitalents__wrapper,
  .members-clinijuniors__wrapper,
  .members-clinisnoezs__wrapper {
    padding: 0;
  }
  .members-cliniclowns__link,
  .members-clinitalents__link,
  .members-clinijuniors__link,
  .members-clinisnoezs__link {
    margin: 1rem 0 1rem;
  }
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.cliniclowns-main, .cliniclowns-who, .cliniclowns-formation, .cliniclowns-contact, .cliniclowns-animal, .cliniclowns-members {
  display: flex;
  flex-direction: column;
  padding: 4rem 1.25rem 0;
}
.cliniclowns-main__title, .cliniclowns-who__title, .cliniclowns-formation__title, .cliniclowns-contact__title, .cliniclowns-animal__title, .cliniclowns-members__title {
  position: relative;
  z-index: 1;
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.25em;
  margin-bottom: 1.5rem;
}
.cliniclowns-main__title strong, .cliniclowns-who__title strong, .cliniclowns-formation__title strong, .cliniclowns-contact__title strong, .cliniclowns-animal__title strong, .cliniclowns-members__title strong {
  display: inline-block;
  position: relative;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
}
.cliniclowns-main__title strong::before, .cliniclowns-who__title strong::before, .cliniclowns-formation__title strong::before, .cliniclowns-contact__title strong::before, .cliniclowns-animal__title strong::before, .cliniclowns-members__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #EF6D51;
}
.cliniclowns-main__text, .cliniclowns-who__text, .cliniclowns-formation__text, .cliniclowns-contact__text, .cliniclowns-animal__text, .cliniclowns-members__text {
  color: #51565D;
  font-size: 0.875rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.55em;
  margin-bottom: 1rem;
}
.cliniclowns-main__link, .cliniclowns-who__link, .cliniclowns-formation__link, .cliniclowns-contact__link, .cliniclowns-animal__link, .cliniclowns-members__link {
  display: inline-block;
  font-size: 1.25rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  color: #292929;
  margin: 1rem 0;
  padding: 1rem 1.5rem;
  max-width: 14rem;
  max-width: fit-content;
  transition: all 300ms;
}
.cliniclowns-main__link::before, .cliniclowns-who__link::before, .cliniclowns-formation__link::before, .cliniclowns-contact__link::before, .cliniclowns-animal__link::before, .cliniclowns-members__link::before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-o.svg");
  transition: translate 300ms;
  display: inline-block;
  width: 0.5rem;
  height: 0.95rem;
  translate: -1rem;
}
.cliniclowns-main__link:hover, .cliniclowns-main__link:focus, .cliniclowns-who__link:hover, .cliniclowns-who__link:focus, .cliniclowns-formation__link:hover, .cliniclowns-formation__link:focus, .cliniclowns-contact__link:hover, .cliniclowns-contact__link:focus, .cliniclowns-animal__link:hover, .cliniclowns-animal__link:focus, .cliniclowns-members__link:hover, .cliniclowns-members__link:focus {
  color: #EF6D51;
}
.cliniclowns-main__link:hover::before, .cliniclowns-main__link:focus::before, .cliniclowns-who__link:hover::before, .cliniclowns-who__link:focus::before, .cliniclowns-formation__link:hover::before, .cliniclowns-formation__link:focus::before, .cliniclowns-contact__link:hover::before, .cliniclowns-contact__link:focus::before, .cliniclowns-animal__link:hover::before, .cliniclowns-animal__link:focus::before, .cliniclowns-members__link:hover::before, .cliniclowns-members__link:focus::before {
  translate: -0.4rem;
}
.cliniclowns-main {
  gap: 2rem;
}
.cliniclowns-main__text {
  margin-bottom: 4rem;
}
.cliniclowns-main {
  padding-top: 1rem;
}
.cliniclowns-ariane__container {
  max-width: 80rem;
  margin: 0 auto;
  padding: 5rem 1.25rem 0;
  display: flex;
}
.cliniclowns-ariane__link {
  font-size: 0.875rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  color: #1C1C1C;
  margin-right: 0.5rem;
  display: inline-flex;
  align-items: center;
  transition: all 300ms;
  transform: scale(1);
  transform-origin: center;
}
.cliniclowns-ariane__link:hover, .cliniclowns-ariane__link:focus {
  transform: scale(1.1);
}
.cliniclowns-ariane__link::after {
  content: url("/wp-content/themes/clinicoeurs/resources/img/fleche-service.svg");
  width: 0.5rem;
  height: 1.1rem;
  margin-left: 0.5rem;
  color: #51565D;
}
.cliniclowns__img {
  order: -1;
  width: 100%;
  height: auto;
  border-radius: 2rem;
}
.cliniclowns__img-wrapper {
  order: -1;
  position: relative;
}
.cliniclowns-who__text, .cliniclowns-formation__text {
  margin-bottom: 0;
}
.cliniclowns-who__wrapper, .cliniclowns-formation__wrapper {
  display: flex;
  flex-direction: column;
}
.cliniclowns-who__img, .cliniclowns-formation__img {
  border-radius: 2rem;
  max-width: 100%;
  height: auto;
}
.cliniclowns-contact__text {
  margin-bottom: 0;
}
.cliniclowns-formation__img {
  margin-bottom: 4rem;
}
.cliniclowns-formation__text {
  margin-bottom: 1rem;
}
.cliniclowns-main, .cliniclowns-members {
  max-width: 80rem;
  margin: 0 auto;
}
.cliniclowns-who, .cliniclowns-formation, .cliniclowns-contact, .cliniclowns-animal {
  background-color: white;
}
.cliniclowns-who__wrapper, .cliniclowns-formation__wrapper, .cliniclowns-contact__wrapper, .cliniclowns-animal__wrapper {
  max-width: 80rem;
  margin: 0 auto;
}
.cliniclowns-members {
  padding: 4rem 0 4rem;
}
.cliniclowns-members__title {
  padding: 0 1.25rem 1rem;
}
.cliniclowns-members__wrapper {
  padding: 0 1.25rem 1.5rem;
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  overflow: scroll;
}
.cliniclowns-members__wrapper img {
  width: 15rem;
  height: 15rem;
  object-fit: cover;
}
.cliniclowns .news {
  background-color: transparent;
}
.cliniclowns .gallery__wrapper-text {
  position: relative;
  z-index: 1;
}
.cliniclowns .gallery__wrapper-text strong {
  display: inline-block;
  position: relative;
}
.cliniclowns .gallery__wrapper-text strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #EF6D51;
}

p.cliniclowns-ariane__link:last-of-type {
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  color: #1C1C1C;
}
p.cliniclowns-ariane__link:last-of-type::after {
  content: "";
}

@media (min-width: 765px) {
  .cliniclowns-ariane__container {
    padding: 6rem 1.25rem 0;
  }
  .cliniclowns-members__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    overflow: initial;
  }
  .cliniclowns-members__wrapper img {
    width: 15rem;
    height: 15rem;
    object-fit: cover;
  }
  .cliniclowns-main {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: 80rem;
    align-items: stretch;
    padding-bottom: 2rem;
  }
  .cliniclowns-main__title strong {
    display: inline-block;
  }
  .cliniclowns-main__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 30rem;
    order: -1;
  }
  .cliniclowns-who, .cliniclowns-contact {
    max-width: initial;
    padding-bottom: 2rem;
  }
  .cliniclowns-who__text, .cliniclowns-contact__text {
    margin-bottom: 0;
  }
  .cliniclowns-who__wrapper, .cliniclowns-contact__wrapper {
    max-width: 80rem;
    margin: 0 auto;
    padding: 4rem 0 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .cliniclowns-who .cliniclowns-who__img,
  .cliniclowns-who .cliniclowns-formation__img, .cliniclowns-contact .cliniclowns-who__img,
  .cliniclowns-contact .cliniclowns-formation__img {
    max-width: 50%;
    margin-left: 2rem;
    margin-bottom: 0;
  }
  .cliniclowns-formation, .cliniclowns-animal {
    max-width: initial;
    padding-bottom: 2rem;
  }
  .cliniclowns-formation__text, .cliniclowns-animal__text {
    margin-bottom: 0;
  }
  .cliniclowns-formation__wrapper, .cliniclowns-animal__wrapper {
    max-width: 80rem;
    margin: 0 auto;
    padding: 2rem 0 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .cliniclowns-formation .cliniclowns-formation__img, .cliniclowns-animal .cliniclowns-formation__img {
    order: -1;
    max-width: 50%;
    margin-right: 2rem;
    margin-bottom: 0;
  }
}
@media (min-width: 995px) {
  .cliniclowns-ariane__container {
    padding: 1.25rem 1.25rem 0;
  }
  .cliniclowns-members__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .cliniclowns-main {
    padding-top: 4rem;
    padding-bottom: 5rem;
    justify-content: space-between;
    overflow: visible;
  }
  .cliniclowns-main__title {
    font-size: 3rem;
    line-height: 1.16em;
  }
  .cliniclowns__img {
    max-width: 80%;
    margin: 0 auto;
    display: block;
  }
  .cliniclowns__img-wrapper {
    overflow: visible;
    max-width: 50%;
  }
}
@media (min-width: 80rem) {
  .cliniclowns-ariane__container {
    padding: 1.25rem 0 0;
  }
  .cliniclowns-members__title {
    padding: 0 0 1rem;
  }
  .cliniclowns-members__wrapper {
    padding: 0 0 1.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .cliniclowns-main {
    padding: 2rem 0 6rem;
  }
  .cliniclowns__img {
    padding: 0;
  }
  .cliniclowns-who__wrapper, .cliniclowns-formation__wrapper, .cliniclowns-contact__wrapper, .cliniclowns-animal__wrapper {
    justify-content: space-between;
  }
  .cliniclowns-who__wrapper-text, .cliniclowns-formation__wrapper-text, .cliniclowns-contact__wrapper-text, .cliniclowns-animal__wrapper-text {
    max-width: 45%;
  }
  .cliniclowns .gallery__wrapper-text {
    padding: 3rem 0 2rem;
  }
  .cliniclowns .gallery__wrapper-img {
    padding: 0 0 3rem;
  }
  .creations__wrapper {
    padding: 0 0 2rem;
  }
}
/* Gallery : infinite slider */
/* CSS HSL */
/* Shadows */
.gallery-service {
  padding: 2.35em 0;
  background: white;
}
.gallery-service-container {
  max-width: 80rem;
  margin: 0 auto;
}
.gallery-service span {
  display: block;
  text-align: center;
  color: hsl(1, 97%, 65%);
}
.gallery-service h2 {
  padding: 0 1.25rem;
}
@keyframes slider {
  0% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(-2000px);
  }
}
.gallery-service .gallery__slider {
  margin-top: 2.5em;
  display: flex;
  width: 200%;
}
.gallery-service .gallery__slider__img {
  animation: slider 20s linear infinite;
  display: flex;
  gap: 0 1.5em;
  margin-left: 1.5rem;
}
.gallery-service .gallery__slider__img img {
  border-radius: 1rem;
  min-width: 250px;
  max-height: 200px;
  object-fit: cover;
}
.gallery-service .gallery__slider:hover__img {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

@media screen and (min-width: 1250px) {
  .gallery-service {
    background: white;
  }
  .gallery-service span, .gallery-service h2 {
    text-align: left;
    padding: 0;
  }
  .gallery-service .gallery__slider {
    margin: 3.5em auto 3em;
  }
  .gallery-service .gallery__slider__img img {
    min-width: 375px;
    max-height: 300px;
  }
}
.pagination .prev,
.pagination .next {
  display: initial;
  position: initial;
  font-size: 1rem;
  color: black;
}

.pagination .next {
  padding-left: 1rem;
}

.pagination .prev {
  padding-right: 1rem;
}

.pagination .current {
  font-size: 1.25rem;
  font-weight: bold;
  color: black;
}

.page-numbers {
  font-size: 1rem;
  font-weight: lighter;
  color: grey;
}

.cliniclowns,
.clinitalents,
.clinisnoezs,
.clinijuniors {
  overflow: hidden;
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.clinitalents-main {
  overflow: hidden;
}
.clinitalents-main, .clinitalents-who, .clinitalents-recruitment, .clinitalents-members {
  max-width: 80rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 2rem 1.25rem 0;
}
.clinitalents-main__title, .clinitalents-who__title, .clinitalents-recruitment__title, .clinitalents-members__title {
  position: relative;
  z-index: 1;
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.5em;
  margin-bottom: 1.5rem;
}
.clinitalents-main__title strong, .clinitalents-who__title strong, .clinitalents-recruitment__title strong, .clinitalents-members__title strong {
  display: inline-block;
  position: relative;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
}
.clinitalents-main__title strong::before, .clinitalents-who__title strong::before, .clinitalents-recruitment__title strong::before, .clinitalents-members__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #55BA8F;
}
.clinitalents-main__text, .clinitalents-who__text, .clinitalents-recruitment__text, .clinitalents-members__text {
  color: #51565D;
  font-size: 0.875rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.55em;
  margin-bottom: 1rem;
}
.clinitalents-main__link, .clinitalents-who__link, .clinitalents-recruitment__link, .clinitalents-members__link {
  display: inline-block;
  font-size: 1.25rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  color: #292929;
  margin: 1rem 0;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  max-width: 14rem;
  max-width: fit-content;
  transition: all 300ms;
}
.clinitalents-main__link::before, .clinitalents-who__link::before, .clinitalents-recruitment__link::before, .clinitalents-members__link::before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-v.svg");
  transition: translate 300ms;
  display: inline-block;
  width: 0.5rem;
  height: 0.95rem;
  translate: -1rem;
}
.clinitalents-main__link:hover, .clinitalents-main__link:focus, .clinitalents-who__link:hover, .clinitalents-who__link:focus, .clinitalents-recruitment__link:hover, .clinitalents-recruitment__link:focus, .clinitalents-members__link:hover, .clinitalents-members__link:focus {
  color: #55BA8F;
}
.clinitalents-main__link:hover::before, .clinitalents-main__link:focus::before, .clinitalents-who__link:hover::before, .clinitalents-who__link:focus::before, .clinitalents-recruitment__link:hover::before, .clinitalents-recruitment__link:focus::before, .clinitalents-members__link:hover::before, .clinitalents-members__link:focus::before {
  translate: -0.4rem;
}
.clinitalents-recruitment__text {
  margin-bottom: 0;
}
.clinitalents-members {
  padding: 4rem 0 0;
}
.clinitalents-members__title {
  padding: 0 1.25rem;
}
.clinitalents-members__wrapper {
  display: flex;
  overflow: scroll;
  padding: 0 1.25rem 4rem;
}
.clinitalents-who, .clinitalents-recruitment {
  max-width: initial;
  background-color: #ffffff;
}
.clinitalents__background {
  max-width: 80rem;
  margin: 0 auto;
}
.clinitalents-formation__img {
  max-width: 100%;
  height: auto;
  border-radius: 2rem;
  margin-bottom: 4rem;
}
.clinitalents-main {
  padding-top: 1rem;
  gap: 2rem;
}
.clinitalents-main__text {
  margin-bottom: 4rem;
}

.members-clinitalents-img {
  margin-right: 1.25rem;
}
.members-clinitalents-img:last-of-type {
  margin-right: 0;
}

.clinitalents-activity {
  max-width: 80rem;
  margin: 0 auto;
}
.clinitalents-activity-wrapper {
  display: flex;
  overflow: scroll;
}
.clinitalents-activity__title {
  padding: 2rem 1.25rem 0;
  position: relative;
  z-index: 1;
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.5em;
  margin-bottom: 1.5rem;
}
.clinitalents-activity__title strong {
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  display: inline-block;
  position: relative;
}
.clinitalents-activity__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #55BA8F;
}

.cards__activity {
  display: flex;
  padding: 0 1.25rem 1rem;
  margin-bottom: 3rem;
}
.cards__activity-wrapper {
  background-color: #ffffff;
  border-radius: 0 1rem 1rem 0;
  padding: 1.5rem 1.5rem;
  margin-right: 2rem;
}
.cards__activity-title {
  font-size: 1.25rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  margin-bottom: 1.5rem;
}
.cards__activity p {
  font-size: 0.875rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.55em;
  color: #51565D;
}
.cards__activity-img {
  border-radius: 1rem 0 0 1rem;
  order: -1;
  min-width: 35%;
  height: auto;
  max-width: 20rem;
  object-fit: cover;
}

@media (min-width: 765px) {
  .members-clinitalents-img {
    margin-right: 0;
    justify-self: center;
  }
  .clinitalents-members__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: stretch;
    align-items: stretch;
    overflow: initial;
    object-fit: cover;
    column-gap: 1rem;
    row-gap: 1rem;
  }
  .clinitalents-who, .clinitalents-activity, .clinitalents-recruitment {
    padding: 4rem 1.25rem;
  }
  .clinitalents-main {
    justify-content: space-between;
    padding: 0 1.25rem 4rem;
    display: flex;
    flex-direction: row;
  }
  .clinitalents-main__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 30rem;
    order: -1;
  }
  .clinitalents__background {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .clinitalents-formation__img {
    max-width: 50%;
    margin: 0;
    margin-left: 2rem;
  }
  .clinitalents-activity__title {
    padding: 2rem 0 1rem;
  }
  .clinitalents-activity-wrapper {
    overflow: initial;
    max-width: 100%;
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .cards__activity {
    padding: 0;
    max-width: 100%;
    height: auto;
    align-self: stretch;
    margin-bottom: 0;
    max-height: 15rem;
  }
  .cards__activity-img {
    width: 15rem;
    height: 15rem;
  }
  .cards__activity-wrapper {
    margin: 0;
  }
  .recruitment-img {
    order: -1;
    margin-right: 2rem;
    margin-left: 0;
  }
}
@media (min-width: 995px) {
  .clinitalents-main {
    padding: 5rem 1.25rem 6rem;
  }
  .clinitalents-main__title {
    font-size: 3rem;
    line-height: 1.16em;
  }
  .clinitalents-members__wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (min-width: 80rem) {
  .clinitalents-main {
    padding: 2rem 0 6rem;
    overflow: visible;
  }
  .clinitalents__background {
    justify-content: space-between;
  }
  .clinitalents-recruitment__wrapper, .clinitalents-who__wrapper {
    max-width: 45%;
  }
  .clinitalents-members__title {
    padding: 0;
  }
  .clinitalents-members__wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 0 0 4rem;
  }
  .clinitalents-activity {
    padding: 4rem 0;
  }
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.clinijuniors .svg-ell1 {
  fill: #EF6D51;
  left: -10%;
  top: -11%;
  max-width: 20%;
}
.clinijuniors .svg-ell2 {
  fill: #FFCC41;
  max-width: 50%;
  left: 64%;
  bottom: -10%;
}
.clinijuniors .svg-rect1 {
  fill: #FFCC41;
  right: -4rem;
  bottom: 10rem;
  max-width: 46%;
}
.clinijuniors .svg-rect2 {
  fill: #66B7B4;
  max-width: 33%;
  left: -13%;
  top: 54%;
  z-index: 1;
}
.clinijuniors .svg-rect3 {
  fill: #55BA8F;
  max-width: 12%;
  right: 4%;
  top: 82%;
  z-index: 1;
}
.clinijuniors-main {
  overflow: hidden;
}
.clinijuniors__background {
  max-width: 80rem;
  margin: 0 auto;
}
.clinijuniors-learn__background {
  padding-bottom: 1rem;
}
.clinijuniors-learn, .clinijuniors-who {
  background-color: #ffffff;
}
.clinijuniors-main, .clinijuniors-who, .clinijuniors-learn {
  display: flex;
  flex-direction: column;
  padding: 2rem 1.25rem 0;
}
.clinijuniors-main__title, .clinijuniors-who__title, .clinijuniors-learn__title {
  position: relative;
  z-index: 1;
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.25em;
  margin-bottom: 1.5rem;
}
.clinijuniors-main__title strong, .clinijuniors-who__title strong, .clinijuniors-learn__title strong {
  display: inline-block;
  position: relative;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
}
.clinijuniors-main__title strong::before, .clinijuniors-who__title strong::before, .clinijuniors-learn__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #66B7B4;
}
.clinijuniors-main__text, .clinijuniors-who__text, .clinijuniors-learn__text {
  color: #51565D;
  font-size: 0.875rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.55em;
}
.clinijuniors-main__link, .clinijuniors-who__link, .clinijuniors-learn__link {
  display: inline-block;
  font-size: 1rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  color: #292929;
  margin: 1rem 0;
  padding: 1rem 1.5rem;
  max-width: 14rem;
  max-width: fit-content;
}
.clinijuniors-main__link::before, .clinijuniors-who__link::before, .clinijuniors-learn__link::before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-b.svg");
  transition: translate 300ms;
  display: inline-block;
  width: 0.5rem;
  height: 0.95rem;
  translate: -1rem;
}
.clinijuniors-main__link:hover, .clinijuniors-main__link:focus, .clinijuniors-who__link:hover, .clinijuniors-who__link:focus, .clinijuniors-learn__link:hover, .clinijuniors-learn__link:focus {
  color: #66B7B4;
}
.clinijuniors-main__link:hover::before, .clinijuniors-main__link:focus::before, .clinijuniors-who__link:hover::before, .clinijuniors-who__link:focus::before, .clinijuniors-learn__link:hover::before, .clinijuniors-learn__link:focus::before {
  translate: -0.4rem;
}
.clinijuniors-recruitment__text {
  margin-bottom: 0;
}
.clinijuniors-members {
  margin-bottom: 4rem;
}
.clinijuniors-members__title {
  padding: 2.5rem 1.25rem 1.5rem;
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.25em;
}
.clinijuniors-members__title strong {
  position: relative;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  display: inline-block;
}
.clinijuniors-members__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #66B7B4;
}
.clinijuniors-members__wrapper {
  display: flex;
  gap: 1.25rem;
  overflow: scroll;
  padding: 0 1.25rem 1rem;
}
.clinijuniors__img {
  border-radius: 2rem;
  max-width: 100%;
  height: auto;
  margin-bottom: 2rem;
}
.clinijuniors__img-wrapper {
  order: -1;
  position: relative;
}
.clinijuniors-main {
  padding: 1rem 1.25rem 4rem;
}

.clinijuniors-who .clinijuniors__img, .clinijuniors-learn .clinijuniors__img {
  margin-bottom: 4rem;
}

@media (min-width: 765px) {
  .clinijuniors__img-wrapper {
    margin-right: 0;
    justify-self: center;
  }
  .clinijuniors__img {
    margin: 0;
  }
  .clinijuniors-members {
    max-width: 80rem;
    margin: 0 auto;
    padding: 4rem 0;
  }
  .clinijuniors-members__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: stretch;
    align-items: stretch;
    object-fit: cover;
    overflow: initial;
  }
  .clinijuniors-who, .clinijuniors-learn {
    padding: 4rem 1.25rem;
  }
  .clinijuniors-who .clinijuniors__img, .clinijuniors-learn .clinijuniors__img {
    max-width: 50%;
    margin-bottom: 0;
    margin-left: 2rem;
  }
  .clinijuniors-who .clinijuniors__img {
    order: -1;
    margin-right: 2rem;
    margin-left: 0;
  }
  .clinijuniors-main {
    justify-content: space-between;
    padding: 1rem 1.25rem 6rem;
    display: flex;
    flex-direction: row;
    max-width: 80rem;
    margin: 0 auto;
  }
  .clinijuniors-main .clinijuniors__img {
    border-radius: 4rem;
    max-width: 32rem;
  }
  .clinijuniors-main__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 25rem;
    order: -1;
    margin-right: 2rem;
  }
  .clinijuniors__background {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .clinijuniors-activity {
    padding: 4rem 0;
  }
  .clinijuniors-activity__title {
    padding: 2rem 0 1rem;
  }
  .clinijuniors-activity-wrapper {
    overflow: initial;
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .recruitment-img {
    order: -1;
    margin-right: 2rem;
    margin-left: 0;
  }
}
@media (min-width: 995px) {
  .clinijuniors-main {
    padding: 5rem 1.25rem 6rem;
  }
  .clinijuniors-main__title {
    font-size: 3rem;
    line-height: 1.16em;
  }
  .clinijuniors-members__wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1.75rem;
  }
}
@media (min-width: 80rem) {
  .clinijuniors-main {
    padding: 2rem 0 6rem;
    overflow: visible;
  }
  .clinijuniors__background {
    justify-content: space-between;
  }
  .clinijuniors-who__background, .clinijuniors-learn__background {
    max-width: 45%;
  }
  .clinijuniors-members__title {
    padding: 2.5rem 0 1.5rem;
  }
  .clinijuniors-members__wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 0 0 4rem;
  }
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.clinisnoezs .svg-ell1 {
  fill: #EF6D51;
  left: -10%;
  top: -1%;
  max-width: 20%;
}
.clinisnoezs .svg-ell2 {
  fill: #FFCC41;
  max-width: 50%;
  left: 64%;
  bottom: -4%;
  z-index: 1;
}
.clinisnoezs .svg-rect1 {
  fill: #9359c6;
  right: -4rem;
  bottom: 7rem;
}
.clinisnoezs .svg-rect2 {
  fill: #55BA8F;
  max-width: 26%;
  left: -8%;
  top: 70%;
}
.clinisnoezs-main {
  overflow: hidden;
}
.clinisnoezs__background {
  max-width: 80rem;
  margin: 0 auto;
}
.clinisnoezs-main, .clinisnoezs-who, .clinisnoezs-where {
  padding: 2rem 1.25rem 0;
  display: flex;
  flex-direction: column;
}
.clinisnoezs-main__title, .clinisnoezs-who__title, .clinisnoezs-where__title {
  position: relative;
  z-index: 1;
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.5em;
  margin-bottom: 1.5rem;
}
.clinisnoezs-main__title strong, .clinisnoezs-who__title strong, .clinisnoezs-where__title strong {
  display: inline-block;
  position: relative;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
}
.clinisnoezs-main__title strong::before, .clinisnoezs-who__title strong::before, .clinisnoezs-where__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #9359c6;
}
.clinisnoezs-main__text, .clinisnoezs-who__text, .clinisnoezs-where__text {
  color: #51565D;
  font-size: 0.875rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.55em;
  margin-bottom: 1rem;
}
.clinisnoezs-main__link, .clinisnoezs-who__link, .clinisnoezs-where__link {
  display: inline-block;
  font-size: 1rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  color: #292929;
  margin: 1rem 0;
  padding: 1rem 1.5rem;
  max-width: 14rem;
  max-width: fit-content;
}
.clinisnoezs-main__link::before, .clinisnoezs-who__link::before, .clinisnoezs-where__link::before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-p.svg");
  transition: translate 300ms;
  display: inline-block;
  width: 0.5rem;
  height: 0.95rem;
  translate: -1rem;
}
.clinisnoezs-main__link:hover, .clinisnoezs-main__link:focus, .clinisnoezs-who__link:hover, .clinisnoezs-who__link:focus, .clinisnoezs-where__link:hover, .clinisnoezs-where__link:focus {
  color: #9359c6;
}
.clinisnoezs-main__link:hover::before, .clinisnoezs-main__link:focus::before, .clinisnoezs-who__link:hover::before, .clinisnoezs-who__link:focus::before, .clinisnoezs-where__link:hover::before, .clinisnoezs-where__link:focus::before {
  translate: -0.4rem;
}
.clinisnoezs-main {
  max-width: 80rem;
  margin: 0 auto;
}
.clinisnoezs-main__text {
  color: #51565D;
  font-size: 0.875rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.55em;
  margin-bottom: 3rem;
}
.clinisnoezs-recruitment__text {
  margin-bottom: 0;
}
.clinisnoezs__img {
  border-radius: 2rem;
  max-width: 100%;
  height: auto;
  margin-bottom: 4rem;
}
.clinisnoezs-who, .clinisnoezs-where {
  background-color: #ffffff;
}
.clinisnoezs-members {
  margin-bottom: 4rem;
}
.clinisnoezs-members__title {
  padding: 2.5rem 1.25rem 1.5rem;
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.5em;
}
.clinisnoezs-members__title strong {
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  display: inline-block;
  position: relative;
}
.clinisnoezs-members__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #9359c6;
}
.clinisnoezs-members__wrapper {
  display: flex;
  gap: 1.25rem;
  overflow: scroll;
  padding: 0 1.25rem 1rem;
}

.clinisnoezs-main {
  padding-top: 1rem;
}
.clinisnoezs-main .clinisnoezs__img {
  order: -1;
  margin-bottom: 2rem;
}
.clinisnoezs__img {
  border-radius: 2rem;
  max-width: 100%;
  height: 100%;
  margin-bottom: 4rem;
}
.clinisnoezs__img-wrapper {
  order: -1;
}

@media (min-width: 765px) {
  .clinisnoezs-members {
    max-width: 80rem;
    margin: 0 auto;
    padding: 4rem 0;
  }
  .clinisnoezs-members__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    overflow: initial;
    justify-items: stretch;
    align-items: stretch;
    object-fit: cover;
  }
  .clinisnoezs-main {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.25rem 4rem;
  }
  .clinisnoezs-main .clinisnoezs__img {
    margin-bottom: 0;
    border-radius: 2rem;
  }
  .clinisnoezs-main__background {
    max-width: 25rem;
    margin-right: 2rem;
  }
  .clinisnoezs__img-wrapper {
    order: 0;
  }
  .clinisnoezs__background {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .clinisnoezs-who {
    padding-top: 4rem;
  }
  .clinisnoezs-who .clinisnoezs__img {
    max-width: 50%;
    order: -1;
    margin: 0;
    margin-right: 2rem;
  }
  .clinisnoezs-where {
    padding: 4rem 1.25rem;
  }
  .clinisnoezs-where .clinisnoezs__img {
    max-width: 50%;
    margin: 0;
    margin-left: 4rem;
  }
}
@media (min-width: 995px) {
  .clinisnoezs-main {
    padding: 5rem 1.25rem 6rem;
  }
  .clinisnoezs-main__title {
    font-size: 3rem;
    line-height: 1.16em;
  }
  .clinisnoezs-members__wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .clinisnoezs .cliniclowns__img-wrapper {
    order: 1;
  }
}
@media (min-width: 80rem) {
  .clinisnoezs-main {
    overflow: visible;
    padding: 2rem 0 6rem;
  }
  .clinisnoezs-main .clinisnoezs__img {
    max-width: 93%;
  }
  .clinisnoezs__background {
    justify-content: space-between;
  }
  .clinisnoezs-who__background, .clinisnoezs-where__background {
    max-width: 45%;
  }
  .clinisnoezs-members__title {
    padding: 2.5rem 0 1.5rem;
  }
  .clinisnoezs-members__wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 0 0 4rem;
  }
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.single__products .cliniclowns-ariane__container {
  padding: 3rem 1.25rem 1rem;
}
.single__products .cliniclowns-ariane__link {
  line-height: 1.25em;
}
.single__products-ariane__link {
  font-size: 0.875rem;
  line-height: 1.25em;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  color: #1C1C1C;
  margin-right: 0.5rem;
}
.single__products-ariane__link::after {
  content: url("/wp-content/themes/clinicoeurs/resources/img/fleche-service.svg");
  width: 0.5rem;
  height: 1rem;
  margin-left: 0.5rem;
  color: #51565D;
}
.single__products-ariane__link:last-of-type {
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  color: #1C1C1C;
}
.single__products-ariane__link:last-of-type::after {
  content: "";
  width: 0;
  height: 0;
  margin-left: 0;
  color: transparent;
}
.single__products-title {
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.25em;
}
.single__products-title:nth-child(2) {
  margin-top: 8rem;
}
.single__products-title strong {
  position: relative;
  display: inline-block;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
}
.single__products-title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 6px;
  left: 0;
  width: 100%;
  height: 27%;
  background-color: #EF6D51;
}
.single__products-text-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem 4rem;
}
.single__products-img-main {
  order: -1;
  margin-bottom: 1rem;
  width: 100%;
  height: auto;
  border-radius: 4rem;
}
.single__products-description {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  color: #51565D;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.55em;
}
.single__products-wrapper {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  object-fit: cover;
  gap: 1.25rem;
}
.single__products-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 0.5rem;
  transition: all 300ms;
}
.single__products-image:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.single__products-container {
  position: relative;
  z-index: 0;
  padding: 4rem 1.25rem;
  background-color: #ffffff;
}

@media (min-width: 765px) {
  .single__products {
    margin: 0 auto;
    padding: 3rem 0 0;
  }
  .single__products-text-wrapper {
    max-width: 80rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }
  .single__products-img-main {
    order: 1;
    margin-bottom: 0;
    width: 100%;
    max-width: 25.5rem;
    height: auto;
    border-radius: 0.5rem;
  }
  .single__products-description {
    margin-top: 1.5rem;
    font-size: 0.875rem;
    font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
    font-weight: 400;
    line-height: 1.5em;
  }
  .single__products-wrapper {
    grid-template-columns: 1fr 1fr 1fr;
    object-fit: cover;
    gap: 2rem;
    justify-items: stretch;
    align-items: stretch;
  }
  .single__products-image {
    max-height: 16rem;
  }
  .single__products-container-second {
    margin: 0 auto;
    max-width: 80rem;
  }
}
@media (min-width: 995px) {
  .single__products {
    padding: 0;
  }
  .single__products-wrapper {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    object-fit: cover;
    gap: 2rem;
    justify-items: stretch;
    align-items: stretch;
  }
  .single__products-wrapper-text-wrapper {
    gap: 4rem;
  }
  .single__products .cliniclowns-ariane__container {
    padding-top: 1rem;
  }
  .single__products-text-wrapper {
    padding: 5rem 1.25rem;
  }
}
@media (min-width: 995px) {
  .single__products-title {
    font-size: 3rem;
  }
  .single__products-container .single__products-title {
    font-size: 2.5rem;
  }
  .single__products-text-background {
    max-width: 45rem;
  }
  .single__products-description {
    max-width: 35rem;
  }
}
@media (min-width: 80rem) {
  .single__products-img-main {
    max-width: 35rem;
    margin-left: 0;
  }
  .single__products .cliniclowns-ariane__container {
    padding: 1rem 0 5rem;
  }
  .single__products-text-wrapper {
    padding: 0 0 6rem;
  }
  .single__products-container {
    padding: 4rem 0;
  }
}
/* Styles for the image modal */
.modal {
  display: none;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}

.container-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  max-height: 100%;
}

.modal-content {
  display: block;
  margin: auto;
  max-width: 90%;
  max-height: 90%;
}

.close {
  color: #fff;
  font-size: 2.5rem;
  font-weight: bold;
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  cursor: pointer;
}

.prev,
.next {
  color: #fff;
  font-size: 2.5rem;
  font-weight: bold;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.prev {
  left: 1.25rem;
}

.next {
  right: 1.25rem;
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.product {
  max-width: 80rem;
  margin: 0 auto;
  padding: 2rem 0 0;
}
.product-form__background {
  display: flex;
  flex-direction: column;
  padding: 2rem 1.25rem;
}
.product-form__informations {
  box-shadow: 0 0 5px #dbdbdb;
  background-color: #ffffff;
  padding: 2rem;
  max-width: 35rem;
}
.product__title {
  font-size: 2rem;
  line-height: 1.25em;
  padding: 4rem 1.25rem 0;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
}
.product__title strong {
  display: inline-block;
  position: relative;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
}
.product__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #EF6D51;
}
.product__img {
  margin-top: 0;
  max-width: 100%;
  height: auto;
  border-radius: 0 0 0.5rem 0.5rem;
}
.product-main__title {
  font-size: 1.5rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  margin-bottom: 1.5rem;
  padding: 0 1.25rem;
}
.product-main__title strong {
  display: inline-block;
  position: relative;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
}
.product-main__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #EF6D51;
}
.product-main__wrapper {
  display: flex;
  overflow-x: scroll;
  gap: 1.25rem;
  padding: 2rem 1.25rem;
}
.product-main__card-title {
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.375em;
  text-align: center;
  color: #ffffff;
  background-color: #EF6D51;
  padding: 1.5rem 1rem 0;
  border-radius: 1rem 1rem 0 0;
  min-height: 4.5rem;
  min-width: 13rem;
  white-space: nowrap;
}
.product-main__card-img {
  min-width: 12rem;
  width: 100%;
  height: auto;
  border-radius: 0 0 1rem 1rem;
  min-height: 14rem;
  max-height: 14rem;
  object-fit: cover;
}
.product .product-main__link {
  transition: all 300ms;
}
.product .product-main__link:hover, .product .product-main__link:focus {
  scale: 1.05;
}
.product .product-main__link:nth-child(2n+3) .product-main__card-title {
  background-color: #9359c6;
}
.product .product-main__link:nth-child(4n+2) .product-main__card-title {
  background-color: #EF6D51;
}
.product .product-main__link:nth-child(5n+1) .product-main__card-title {
  background-color: #0980a7;
}
.product .product-main__link:nth-child(7n+4) .product-main__card-title {
  background-color: #37814d;
}
.product .product-main__link:nth-child(3n+2) .product-main__card-title {
  background-color: #0980a7;
}
.product .product-main__link:nth-child(3n+7) .product-main__card-title {
  background-color: #9359c6;
}
.product .product-main__link:nth-child(5n+1) .product-main__card-title {
  background-color: #37814d;
}
.product .product-main__link:nth-child(8) .product-main__card-title {
  background-color: #EF6D51;
}

.product-faq {
  margin-top: 2rem;
  padding: 2rem 1.25rem 3rem;
}
.product-faq__title {
  font-size: 1.5rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  padding-bottom: 1.5rem;
  line-height: 1.25em;
}
.product-faq__title strong {
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  display: inline-block;
  position: relative;
}
.product-faq__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #9359c6;
}
.product-faq__term {
  border-top: 1px solid black;
  padding: 0.5rem 0;
  font-size: 1rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  line-height: 1.5em;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
.product-faq__definition {
  transition: opacity 3s ease, max-height 5s ease;
  max-height: 10rem;
  height: initial;
  opacity: 1;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
}

.product__contact {
  padding: 2rem 1.25rem 6rem;
}
.product__contact__footer button {
  width: 100%;
  margin-top: 1.5rem;
  font-size: 1rem;
  display: block;
  padding: 1rem 1.5rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  color: white;
  background-color: #EF6D51;
  border-radius: 0.5rem;
  border: none;
}

.product-contact__title {
  padding: 3rem 1.25rem 0;
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
}
.product-contact__title strong {
  display: inline-block;
  position: relative;
}
.product-contact__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #EF6D51;
}

@media (min-width: 765px) {
  .product__title {
    padding: 6rem 1.25rem 1rem;
  }
  .product-main__title {
    font-size: 2rem;
  }
  .product-main__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    padding: 2rem 1.25rem;
  }
  .product-main__card {
    max-width: initial;
    padding: 0;
  }
  .product-faq {
    padding-top: 4rem;
    flex-direction: row;
    gap: 2rem;
  }
  .product-faq__background {
    position: relative;
    z-index: 1;
    background-color: #FBF5F3;
    padding-left: 1.25rem;
  }
  .product-contact {
    display: flex;
    flex-direction: row;
    padding: 4rem 1.25rem;
  }
  .product-contact__wrapper {
    position: relative;
    z-index: 1;
    order: 1;
    background-color: #FBF5F3;
    padding-left: 2rem;
    width: 100%;
  }
}
@media (min-width: 995px) {
  .product__title {
    padding-top: 0;
    font-size: 3rem;
    line-height: 1.16em;
  }
  .product-main__wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    overflow: visible;
  }
  .product-form__background {
    display: flex;
    flex-direction: row;
  }
}
@media (min-width: 80rem) {
  .product__title {
    padding: 0 0 3rem;
  }
  .product-main__title {
    padding: 0;
  }
  .product-main__wrapper {
    padding: 2rem 0;
  }
  .product-faq, .product-contact {
    padding: 4rem 0;
  }
}
.js-enabled .faq-hide-js {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  max-height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s, max-height 0.5s;
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.faq-container {
  border-radius: 0.5rem 0.5rem 0 0;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.faq {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  background: transparent;
  padding: 1.5rem 2rem 0 1.5rem;
  position: relative;
  overflow: hidden;
}

.faq:not(:first-child) {
  border-top: 1px solid #e6e6e6;
}

.faq-title {
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  margin: 0 2rem 1.5rem 0;
  line-height: 1.5em;
  transition: color 750ms ease, padding 750ms ease;
}

.faq-text {
  transition: all 750ms;
  overflow: hidden;
  height: 1px;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5rem;
}

.faq.active {
  background-color: #f8f8f8;
  box-shadow: inset 4px 0 0 0 #9359c6;
}

.faq.active .faq-title {
  color: #8b48c7;
}

.faq.active .faq-text {
  height: 14rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 375px) {
  .faq.active .faq-text {
    height: 11rem;
  }
}
@media (min-width: 425px) {
  .faq.active .faq-text {
    height: 9rem;
  }
}
@media (min-width: 650px) {
  .faq.active .faq-text {
    height: 5rem;
  }
}
@media (min-width: 1100px) {
  .faq.active .faq-text {
    height: 6rem;
  }
}
@media (min-width: 1200px) {
  .faq.active .faq-text {
    height: 6rem;
  }
}
@media (min-width: 1300px) {
  .faq.active .faq-text {
    height: 5rem;
  }
}
.faq-toggle {
  background-color: transparent;
  border: 1px solid #e6e6e6;
  color: inherit;
  border-radius: 50%;
  cursor: pointer;
  font-size: 0;
  position: absolute;
  top: 1.25rem;
  right: 30px;
  height: 30px;
  width: 30px;
  transition: 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.faq-toggle:before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/fleche.svg");
  width: 0.5rem;
  rotate: 90deg;
  transition: all 300ms;
}

.faq-toggle:focus {
  outline: none;
}

.faq.active .faq-toggle {
  background-color: #f8f8f8;
  color: #fff;
}
.faq.active .faq-toggle:before {
  content: url("/wp-content/themes/clinicoeurs/resources/img/fleche.svg");
  width: 0.5rem;
  rotate: 270deg;
}

@media (min-width: 765px) {
  .faq .active .faq-text {
    max-height: 17rem;
  }
  .faq-container {
    border-radius: 0.5rem 0.5rem 0 0;
  }
  .product__img {
    margin-top: 0;
    width: 120%;
    height: auto;
    object-fit: cover;
    border-radius: 0 0 1rem 1rem;
  }
  .product-faq__background {
    position: relative;
    z-index: 1;
    background-color: #FBF5F3;
    padding-left: 0;
  }
}
@media (min-width: 1000px) {
  .product-faq {
    display: flex;
    align-items: flex-start;
  }
  .product-faq__background {
    min-width: 27rem;
  }
  .faq-container {
    border-radius: 0.5rem;
  }
  .product__img {
    max-width: 50%;
    border-radius: 1rem;
  }
}
@media (min-width: 80rem) {
  .product-faq__background {
    padding-left: 0;
  }
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.gallery {
  background-color: white;
}
.gallery__background {
  max-width: 80rem;
  margin: 0 auto;
}
.gallery__wrapper-text {
  padding: 3rem 1.25rem 2rem;
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.5em;
}
.gallery__wrapper-text strong {
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  position: relative;
  z-index: 1;
  display: inline-block;
}
.gallery__wrapper-text strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #55BA8F;
}
.gallery__wrapper-img {
  padding: 0 1.25rem 3rem;
  overflow: scroll;
  display: flex;
}
.gallery__img {
  min-width: 17.5rem;
  max-width: 17.5rem;
  max-height: 17.5rem;
  object-fit: cover;
  margin-right: 1.25rem;
}

@media (min-width: 765px) {
  .gallery__img {
    max-width: 21rem;
    margin: 0;
  }
  .gallery__wrapper-img {
    gap: 1.25rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    overflow: hidden;
  }
}
@media (min-width: 80rem) {
  .gallery__wrapper-img {
    grid-template-columns: repeat(4, 1fr);
  }
  .gallery__img {
    max-width: 100%;
    max-height: 19rem;
  }
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.volunteering__img {
  max-width: 100%;
  height: auto;
  order: -1;
  border-radius: 2rem;
  margin-bottom: 2rem;
}
.volunteering-main {
  display: flex;
  flex-direction: column;
  padding: 6rem 1.25rem 4rem;
}
.volunteering-main__title {
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.125em;
}
.volunteering-main__title strong {
  display: inline-block;
  position: relative;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
}
.volunteering-main__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #9359c6;
}
.volunteering-main__text {
  margin-top: 1rem;
  font-size: 0.875rem;
  line-height: 1.5em;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  color: #51565D;
}
.volunteering-profil__title {
  position: relative;
  z-index: 1;
  padding: 2rem 1.25rem 0;
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.5em;
}
.volunteering-profil__title strong {
  display: inline-block;
  z-index: 0;
  position: relative;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
}
.volunteering-profil__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #9359c6;
}
.volunteering-profil__text {
  padding: 0 1.25rem 2rem;
  margin-top: 1rem;
  font-size: 0.875rem;
  line-height: 1.5em;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  color: #51565D;
}
.volunteering-card__wrapper {
  position: relative;
  padding: 1.5rem;
  border-radius: 1rem;
  background-color: white;
  max-width: 90%;
  margin: 0 auto;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.volunteering-card__sro {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0;
}
.volunteering-card__title {
  font-size: 1.5rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  padding-bottom: 0.5rem;
}
.volunteering-card__text {
  font-size: 0.875rem;
  line-height: 1.55em;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  padding-bottom: 1.25rem;
  color: #51565D;
}
.volunteering-card__frequence, .volunteering-card__location {
  font-size: 0.875rem;
  line-height: 1.55em;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  padding-bottom: 1.25rem;
  color: #51565D;
}
.volunteering-card__frequence-title, .volunteering-card__location-title {
  font-size: 1.125rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  padding-bottom: 0.5rem;
}

.volunteering-contact__title {
  padding: 3rem 1.25rem 0;
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
}
.volunteering-contact__title strong {
  display: inline-block;
  position: relative;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
}
.volunteering-contact__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #EF6D51;
}

.field__label {
  margin-top: 1.5rem;
  font-size: 1.125rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  display: block;
}
.field__input {
  font-size: 1rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  background-color: transparent;
  border: none;
  padding: 0.5rem 0;
  border-bottom: 1px solid black;
  width: 100%;
}
.field__textarea {
  font-size: 1.125rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  background-color: transparent;
  border: none;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid black;
  width: 100%;
}
[label=firstname] {
  margin-top: 0;
}

.sliderButtonsContainer {
  display: none;
}

.sliderContainer {
  overflow: scroll;
}

@media (min-width: 765px) {
  .volunteering-background {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: 80rem;
    gap: 2rem;
  }
  .volunteering-main {
    padding: 6rem 1.25rem 4rem;
    max-width: 80rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
  .volunteering-main__background {
    padding-right: 2rem;
    max-width: 45%;
  }
  .volunteering-main .volunteering__img {
    order: 0;
    max-width: 50%;
    margin: 0;
  }
  .volunteering-card__wrapper {
    min-width: 20rem;
  }
  .volunteering-profil {
    background-color: #ffffff;
    padding: 3rem 0;
  }
  .volunteering-profil__title strong {
    position: relative;
    z-index: 1;
  }
  .volunteering-profil__background {
    max-width: 50%;
  }
  .volunteering-profil__background-card {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    overflow: scroll;
    padding: 1rem;
  }
  .volunteering-contact {
    margin: 0 auto;
    max-width: 80rem;
    padding: 4rem 1.25rem;
  }
  .volunteering-contact__title {
    padding: 0;
  }
  .volunteering-contact__wrapper {
    max-width: 40%;
  }
  .volunteering__contact__submit {
    margin-top: 2rem;
    font-size: 1rem;
    font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
    background-color: #EF6D51;
    padding: 1rem 1.25rem;
    border: none;
    border-radius: 0.5rem;
  }
}
@media (min-width: 995px) {
  .volunteering-main {
    padding: 5rem 1.25rem;
  }
  .volunteering-main__title {
    font-size: 3rem;
    line-height: 1.16em;
  }
}
@media (min-width: 80rem) {
  .volunteering-main {
    padding: 6rem 0;
  }
  .volunteering-profil__title {
    padding: 0;
  }
  .volunteering-profil__text {
    padding: 0 0 2rem;
  }
  .volunteering-profil__background {
    max-width: 35%;
  }
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.support-main {
  padding: 4rem 0;
}
.support-main__text, .support-main__title {
  padding: 0 1.25rem;
}
.support-main__title, .support-donation__title, .support-team__title {
  position: relative;
  z-index: 1;
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.25em;
  margin-bottom: 1.5rem;
}
.support-main__title strong, .support-donation__title strong, .support-team__title strong {
  display: inline-block;
  position: relative;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
}
.support-main__title strong::before, .support-donation__title strong::before, .support-team__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #EF6D51;
}
.support-main__text, .support-donation__text, .support-team__text {
  color: #51565D;
  font-size: 0.875rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.55em;
  margin-bottom: 1rem;
  max-width: 35rem;
}
.support__img {
  order: -1;
  width: 100%;
  height: auto;
  border-radius: 2rem;
}
.support__img-background {
  max-width: 100%;
  height: 100%;
}
.support__img-wrapper {
  margin: 1rem 1.25rem 1rem;
  order: -1;
  position: relative;
}
.support-main {
  display: flex;
  flex-direction: column;
}
.support-wrapper {
  border-radius: 1rem;
  margin-bottom: 2rem;
}
.support-wrapper-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1rem;
  background: linear-gradient(rgba(54, 54, 54, 0) 0%, rgba(54, 54, 54, 0.88) 71.43%, #363636 100%);
  height: 100%;
}
.support-wrapper-title {
  padding: 8rem 1.25rem 1.5rem;
  font-size: 1.5rem;
  line-height: 1.16em;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  color: #ffffff;
}
.support-wrapper-text {
  font-size: 0.875rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.55em;
  padding: 0 1.25rem 1.5rem;
  color: #ffffff;
  max-width: 31rem;
}
.support-wrapper-link {
  font-size: 1rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  background-color: #0980a7;
  color: white;
  display: inline-block;
  margin: auto 0 1.25rem 1.5rem;
  border: 2px solid #0980a7;
  transition: all 300ms;
}
.support-wrapper-link:hover {
  background-color: transparent;
  color: #0980a7;
}
.support .support-team .support-wrapper-link {
  background-color: #9359c6;
  border-color: #9359c6;
  margin-top: auto;
}
.support .support-team .support-wrapper-link:hover {
  background-color: transparent;
  color: #9359c6;
}
.support-donation {
  background-color: #ffffff;
}
.support-donation__background {
  margin: 0;
  max-width: 80rem;
  padding: 4rem 1.25rem;
}
.support-team {
  margin: 0;
  max-width: 80rem;
  padding: 4rem 1.25rem;
}

.money {
  background-image: url("/wp-content/themes/clinicoeurs/resources/img/template-support/don-money.jpg");
}

.material {
  background-image: url("/wp-content/themes/clinicoeurs/resources/img/template-support/don-material.jpg");
}

.article {
  background-image: url("/wp-content/themes/clinicoeurs/resources/img/template-support/article.jpg");
}

.legs {
  background-image: url("/wp-content/themes/clinicoeurs/resources/img/template-support/legs-en-duo.jpg");
}

.support-benevole {
  background-image: url("/wp-content/themes/clinicoeurs/resources/img/template-support/benevole.jpg");
}

.partner-benevole {
  background-image: url("/wp-content/themes/clinicoeurs/resources/img/template-support/partner.jpg");
}

.money,
.material,
.article,
.legs,
.support-benevole,
.partner-benevole {
  background-size: cover;
  background-position: center;
}

.event {
  background-color: #ffffff;
}
.event__background {
  padding-bottom: 2rem;
  margin: 0 auto;
  max-width: 80rem;
}
.event__title {
  padding: 4rem 1.25rem 0;
  font-size: 2rem;
  display: inline-block;
  position: relative;
  z-index: 1;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
}
.event__title strong {
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  position: relative;
  z-index: 1;
  display: inline-block;
}
.event__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #9359c6;
}
.event__text {
  padding: 2rem 1.25rem 0;
  font-size: 0.875rem;
  line-height: 1.5em;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  color: #51565D;
}
.event__link {
  font-size: 1rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  padding: 1rem 1.5rem;
  margin: 3rem 1.25rem 0;
  background-color: #9359c6;
  color: #ffffff;
  display: inline-block;
  border-radius: 0.5rem;
}
.event__img {
  border-radius: 0.5rem;
  margin: 2rem auto 4rem;
  display: block;
  max-width: 90%;
  height: auto;
}

.support .form strong::before {
  background-color: #55BA8F;
}
.support .form__submit {
  border-color: #37814d;
  background-color: #37814d;
}
.support .form__submit:hover {
  color: #37814d;
}

@media (min-width: 765px) {
  .support__img {
    max-width: 30rem;
  }
  .support__img-wrapper {
    margin: 0;
  }
  .support-main {
    margin: 0 auto;
    max-width: 80rem;
    padding: 8rem 1.25rem 4rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
  .support-main__title, .support-main__text {
    padding: 0;
  }
  .support-main__background {
    order: -1;
    max-width: 30rem;
    margin-right: 2rem;
  }
  .support-donation__background {
    margin: 0 auto;
    max-width: 80rem;
  }
  .support-donation__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-bottom: 2rem;
  }
  .support-wrapper {
    margin-bottom: 0;
  }
  .support-wrapper-title {
    padding-top: 16rem;
  }
  .support-team {
    max-width: 80rem;
    margin: 0 auto;
  }
  .material .support-wrapper-text {
    padding-bottom: 3rem;
    max-width: 35rem;
  }
  .event__wrapper {
    max-width: 50%;
  }
  .event__background {
    max-width: 80rem;
    margin: 0 auto;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .event__img {
    margin: 4rem 0;
    border-radius: 1rem;
  }
}
@media (min-width: 995px) {
  .support-main {
    padding: 5rem 1.25rem;
  }
  .support-main__title {
    font-size: 3rem;
    line-height: 1.16em;
  }
  .event__title {
    padding-top: 0;
  }
  .event__wrapper {
    max-width: 35rem;
  }
  .event__img {
    margin-right: 1.25rem;
  }
}
@media (min-width: 80rem) {
  .event__title, .event__text {
    padding-left: 0;
    padding-right: 0;
  }
  .event__link {
    margin-left: 0;
  }
  .event__wrapper {
    max-width: 45%;
  }
  .event__img {
    margin-right: 0;
  }
  .support-main {
    padding: 5rem 0;
  }
  .support-main__title {
    padding-left: 0;
    padding-right: 0;
  }
  .support-main__text {
    padding-left: 0;
    padding-right: 0;
  }
  .support-donation__background {
    padding: 4rem 0;
  }
  .support-team {
    padding: 4rem 0;
  }
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.partner__img {
  border-radius: 2rem;
  max-width: 100%;
  height: auto;
}
.partner-main {
  padding: 6rem 1.25rem 4rem;
  display: flex;
  flex-direction: column;
  max-width: 80rem;
  margin: 0 auto;
}
.partner-main__title {
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.25em;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
}
.partner-main__title strong {
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  position: relative;
  z-index: 1;
  display: inline-block;
}
.partner-main__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #66B7B4;
}
.partner-main__text {
  font-size: 0.875rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5em;
  color: #51565D;
}
.partner-main .partner__img {
  order: -1;
  margin-bottom: 2rem;
}
.partner__background {
  max-width: 80rem;
  margin: 0 auto;
}
.partner-obtain {
  background-color: #ffffff;
}
.partner-obtain__background {
  margin: 0 auto;
  max-width: 80rem;
  padding: 4rem 1.25rem;
}
.partner-obtain__title {
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.25em;
  margin-bottom: 1.5rem;
}
.partner-obtain__title strong {
  display: inline-block;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  position: relative;
  z-index: 1;
}
.partner-obtain__title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #66B7B4;
}
.partner-obtain__text {
  font-size: 1rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5em;
  color: #51565D;
  margin-bottom: 1.5rem;
}
.partner__gallery {
  background-color: #ffffff;
}
.partner__gallery-background {
  margin: 0 auto;
  max-width: 80rem;
  padding-bottom: 4rem;
}
.partner__gallery-title {
  font-size: 2rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 500;
  line-height: 1.25em;
  margin-bottom: 1.5rem;
  padding: 4rem 1.25rem 0;
}
.partner__gallery-title strong {
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  position: relative;
  z-index: 1;
  display: inline-block;
}
.partner__gallery-title strong::before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background-color: #66B7B4;
}
.partner__gallery-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 2rem;
  justify-items: center;
  object-fit: cover;
}
.partner__gallery-img {
  max-width: 80%;
  height: auto;
  transition: all 300ms;
}
.partner__gallery-img-inactive {
  max-width: 80%;
  height: auto;
  transition: all 1s;
  filter: grayscale(100%);
}
.partner__gallery-img:hover {
  transform-origin: center;
  transform: scale(1.2);
}
.partner .contact__information {
  background-color: transparent;
}

.form__title strong::before {
  background-color: #9359c6;
}
.form__submit {
  background-color: #9359c6;
}

.partner .form strong::before {
  background-color: #66B7B4;
}
.partner .form__submit {
  border-color: #66B7B4;
  background-color: #66B7B4;
}
.partner .form__submit:hover {
  color: #66B7B4;
}

@media (min-width: 765px) {
  .partner__img {
    max-width: 50%;
  }
  .partner-main {
    padding-top: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 4rem;
  }
  .partner-main .partner__img {
    order: 0;
    margin: 0;
    margin-left: 2rem;
  }
  .partner-obtain__background {
    padding: 8rem 1.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .partner-obtain__background .partner__img {
    margin-right: 2rem;
  }
  .partner-obtain__text-wrapper {
    order: 1;
  }
  .partner__gallery-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (min-width: 995px) {
  .partner-main {
    padding: 5rem 1.25rem;
  }
  .partner-main__title {
    font-size: 3rem;
    line-height: 1.16em;
  }
}
@media (min-width: 80rem) {
  .partner-main {
    padding: 6rem 0;
    justify-content: space-between;
  }
  .partner-main__title {
    font-size: 3rem;
    line-height: 1.16em;
  }
  .partner-main__wrapper {
    max-width: 42%;
  }
  .partner-obtain__background {
    justify-content: space-between;
    padding: 8rem 0;
  }
  .partner-obtain__text {
    font-size: 0.875rem;
  }
  .partner-obtain__text-wrapper {
    max-width: 45%;
  }
  .partner__gallery-title {
    padding: 4rem 0 0;
  }
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.mention-main {
  background: #FBF5F3 repeat url("../../resources/img/fond-color.svg");
  background-size: 50%;
  padding-top: 4rem;
}

.mention-coordinate {
  max-width: 80rem;
  margin: 0 auto;
  padding: 1.5rem;
}
.mention-coordinate:last-of-type {
  padding: 0;
  max-width: initial;
}
.mention-coordinate:last-of-type div {
  max-width: 80rem;
  margin: 0 auto;
}
.mention-coordinate:last-of-type .mention-coordinate__title {
  padding: 1.5rem 1.5rem 0;
  max-width: 30rem;
}
.mention-coordinate:last-of-type .mention-coordinate__text {
  padding: 0 1.5rem 1.5rem;
  max-width: 30rem;
}
.mention-coordinate__title, .mention-coordinate__first-title {
  font-size: 1.75rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  line-height: 1.25em;
}
.mention-coordinate__text {
  font-size: 1rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.45em;
  padding-top: 0.5rem;
}
.mention-coordinate__before {
  font-size: 1.25rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  display: block;
  max-width: 75%;
  padding-top: 1.5rem;
  line-height: 1.25em;
}
.mention-coordinate__after {
  padding-top: 0.75rem;
  font-size: 1rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
}
.mention-coordinate__img {
  display: none;
}

.mention-title {
  font-size: 2.25rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  max-width: 80rem;
  margin: 0 auto;
}

@media (min-width: 600px) {
  .mention-coordinate__title {
    max-width: 30rem;
  }
  .mention-coordinate__text {
    max-width: 30rem;
  }
}
@media (min-width: 768px) {
  .mention-title {
    font-size: 3rem;
    font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
    font-weight: 700;
    padding: 2.5rem 1.5rem 0.5rem 1.5rem;
    max-width: 80rem;
    margin: 0 auto;
  }
  .mention-coordinate {
    padding: 3rem 1.5rem;
  }
  .mention-coordinate__title, .mention-coordinate__first-title {
    font-size: 2rem;
    max-width: 40rem;
  }
  .mention-coordinate__before {
    font-size: 1.5rem;
  }
  .mention-coordinate__after {
    font-size: 1rem;
  }
  .mention-coordinate__text {
    padding-top: 1rem;
    font-size: 1rem;
    max-width: 40rem;
  }
  .mention-coordinate__img {
    display: initial;
    animation: floatSm 5s infinite;
  }
  .coordinate {
    display: flex;
  }
}
@media (min-width: 995px) {
  .mention-main {
    padding-top: 0;
  }
  .mention-title {
    padding-top: 3rem;
    font-size: 4rem;
  }
  .mention-coordinate:nth-of-type(odd) .mention-coordinate__title {
    margin: 0 0 0 auto;
  }
  .mention-coordinate:nth-of-type(odd) .mention-coordinate__text {
    margin: 0 0 0 auto;
  }
  .mention-coordinate:last-of-type .mention-coordinate__title {
    max-width: 40rem;
  }
  .mention-coordinate:last-of-type .mention-coordinate__text {
    max-width: 40rem;
  }
}
@keyframes floatSm {
  0%, 100% {
    translate: 0 -3rem;
    rotate: -5deg;
  }
  50% {
    translate: 0 3rem;
    rotate: 5deg;
  }
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

/*SECTION 4 */
.volunteering-profil__background {
  max-width: 30rem;
}
.volunteering-job {
  background-color: #ffffff;
}
.volunteering-job-container {
  padding: 2.5rem 0;
}

.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.slider {
  margin: 0 1.5rem 0 1.5rem;
  text-align: left;
  border-radius: 0.5rem;
  position: relative;
}

.slides {
  display: flex;
  overflow-x: scroll;
  position: relative;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  gap: 1.5rem;
}

.slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  box-sizing: border-box;
  background: #ffffff;
  transform-origin: center center;
  transform: scale(1);
  scroll-snap-align: center;
}

.slider__nav {
  margin: 20px auto;
  text-align: center;
}

a .slide__next {
  transform: rotate(-45deg);
  right: 5%;
}

.slider__navlink {
  font-size: 0;
  transition: all 0.5s;
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 0 6px 0 6px;
  color: #1C1C1C;
  background-color: #ffffff;
  border: 2px solid #9359c6;
}

.slider-navlink-active {
  background-color: #9359c6;
  width: 40px;
  height: 10px;
  border-radius: 1rem;
  color: #ffffff;
}

.card-reviews {
  padding: 2rem 1.25rem 1.25rem;
  border: 2px solid #9359c6;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 24.75rem;
  min-width: 20.4rem;
  align-items: flex-start;
}

.card-reviews .quote {
  position: relative;
}

.card-reviews > .quote::before {
  content: url("../../../img/quote.svg");
  position: absolute;
  top: -8px;
  left: -8px;
  z-index: -1;
  display: inline-block;
  width: 1.5rem;
}

.card-reviews .quote::after {
  content: url("../../../img/quote.svg");
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  rotate: 180deg;
  display: inline-block;
  width: 1.5rem;
}

.card-reviews h3 {
  font-size: 1.5rem;
  line-height: 1.25em;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  color: #0f141e;
  margin-bottom: 0.5rem;
  text-align: left;
}

.card-reviews p:first-of-type {
  font-size: 0.875rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 1.2rem;
}

.identity-card {
  margin-top: auto;
  display: flex;
  align-items: center;
}

.identity-card div {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.identity-card img,
.identity-container img {
  max-width: 4rem;
  height: auto;
  border-radius: 2rem;
}

.identity-card div > p {
  color: #0f141e;
  font-size: 0.875rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  line-height: 1.75; /*************CONVERSION*/
  margin: 0 0 0 1rem;
}

.identity-card div > p:first-of-type {
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  line-height: 18px; /*************CONVERSION*/
  margin-bottom: 0.5rem;
}

.slider .anchor-hack {
  display: block;
  position: relative;
  height: 0;
  top: -17rem;
  visibility: hidden;
}

.slider span[id=slide-4] {
  top: -18.2rem;
}

.slider span[id=slide-6],
.slider span[id=slide-5],
.slider span[id=slide-4],
.slider span[id=slide-3],
.slider span[id=slide-2] {
  right: -8.2rem;
}

.arrow-left,
.arrow-right {
  background: transparent;
  border: none;
  display: none;
}

@media (min-width: 390px) {
  .slides {
    max-width: 21rem;
    margin: 0 auto;
    padding-bottom: 0.5rem;
  }
}
@media (min-width: 720px) {
  .volunteering-job-container {
    overflow: hidden;
  }
  button[href="#slide-2"],
  button[href="#slide-3"] {
    display: none;
  }
  .slides {
    max-width: 43rem;
    margin: 0 auto;
  }
  .slider span[id=slide-4] {
    right: -20rem;
  }
  .arrow-left,
  .arrow-right {
    font-size: 0;
    display: none;
    opacity: 1;
    transition: all 200ms;
    position: absolute;
    top: 11rem;
    z-index: 2;
  }
  .arrow-hidden {
    opacity: 0;
  }
}
@media (min-width: 995px) {
  .arrow-left {
    left: -1rem;
  }
  .arrow-right {
    right: -1rem;
  }
  .arrow-left,
  .arrow-right {
    display: initial;
  }
  .volunteering-job-container {
    max-width: 80rem;
    margin: 0 auto;
    padding: 5rem 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .volunteering-profil__background {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  .volunteering-profil__title {
    padding-top: 2rem;
  }
}
@media (max-width: 375px) {
  .card-reviews {
    min-width: 100%;
  }
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Poppins-Bold.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("/wp-content/themes/clinicoeurs/dist/font/Montserrat.ttf") format("TrueType");
}
/* MAX WIDTH PAGE*/
/* COLOUR*/
/* SIZE */
/* FONT MIXIN */
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.footers__background {
  max-width: 80rem;
  margin: 0 auto;
}

.footers {
  background-color: #313131;
}
.footers__menu {
  padding: 2rem 1.25rem 1rem;
}
.footers__menu-li {
  font-size: 1.5rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 700;
  color: white;
  margin: 1.5rem 0 0.5rem;
}
.footers__menu-li li {
  font-size: 1rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  color: white;
  padding: 0.75rem 0 0.65rem;
}
.footers__menu-li li:first-of-type {
  padding-top: 1.5rem;
}
.footers__menu-li li a {
  cursor: pointer;
  flex: 0;
  color: white;
  transition: all 300ms;
  border-bottom: 1px solid transparent;
}
.footers__menu-li li a:focus, .footers__menu-li li a:hover {
  border-color: white;
}

.secondaryFooters {
  background-color: #51565D;
}
.secondaryFooters-wrapper {
  margin: 0 auto;
  max-width: 80rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.25rem 1.5rem;
  color: white;
  font-size: 1rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
}
.secondaryFooters div {
  max-width: 80rem;
}
.secondaryFooters p {
  margin-bottom: 1rem;
}
.secondaryFooters a {
  color: white;
  font-size: 1rem;
  font-family: "Montserrat", Arial, Helvetica, "Roboto", "Open Sans", sans-serif;
  font-weight: 400;
  border-bottom: 1px solid transparent;
  transition: all 300ms;
}
.secondaryFooters a:hover, .secondaryFooters a:focus {
  border-bottom: 1px solid #ffffff;
}
.secondaryFooters-container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 765px) {
  .footers__menu {
    padding: 2rem 1.25rem 1rem;
  }
  .secondaryFooters-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
  }
  .secondaryFooters-wrapper div {
    margin: 0;
  }
  .secondaryFooters p {
    margin-bottom: 0;
  }
  .secondaryFooters-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
  .secondaryFooters a {
    padding: 0;
  }
}
@media (min-width: 900px) {
  .footers__menu-first {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
}
@media (min-width: 80rem) {
  .secondaryFooters-wrapper {
    padding: 1.5rem 0;
  }
  .footers {
    padding: 0;
  }
  .footers__menu {
    padding: 2rem 0 1rem;
  }
}
.js-enabled .underline strong::before {
  width: 0;
  transition: width 750ms ease-in-out;
}
.js-enabled .underline-js strong::before {
  width: 100%;
}