@use "../settings/settings";

.single__products {
   .cliniclowns {
      &-ariane {
         &__container {
            padding: 3rem 1.25rem 1rem;
         }

         &__link {
            line-height: 1.25em;
         }
      }
   }

   &-ariane {
      &__link {
         font-size: .875rem;
         line-height: 1.25em;
         @include MontserratRegular;
         color: settings.$colorBlack;
         margin-right: .5rem;

         &::after {
            content: url("/wp-content/themes/clinicoeurs/resources/img/fleche-service.svg");
            width: .5rem;
            height: 1rem;
            margin-left: .5rem;
            color: settings.$colorAccessibleGrey;
         }

         &:last-of-type {
            @include MontserratBold;
            color: settings.$colorBlack;

            &::after {
               content: '';
               width: 0;
               height: 0;
               margin-left: 0;
               color: transparent;
            }
         }
      }
   }

   &-title {
      font-size: 2rem;
      @include MontserratMedium;
      line-height: 1.25em;

      &:nth-child(2) {
         margin-top: 8rem;
      }

      strong {
         position: relative;
         display: inline-block;
         @include MontserratBold;
      }

      strong::before {
         content: "";
         z-index: -1;
         position: absolute;
         bottom: 6px;
         left: 0;
         width: 100%;
         height: 27%;
         background-color: settings.$orangeColor;
      }
   }

   &-text-wrapper {
      display: flex;
      flex-direction: column;
      padding: 0 1.25rem 4rem;
   }

   &-img-main {
      order: -1;
      margin-bottom: 1rem;
      width: 100%;
      height: auto;
      border-radius: settings.$borderRadiusMobile;
   }

   &-description {
      margin-top: 1.5rem;
      font-size: .875rem;
      color: settings.$colorAccessibleGrey;
      @include MontserratRegular;
      line-height: 1.55em;
   }

   &-wrapper {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 1fr;
      object-fit: cover;
      gap: 1.25rem;
   }

   &-image {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: .5rem;
      transition: all 300ms;
      &:hover{
         transform: scale(1.1);
         cursor: pointer;
      }
   }

   &-container {
      position: relative;
      z-index: 0;
      padding: 4rem 1.25rem;
      background-color: settings.$colorWhite;
   }
}

@media (min-width: 765px) {
   .single__products {
      margin: 0 auto;
      padding: 3rem 0 0;

      &-text-wrapper {
         max-width: settings.$widthFullPage;
         margin: 0 auto;
         display: flex;
         justify-content: space-between;
         flex-direction: row;
         align-items: center;
         gap: 2rem;
      }

      &-img-main {
         order: 1;
         margin-bottom: 0;
         width: 100%;
         max-width: 25.5rem;
         height: auto;
         border-radius: .5rem;
      }

      &-description {
         margin-top: 1.5rem;
         font-size: .875rem;
         @include MontserratRegular;
         line-height: 1.5em;
      }

      &-wrapper {
         grid-template-columns: 1fr 1fr 1fr;
         object-fit: cover;
         gap: 2rem;
         justify-items: stretch;
         align-items: stretch;
      }

      &-image {
         max-height: 16rem;
      }
      &-container{
         &-second{
            margin: 0 auto;
            max-width: settings.$widthFullPage;
         }
      }
   }
}

@media (min-width: 995px) {
   .single__products {
      padding : 0;
      &-wrapper {
         margin-top: 2rem;
         display: grid;
         grid-template-columns: 1fr 1fr 1fr 1fr;
         object-fit: cover;
         gap: 2rem;
         justify-items: stretch;
         align-items: stretch;
         &-text-wrapper {
            gap: 4rem;
         }
      }
      .cliniclowns-ariane__container{
         padding-top: 1rem;
      }
      &-text-wrapper{
         padding: 5rem 1.25rem;
      }
   }
}

@media (min-width: 995px) {
   .single__products-title {
      font-size: 3rem;
   }
   .single__products-container {
      .single__products-title {
         font-size: 2.5rem;
      }
   }
   .single__products-text-background {
      max-width: 45rem;
   }
   .single__products-description{
      max-width: 35rem;
   }
}

@media (min-width: settings.$widthFullPage) {
   .single__products-img-main{
      max-width: 35rem;
      margin-left: 0;
   }
   .single__products .cliniclowns-ariane__container {
      padding: 1rem 0 5rem;
   }
   .single__products-text-wrapper {
      padding: 0 0 6rem;
   }
   .single__products-container {
      padding: 4rem 0;
   }
}

/* Styles for the image modal */
.modal {
   display: none;
   position: fixed;
   z-index: 10;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   overflow: auto;
   background-color: rgba(0, 0, 0, 0.9);
}

.container-modal{
   display: flex;
   justify-content: center;
   align-items: center;
   min-height: 100%;
   max-height: 100%;
}

.modal-content {
   display: block;
   margin: auto;
   max-width: 90%;
   max-height: 90%;
}

.close {
   color: #fff;
   font-size: 2.5rem;
   font-weight: bold;
   position: absolute;
   top: 1.25rem;
   right: 1.25rem;
   cursor: pointer;
}

.prev,
.next {
   color: #fff;
   font-size: 2.5rem;
   font-weight: bold;
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   cursor: pointer;
}

.prev {
   left: 1.25rem;
}

.next {
   right: 1.25rem;
}