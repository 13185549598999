@use "../settings/settings";
.mention-main{
   background: settings.$backgroundColor repeat url("../../resources/img/fond-color.svg");
   background-size: 50%;
   padding-top: 4rem;
}
.mention-coordinate{
   max-width: settings.$widthFullPage;
   margin : 0 auto;
   padding: 1.5rem;
   &:last-of-type{
      padding: 0;
      max-width: initial;
      div{
         max-width: settings.$widthFullPage;
         margin: 0 auto;
      }
      .mention-coordinate__title{
         padding: 1.5rem 1.5rem 0;
         max-width: 30rem;
      }
      .mention-coordinate__text{
         padding: 0 1.5rem 1.5rem;
         max-width: 30rem;
      }
   }
   &__title,
   &__first-title{
      font-size: 1.75rem;
      @include MontserratBold;
      line-height: 1.25em;
   }
   &__text{
      font-size: 1rem;
      @include MontserratRegular;
      line-height: 1.45em;
      padding-top: .5rem;
   }
   &__before{
      font-size: 1.25rem;
      @include MontserratBold;
      display: block;
      max-width: 75%;
      padding-top: 1.5rem;
      line-height: 1.25em;
   }
   &__after{
      padding-top: .75rem;
      font-size: 1rem;
      @include MontserratRegular;
   }
   &__img{
      display: none;
   }
}
.mention-title{
   font-size: 2.25rem;
   @include MontserratBold;
   padding: 1.5rem 1.5rem .5rem 1.5rem;
   max-width: settings.$widthFullPage;
   margin: 0 auto;
}

@media (min-width: 600px) {
   .mention-coordinate{
      &__title{
         max-width: 30rem;
      }
      &__text{
         max-width: 30rem;
      }
   }
}
@media (min-width: 768px) {
   .mention-title{
      font-size: 3rem;
      @include MontserratBold;
      padding: 2.5rem 1.5rem .5rem 1.5rem;
      max-width: settings.$widthFullPage;
      margin: 0 auto;
   }
   .mention-coordinate{
      padding: 3rem 1.5rem;
      &__title,
      &__first-title{
         font-size: 2rem;
         max-width: 40rem;
      }
      &__before{
         font-size: 1.5rem;
      }
      &__after{
         font-size: 1rem;
      }
      &__text{
         padding-top: 1rem;
         font-size: 1rem;
         max-width: 40rem;
      }
      &__img{
         display: initial;
         animation: floatSm 5s infinite;
      }
   }
   .coordinate{
      display: flex;
   }
}

@media (min-width: 995px) {
   .mention-main{
      padding-top: 0;
   }
   .mention-title{
      padding-top: 3rem;
      font-size: 4rem;
   }
   .mention-coordinate{
      &:nth-of-type(odd){
         .mention-coordinate{
            &__title{
               margin: 0 0 0 auto;
            }
            &__text{
               margin: 0 0 0 auto;
            }
         }
      }
      &:last-of-type{
         .mention-coordinate__title{
            max-width: 40rem;
         }
         .mention-coordinate__text{
            max-width: 40rem;
         }
      }
   }
}
@keyframes floatSm {
   0%, 100%{
      translate: 0 -3rem;
      rotate: -5deg;
   }
   50%{
      translate: 0 3rem;
      rotate: 5deg;
   }
}