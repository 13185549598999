@use "../settings/settings";

.main {
   overflow: hidden;
   &__title{
      padding-top: 1.25rem;
   }
}

.clinicoeurs{
   .svg {
      &-ell1 {
         fill: settings.$orangeColor;
         left: -10%;
         bottom: 10%;
      }

      &-ell2 {
         fill: settings.$yellowColor;
         max-width: 50%;
         left: 65%;
         bottom: -10%;
      }

      &-rect1 {
         fill: settings.$blueColor;
         right: -4rem;
         bottom: 10rem;
      }

      &-rect2 {
         fill: settings.$greenColor;
         max-width: 18%;
         height: auto;
         left: -6%;
         top: 4%;
      }

      &-rect3 {
         fill: settings.$purpleColor;
         max-width: 15%;
         right: 4%;
         top: 10%;
      }
   }
}

.main,
.but,
.history,
.espace,
.benevole {
   max-width: settings.$widthFullPage;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   padding: 5rem 1.25rem 0;

   &__title {
      position: relative;
      z-index: 1;
      font-size: 2rem;
      line-height: 1.25em;
      @include MontserratMedium;
      margin-bottom: 1.5rem;

      strong {
         position: relative;
         @include MontserratBold;
         display: inline-block;
      }

      strong::before {
         content: "";
         z-index: -1;
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 35%;
         background-color: settings.$greenColor;
      }
   }

   &__text {
      color: $colorAccessibleGrey;
      font-size: .875rem;
      line-height: 1.55em;
      @include MontserratRegular;
      margin-bottom: 2rem;
   }
}

.main {
   &__text {
      margin-bottom: 5rem;
   }
}

.but,
.history,
.espace,
.benevole {
   background-color: white;
   position: relative;
   z-index: 0;

   &__background {
      max-width: settings.$widthFullPage;
   }

   &__img-wrapper {
      margin-top: 4rem;
      position: relative;
   }
}

.but {
   &__title {
      strong::before {
         background-color: settings.$purpleColor;
      }
   }
}

.history {
   padding: 0 1.25rem 5rem;

   &-formation__img {
      margin-bottom: 0;
   }

   &__title {
      strong::before {
         background-color: settings.$blueColor;
      }
   }
}

.espace {
   &__title {
      strong::before {
         background-color: settings.$orangeColor;
      }
   }
}

.benevole {
   &__title {
      strong {
         display: inline-block;
      }

      strong::before {
         background-color: settings.$greenColor;
      }
   }

   &__text {
      margin-bottom: 1.5rem;
   }

   &__link {
      padding: 1rem 1.5rem;
      border-radius: .5rem;
      font-size: .875rem;
      color: settings.$colorTextLink;
      @include MontserratBold;
      display: inline-block;
      max-width: 16rem;
      max-width: fit-content;
      margin-bottom: 2rem;
      transition: all 300ms;

      &::before {
         content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-v.svg");
         transition: translate 300ms;
         display: inline-block;
         width: .5rem;
         height: .95rem;
         translate: -1rem;
      }

      &:hover,
      &:focus {
         color: settings.$greenColor;

         &::before {
            translate: -.4rem;
         }
      }
   }
}

.reviews {
   background-color: initial;
}

.members {
   max-width: settings.$widthFullPage;
   margin: 0 auto;
   padding: 2rem 0 3rem;

   &__title {
      font-size: 2rem;
      @include MontserratMedium;
      padding: 0 1.25rem;

      strong {
         @include MontserratBold;
         position: relative;
         display: inline-block;
      }

      strong::before {
         content: "";
         z-index: -1;
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 35%;
         background: linear-gradient(90deg, rgba(239, 109, 81, 1) 0%, rgba(239, 109, 81, 1) 26%, rgba(85, 186, 143, 1) 26%, rgba(85, 186, 143, 1) 49%, rgba(102, 183, 180, 1) 49%, rgba(105, 177, 181, 1) 75%, rgba(148, 90, 198, 1) 75%, rgba(148, 90, 198, 1) 100%);;
      }
   }
}

.members-cliniclowns,
.members-clinitalents,
.members-clinijuniors,
.members-clinisnoezs {
   &__title {
      padding: 2.5rem 1.25rem 1.5rem;
      font-size: 1.5rem;
      @include MontserratMedium;
      line-height: 1.15em;

      strong {
         @include MontserratBold;
         display: inline-block;
         position: relative;
         z-index: 1;
      }

      strong::before {
         content: "";
         z-index: -1;
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 35%;
         background-color: settings.$orangeColor;
      }
   }

   &__wrapper {
      display: flex;
      gap: 1.25rem;
      overflow: scroll;
      padding: 0 1.25rem;
   }

   &__link {
      display: inline-block;
      font-size: .875rem; // Augmenter en version mobile classique
      @include MontserratBold;
      color: settings.$colorTextLink;
      margin: 1rem 0 1rem 1.25rem;
      padding: 1rem 1.5rem;
      border-radius: .5rem;
      transition: all 300ms;

      &::before {
         content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-o.svg");
         transition: translate 300ms;
         display: inline-block;
         width: .5rem;
         height: .95rem;
         translate: -1rem;
      }

      &:hover,
      &:focus {
         color: settings.$orangeColor;

         &::before {
            translate: -.4rem;
         }
      }
   }
   &__container{
      position: relative;
   }
   &-img {
      width: 15rem;
      height: 15rem;
      object-fit: cover;
      border-radius: 2rem;
   }
   &-name{
      max-width: 88%;
      line-height: 1.5em;
      font-weight: bold;
      position: absolute;
      bottom: 0;
      background-color: white;
      padding: 1rem 1.5rem;
      border-radius: 0 1rem 1rem 1rem;
   }
}

.members-clinitalents {
   &__title {
      strong::before {
         background-color: settings.$greenColor;
      }
   }

   &__link {
      &::before {
         content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-v.svg");
      }

      &:hover,
      &:focus {
         color: settings.$greenColor;
      }
   }
}

.members-clinijuniors {
   &__title {
      strong::before {
         background-color: settings.$blueColor;
      }
   }

   &__link {
      &::before {
         content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-b.svg");
      }

      &:hover,
      &:focus {
         color: settings.$blueColor;
      }
   }
}

.members-clinisnoezs {
   &__title {
      strong::before {
         background-color: settings.$purpleColor;
      }
   }

   &__link {
      &::before {
         content: url("/wp-content/themes/clinicoeurs/resources/img/accueil/service/fleche-p.svg");
      }

      &:hover,
      &:focus {
         color: settings.$purpleColor;
      }
   }
}

.but,
.espace, {
   &-formation__img {
      border-radius: 2rem;
      max-width: 100%;
      height: auto;
      margin-bottom: 5rem;
   }
}

.history,
.benevole {
   padding-top: 0;
   padding-bottom: 5rem;

   &-formation__img {
      border-radius: 2rem;
      max-width: 100%;
      height: auto;
      margin-bottom: 0;
   }
}

.clinicoeurs {
   .reviews {
      padding-top: 4rem;
   }
}

@media (min-width: 375px) {
   .members-cliniclowns,
   .members-clinitalents,
   .members-clinijuniors,
   .members-clinisnoezs {
      &__link {
         font-size: 1.125rem; // Aug
      }
   }
   .benevole {
      &__link {
         font-size: 1.125rem;
      }
   }
}


@media(min-width: 765px) {
   .members-cliniclowns,
   .members-clinitalents,
   .members-clinijuniors,
   .members-clinisnoezs{
      &-img {
         width: 15rem;
         height: 15rem;
         object-fit: cover;
         max-width: 100%;
      }
   }
   .svg {
      &-cl-ell3 {
         bottom: -9%;
      }
   }

   .but,
   .history,
   .espace,
   .benevole {
      &__img-wrapper {
         margin-top: 0;
         max-width: 50%;
      }
   }
   .main {
      padding-top: 6rem;
      display: flex;
      justify-content: space-between;
      gap: 4rem;
      flex-direction: row;
      margin: 0 auto;
      max-width: settings.$widthFullPage;
      align-items: stretch;
      padding-bottom: 5rem;

      &__title {
         strong {
            display: inline-block;
         }
      }

      &__wrapper {
         display: flex;
         flex-direction: column;
         justify-content: center;
         max-width: 25rem;
         order: -1;
      }

      &__text {
         margin-bottom: 0;
      }
   }
   .but,
   .espace {
      max-width: initial;
      padding-bottom: 2rem;

      &__text {
         margin-bottom: 0;
      }

      &__background {
         max-width: settings.$widthFullPage;
         margin: 0 auto;
         padding: 0 0 2rem;
         gap: 2rem;
         display: flex;
         align-items: center;
      }

      &-formation__img {
         margin-bottom: 0;
         min-width: 45%;
      }
   }
   .history,
   .benevole {
      max-width: initial;
      padding-bottom: 2rem;

      &__img-wrapper {
         order: -1;
      }

      &__text {
         margin-bottom: 0;
      }

      &__background {
         max-width: settings.$widthFullPage;
         margin: 0 auto;
         padding: 2rem 0 4rem;
         display: flex;
         align-items: center;
      }

      &-formation__img {
         margin-right: 2rem;
         margin-bottom: 0;
         max-width: 50%;
         order: -1;
      }
   }
   .benevole {
      &__text {
         margin-bottom: 2rem;
      }

      &__link {
         margin-bottom: 0;
      }
   }

   .members {
      padding: 4rem 0;

      &__title {
         display: inline-block;
         font-size: 2.5rem;
         line-height: 1.2em;
         max-width: 60%;
      }

      &-cliniclowns,
      &-clinitalents,
      &-clinisnoezs,
      &-clinijuniors,
      {
         &__title {
            font-size: 1.75rem;
            line-height: 1.25em;
            display: inline-block;
            max-width: 50%;
         }

         &__wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            overflow: initial;
         }

         &__link {
            margin-top: 2rem;
         }
      }
   }
}

@media (min-width: 995px) {
   .clinicoeurs {
      .index {
         &__img-wrapper {
            max-width: 35rem;
         }
      }
   }
   .main {
      padding-top: 5rem;
      &__title {
         font-size: 3rem;
         line-height: 1.15em;
      }
      &__wrapper {
         max-width: 30rem;
      }
   }
   .members {
      &__title {
         font-size: 2.5rem;
      }

      &-cliniclowns,
      &-clinitalents,
      &-clinisnoezs,
      &-clinijuniors,
      {
         &__title {
            font-size: 1.5rem;
         }

         &__wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
         }

         &__link {
            font-size: 1.25rem;
            margin-top: 2rem;
         }
      }
   }
}

@media (min-width: settings.$widthFullPage) {
   .main {
      padding: 6rem 0;
      justify-content: flex-start;
      gap: 10rem;
   }
   .but__background,
   .espace__background {
      justify-content: space-between;

      &-wrapper {
         max-width: 45%;
      }
   }
   .history__background,
   .benevole__background {
      justify-content: space-between;

      &-wrapper {
         max-width: 45%;
      }
   }
   .but__text,
   .history__text,
   .espace__text,
   .benevole__text {
      //font-size: 1rem;
   }
   .members {
      &__title {
         padding: 0;
         max-width: 40%;
      }

      &__wrapper {
         padding: 0 0 2rem;
      }
   }
   .members-cliniclowns,
   .members-clinitalents,
   .members-clinijuniors,
   .members-clinisnoezs {
      &__title {
         padding: 2.5rem 0 1.5rem;
      }

      &__wrapper {
         padding: 0;
      }

      &__link {
         margin: 1rem 0 1rem;
      }
   }
}