@use "../settings/settings";

.product {
   max-width: settings.$widthFullPage;
   margin: 0 auto;
   padding: 2rem 0 0;
   &-form__background{
      display: flex;
      flex-direction: column;
      padding: 2rem 1.25rem;
   }
   &-form__informations{
      box-shadow: 0 0 5px #dbdbdb;
      background-color: settings.$colorWhite;
      padding: 2rem;
      max-width: 35rem;
   }
   &__title {
      font-size: 2rem;
      line-height: 1.25em;
      padding: 4rem 1.25rem 0;
      @include MontserratMedium;

      strong {
         display: inline-block;
         position: relative;
         @include MontserratBold;
      }

      strong::before {
         content: "";
         z-index: -1;
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 35%;
         background-color: settings.$orangeColor;
      }
   }
   &__img{
      margin-top: 0;
      max-width: 100%;
      height: auto;
      border-radius: 0 0 .5rem .5rem;
   }
   &-main {
      &__title {
         font-size: 1.5rem;
         @include MontserratMedium;
         margin-bottom: 1.5rem;
         padding: 0 1.25rem;

         strong {
            display: inline-block;
            position: relative;
            @include MontserratBold;
         }

         strong::before {
            content: "";
            z-index: -1;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 35%;
            background-color: settings.$orangeColor;
         }
      }

      &__wrapper {
         display: flex;
         overflow-x: scroll;
         gap: 1.25rem;
         padding: 2rem 1.25rem;
      }

      &__card {
         &-title {
            @include MontserratBold;
            font-size: 1.25rem;
            line-height: 1.375em;
            text-align: center;
            color: settings.$colorWhite;
            background-color: settings.$orangeColor;
            padding: 1.5rem 1rem 0;
            border-radius:1rem 1rem 0 0;
            min-height: 4.5rem;
            min-width: 13rem;
            white-space: nowrap;
         }

         &-img {
            min-width: 12rem;
            width: 100%;
            height: auto;
            border-radius: 0 0 1rem 1rem;
            min-height: 14rem;
            max-height: 14rem;
            object-fit: cover;
         }
      }
   }

   .product-main__link {
      transition: all 300ms;

      &:hover,
      &:focus {
         scale: 1.05;
      }

      &:nth-child(2n+3) {
         .product-main__card-title {
            background-color: settings.$purpleColor;
         }
      }

      &:nth-child(4n+2) {
         .product-main__card-title {
            background-color: settings.$orangeColor;
         }
      }

      &:nth-child(5n+1) {
         .product-main__card-title {
            background-color: settings.$darkBlueColor;
         }
      }

      &:nth-child(7n+4) {
         .product-main__card-title {
            background-color: settings.$darkGreenColor;
         }
      }

      &:nth-child(3n+2) {
         .product-main__card-title {
            background-color: settings.$darkBlueColor;
         }
      }

      &:nth-child(3n+7) {
         .product-main__card-title {
            background-color: settings.$purpleColor;
         }
      }

      &:nth-child(5n+1) {
         .product-main__card-title {
            background-color: settings.$darkGreenColor;
         }
      }

      &:nth-child(8) {
         .product-main__card-title {
            background-color: settings.$orangeColor;
         }
      }
   }
}

.product-faq {
   margin-top: 2rem;
   padding: 2rem 1.25rem 3rem;

   &__title {
      font-size: 1.5rem;
      @include MontserratMedium;
      padding-bottom: 1.5rem;
      line-height: 1.25em;

      strong {
      @include MontserratBold;
         display: inline-block;
         position: relative;
      }

      strong::before {
         content: "";
         z-index: -1;
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 35%;
         background-color: settings.$purpleColor;
      }
   }

   &__term {
      border-top: 1px solid black;
      padding: .5rem 0;
      font-size: 1rem;
      @include MontserratBold;
      line-height: 1.5em;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
   }

   &__definition {
      transition:opacity 3s ease, max-height 5s ease;
      max-height: 10rem;
      height: initial;
      opacity: 1;
      font-size: 1rem;
      line-height: 1.5rem;
      @include MontserratRegular;
   }
}

.product__contact {
   padding: 2rem 1.25rem 6rem;

   &__footer {
      button {
         width: 100%;
         margin-top: 1.5rem;
         font-size: 1rem;
         display: block;
         padding: 1rem 1.5rem;
         @include MontserratBold;
         color: white;
         background-color: settings.$orangeColor;
         border-radius: .5rem;
         border: none;
      }
   }
}

.product-contact {
   &__title {
      padding: 3rem 1.25rem 0;
      font-size: 2rem;
      @include MontserratBold;

      strong {
         display: inline-block;
         position: relative;
      }

      strong::before {
         content: "";
         z-index: -1;
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 35%;
         background-color: settings.$orangeColor;
      }
   }
}
@media (min-width: 765px) {
   .product {
      &__title {
         padding: 6rem 1.25rem 1rem;
      }

      &-main {
         &__title {
            font-size: 2rem;
         }

         &__wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 2rem;
            padding: 2rem 1.25rem;
         }

         &__card {
            max-width: initial;
            padding: 0;
         }
      }

      &-faq {
         padding-top: 4rem;
         flex-direction: row;
         gap: 2rem;
         &__background {
            position: relative;
            z-index: 1;
            background-color: settings.$backgroundColor;
            padding-left: 1.25rem;
         }
      }

      &-contact {
         display: flex;
         flex-direction: row;
         padding: 4rem 1.25rem;

         &__wrapper {
            position: relative;
            z-index: 1;
            order: 1;
            background-color: settings.$backgroundColor;
            padding-left: 2rem;
            width: 100%;
         }
      }
   }
}

@media (min-width: 995px) {
   .product{
      &__title{
         padding-top: 0;
         font-size: 3rem;
         line-height: 1.16em;
      }
      &-main {
         &__wrapper {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            overflow: visible;
         }
      }
      &-form__background{
         display: flex;
         flex-direction: row;
      }
   }

}

@media (min-width: settings.$widthFullPage) {
   .product {
      &__title{
         padding: 0 0 3rem;
      }
      &-main{
         &__title{
            padding: 0;
         }
         &__wrapper {
            padding: 2rem 0;
         }
      }
      &-faq,
      &-contact{
         padding: 4rem 0;
      }
   }
}

.js-enabled {
   .faq-hide-js{
      @include settings.hidden;
   }
}